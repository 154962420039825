/*----------------------------------------*/
/*    24 - Components - Social LInk
/*----------------------------------------*/
// Social link One
.social-link {
	padding-top: 25px;
	&.with-color{
		> ul{
			> li{
				padding-right: 0;
				&:not(:last-child){
					margin-right: 10px;
				}
				&:hover{
					a{
						background-color: $primary-color;
					}
				}
				&.facebook{
					background-color: $facebook-color;
				}
				&.twitter{
					background-color: $twitter-color;
				}
				&.instagram{
					background-color: $instagram-color;
				}
				&.vimeo{
					background-color: $vimeo-color;
				}
				> a{
					width: 30px;
					height: 30px;
					line-height: 31px;
					color: $white-color;
					text-align: center;
					font-size: 14px;
					border-radius: 100%;
				}
			}
		}
	}
	&.with-radius{
		> ul{
			> li{
				border-radius: 100%;
			}
		}
	}
	&.with-radius-2{
		> ul {
			> li {
				margin-right: 15px;
				padding-right: 0px;
				> a {
					width: 30px;
					height: 30px;
					line-height: 31px;
					border: 1px solid $border-color-2;
					color: #3e3e3e;
					text-align: center;
					font-size: 16px;
					border-radius: 100%;
					&:hover{
						background-color: $primary-color;
						color: $white-color;
					}
				}
			}
		}
	}
	> ul {
		> li {
			display: inline-block;
			padding-right: 25px;
			&:last-child {
				padding-right: 0;
			}
			> a {
				font-size: 22px;
				display: block;
			}
		}
	}
}
/*--- Social Link Style 2 ---*/
.social-link-2 {
	float: right;
	@include breakpoint(max-xs_device){
		display: flex;
		justify-content: center;
		float: none;
	}
	ul{
		display: flex;
		li{
			a{
				padding: 0 10px;
				color: $footer-color;
				&:hover{
					color: $white-color;
					@extend %basetransition;
				}
			}
			&:last-child{
				a{
					padding-right: 0px;
				}
			}
		}
	}
}
