/*----------------------------------------*/
/*    33 - Components - Hereabout
/*----------------------------------------*/
.hereabout-area{
    background-image: url(../images/bg/home/1-2.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    height: 785px;
    position: relative;
    @include breakpoint(max-md_device){
        height: 530px;
    }
    @include breakpoint(max-xs_device){
        height: 375px;
    }
    .hereabout-content{
        position: absolute;
        top: 39%;
        transform: translateY(-39%);
        background: $white-color;
        padding: 90px 100px 0 140px;
        width: 875px;
        @include breakpoint(max-xs_device){
            padding: 40px 20px 0 30px !important;
        }
        @include breakpoint(max-sm_device){
            width: 100% !important;
        }
        @include breakpoint(max-md_device){
            top: 50%;
            transform: translateY(-50%);
            width: 80%;
            padding: 60px 50px 0 100px;
        }
        .section-title{
            .heading-one{
                padding-bottom: 40px;
                @include breakpoint(max-sm_device){
                    padding-bottom: 25px;
                }
                @include breakpoint(max-xxs_device){
                    padding-bottom: 10px;
                    font-size: 22px;
                    line-height: 20px;
                    letter-spacing: 2px;
                }
            }
        }
        .desc-one{
            font-size: 18px;
            line-height: 2em;
            padding-bottom: 18px;
            @include breakpoint(max-sm_device){
                font-size: 16px;
                padding-bottom: 10px;
            }
            @include breakpoint(max-xxs_device){
                line-height: 24px;
                font-size: 14px;
                padding-bottom: 5px;
            }
        }
        .hereabout-btn{
            position: relative;
            bottom: -30px;
            @include breakpoint(max-sm_device){
                bottom: -20px;
            }
        }
    }
}
.product-shop-area{
    background-image: url(../images/bg/home/11-4.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    height: 850px;
    position: relative;
    align-items: center;
    bottom: -67px;
    @include breakpoint(max-xl_device){
        height: 720px;
    }
    @include breakpoint(max-lg_device){
        height: 620px;
    }
    @include breakpoint(max-md_device){
        height: 500px;
    }
    @include breakpoint(max-sm_device){
        height: 335px;
        bottom: -56px;
    }
    @include breakpoint(max-xs_device){
        height: 290px;
        bottom: -48px;
    }
    @include breakpoint(max-xxs_device){
        height: 200px;
        bottom: -38px;
    }
    .about-shop-wrapper{
        .title-1{
            font-size: 36px;
            font-family: $general-font-family-5;
            font-weight: 400;
            color: $primary-color-6;
            margin-bottom: 10px;
            @include breakpoint(max-sm_device){
                font-size: 26px;
                line-height: 26px;
            }
            @include breakpoint(max-xs_device){
                font-size: 20px;
                line-height: 20px;
            }
            @include breakpoint(max-xxs_device){
                font-size: 18px;
                line-height: 18px;
            }
        }
        .title-2{
            font-size: 66px;
            font-family: $general-font-family-5;
            font-weight: 400;
            color: $theme-black-color;
            margin-bottom: 10px;
            @include breakpoint(max-lg_device){
                font-size: 62px;
                line-height: 62px;
            }
            @include breakpoint(max-md_device){
                font-size: 46px;
                line-height: 46px;
            }
            @include breakpoint(max-sm_device){
                font-size: 35px;
                line-height: 35px;
            }
            @include breakpoint(max-xs_device){
                font-size: 30px;
                line-height: 30px;
            }
            @include breakpoint(max-xxs_device){
                font-size: 20px;
                line-height: 20px;
            }
        }
        .desc-content{
            font-size: 22px;
            color: $banner-color-1;
            line-height: 1.5em;
            width: 790px;
            margin: 0 auto;
            padding-bottom: 40px;
            @include breakpoint(max-md_device){
                font-size: 18px;
                width: 700px;
            }
            @include breakpoint(max-sm_device){
                font-size: 18px;
                width: 500px;
                padding-bottom: 20px;
            }
            @include breakpoint(max-xs_device){
                font-size: 16px;
                width: 400px;
            }
            @include breakpoint(max-xxs_device){
                display: none;
            }
        }
    }
}