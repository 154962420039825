/*----------------------------------------*/
/*    11 - Pages - About
/*----------------------------------------*/
.about-area{
    /*-- About Store Area Start --*/
    .section-title {
        > .heading-one{
            @include breakpoint(max-xxs_device){
                line-height: 30px;
            }
        }
    }
    .about-store-area{
        padding-bottom: 80px;
        background-color: #2c2c2c;
        .about-store-title{
            text-align: center;
            margin: 90px 0px;
            @include breakpoint(max-sm_device){
                margin: 82px 0px;
            }
            @include breakpoint(max-xs_device){
                margin: 85px 0px;
            }
            .heading{
                font-size: 36px;
                font-family: $heading-font-family-name;
                font-weight: normal;
                line-height: 24px;
                letter-spacing: 3px;
                color: $white-color;
                text-transform: uppercase;
                @include breakpoint(max-sm_device){
                    line-height: 36px;
                }
                @include breakpoint(max-xs_device){
                    font-size: 24px;
                    line-height: 24px;
                }
            }
        }
        .about-thumbnail{
            display: block;
            overflow: hidden;
            position: relative;
            img{
                width: 100%;
            } 
            .about-thumb-video{
                content: "";
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                position: absolute;
                z-index: 1;
                .popup-video{
                    i{
                        font-size: 20px;
                        height: 66px;
                        width: 66px;
                        line-height: 66px;
                        background: #ffffff;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        @include breakpoint(max-xxs_device){
                            font-size: 18px;
                            height: 40px;
                            width: 40px;
                            line-height: 66px;
                        }
                    }
                }
            }
        }
        .desc-content{
            width: 970px;
            color: $white-color;
            margin: 0 auto;
            font-size: 18px;
            padding-top: 80px;
            @include breakpoint(max-lg_device){
                width: 950px;
            }
            @include breakpoint(max-md_device){
                width: 700px;
            }
            @include breakpoint(max-sm_device){
                width: 500px;
            }
            @include breakpoint(max-xs_device){
                width: 420px;
                font-size: 16px;
            }
            @include breakpoint(max-xxs_device){
                width: 280px;
                font-size: 14px;
            }
        }
    }
    /*-- About Store Area End --*/
    /*-- Project About Area Start --*/
    .project-about{
        background-color: #f9f9f9;
        padding: 90px 0 85px 0;
        .project-about-wrapper{
            padding-top: 30px;
            .project-about-icon{
                i{
                    fill: #262626;
                    color: #262626;
                    font-size: 26px;
                    padding: 26px;
                    border: 1px solid #262626;
                    border-radius: 100%;
                    background-color: $white-color;
                    &:hover{
                        background-color: $primary-color;
                        border-color: $primary-color;
                        color: $white-color;
                    }
                }
            }
            .project-about-content{
                .title{
                    color: #1a1a1a;
                    font-size: 24px;
                    margin-top: 20px;
                    margin-bottom: 15px;
                }
                .desc-content{
                    font-size: 14px;
                    line-height: 2em;
                }
            }
        }
    }
    /*-- Project About Area End --*/
    /*-- About Brand Area Start Here --*/
    .about-brand-area{
        padding: 90px 0px 40px 0;
        .section-title{
            margin-bottom: 30px;
        }
        .brand-logo-2{
            .single-brand-item{
                img{
                    background: transparent;
                }
            } 
        } 
    }
    /*-- About Brand Area End Here --*/
    /*-- About Team Area Start Here --*/
    .about-team-area{
        background-color: #f9f9f9;
        padding: 80px 0px;
        .slick-list{
            margin: 0 -30px;
        }
        .team-slider{
            .single-team{
                margin: 0 30px;
                position: relative;
                .team-item{
                    .banner-overlay{
                        position: absolute;
                        left: 0;
                        top: 0;
                        opacity: 0;
                        content: "";
                        background-color: $primary-color;
                        width: 100%;
                        height: 100%;
                        transition: .5s;
                    }
                    .team-image{
                        img{
                            width: 100%;
                        }
                    }
                    .team-content{
                        position: absolute;
                        left: 50%;
                        top: 60%;
                        transform: translate(-50%, -50%);
                        opacity: 0;
                        transition: .5s;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        text-align: center;
                        .title{
                            a{
                                font-size: 24px;
                                color: $white-color;
                                font-weight: 600;
                                line-height: 16px;
                                margin-bottom: 10px;
                            }
                        }
                        .team-desc{
                            font-size: 12px;
                            font-family: $body-font-family-name;
                            color: $white-color;
                            margin-bottom: 10px;
                        }
                    }
                }
                &:hover{
                    .banner-overlay{
                        opacity: 1;
                        transition: .5s;
                    }
                    .team-content{
                        opacity: 1;
                        transition: .5s;
                        top: 50%;
                    }
                }
                .social-link-2{
                    ul{
                        li{
                            a{
                                width: 38px;
                                height: 38px;
                                line-height: 38px;
                                font-size: 18px;
                                padding: 0;
                                border-radius: 50%;
                                vertical-align: middle;
                                margin: 5px;
                                border: 1px solid rgba(255,255,255,0.5);
                                color: #fff;
                                transition: all .3s;
                                display: inline-block;
                                text-align: center;
                                &:hover{
                                    border-color: $primary-color;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    /*-- About Team Area End Here --*/
}