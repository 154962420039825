/*----------------------------------------*/
/*    32 - Components - Post
/*----------------------------------------*/
.blog-post-area{
    .slick-list{
        margin-left: -20px;
        margin-right: -20px;
    }
}
.post-inner-box{
    padding: 0 20px;
    .post-thumbnail{
        position: relative;
        display: inline-block;
        .date-post{
            position: absolute;
            height: 70px;
            width: 70px;
            background-color: rgba(35,35,35,.9);
            opacity: .9;
            display: grid;
            justify-content: center;
            bottom: 0;
            right: 0;
            padding: 10px 0;
            text-align: center;
            font-family: $body-font-family-name;
            span{
                color: $white-color;
            }
            .day-post{
                font-size: 21px;
                text-transform: uppercase;
            }
            .month-post{
                font-size: 14px;
                text-transform: uppercase;
            }
        }
    }
    .post-content{
        .post-meta{
            padding-top: 15px;
            a{
                text-transform: uppercase;
                font-size: 12px;
                font-weight: 700;
                font-family: $body-font-family-name;
                &:hover{
                    color: $primary-color;
                    text-decoration: underline;
                }
            }
        }
        .post-title{
            a{
                font-size: 24px;
                padding: 0px 40px 18px 40px;
                display: block;
                @include breakpoint(max-lg_device){
                    padding: 0 20px 18px 20px;
                    font-size: 22px;
                }
            }
        }
        .post-title-2 {
            a{
                font-size: 24px;
                font-family: $general-font-family-2;
                padding: 10px 40px 18px 40px;
                display: block;
                font-weight: normal;
                @include breakpoint(max-lg_device){
                    padding: 0 20px 18px 20px;
                    font-size: 22px;
                }
            }
        }
        .read-more{
            a{
                text-transform: uppercase;
                text-decoration: underline;
                font-size: 12px;
                font-family: $body-font-family-name;
                letter-spacing: 2px;
                &:hover{
                    text-decoration: none;
                }
            }
        }
    }
}
.blog-post-2{
    .featured-body-style{
        margin: -46px 0 0 0;
        padding: 90px 70px 90px 70px;
        @include breakpoint(max-xl_device){
            padding: 90px 30px 90px 30px;
        }
        @include breakpoint(max-md_device){
            margin: -40px 0 0 0;
        }
        @include breakpoint(max-sm_device){
            margin: -35px 0 0 0;
        }
        @include breakpoint(max-xs_device){
            margin: -30px 0 0 0;
            padding: 90px 20px 90px 20px;
        }
        @include breakpoint(max-xxs_device){
            margin: -20px 0 0 0;
            padding: 90px 10px 90px 10px;
        }
    }
    .post-inner-box{
        .post-content{
            .post-title{
                a{
                    padding: 0 10px 18px 10px;
                }
            }
        }
    }
}
.blog-post-area-2{
    .post-inner-box{
        position: relative;
        display: block;
        overflow: hidden;
        &:hover{
            .post-content{
                opacity: 1;
                background: $primary-color-4;
                @extend %basetransition;
            }
            .banner-overlay{
                opacity: .5;
                @extend %basetransition;
            }
        }
        .banner-overlay{
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
            content: "";
            background-color: #000;
            width: 100%;
            height: 100%;
            @extend %basetransition;
        }
        .post-thumbnail{
            display: block;
            img{
                width: 100%;
                display: block;
            }
        }
        .post-content{
            position: absolute;
            bottom: 0px;
            width: 100%;
            display: block;
            overflow: hidden;
            background:rgba(0, 0, 0, 0.5);
            @extend %basetransition;
            .post-meta{
                @include breakpoint(max-lg_device){
                    padding-top: 5px;
                }
                a{
                    color: $white-color;
                    visibility: visible;
                    z-index: 1;
                }
            }
            .post-title{
                a{
                    color: $white-color;
                    font-family: $general-font-family-2;
                    visibility: visible;
                    z-index: 1;
                    @include breakpoint(max-xl_device){
                        font-size: 20px;
                        padding: 0 30px 18px 30px;
                    }
                    @include breakpoint(max-lg_device){
                        font-size: 18px;
                        padding: 0 20px 10px 20px;
                    }
                    @include breakpoint(max-sm_device){
                        font-size: 16px;
                        padding: 0 10px 10px 10px;
                    }
                }
            }
        }
    }
}
.blog-post-area-3{
    padding-top: 90px;
    .section-title-3{
        .heading-5{
            margin-bottom: 20px;
        }
    }
    .main-title{
        padding-bottom: 30px;
    }
    .post-inner-box{
        position: relative;
        &:hover{
            .post-content{
                opacity: 1;
                transition: .3s;
            }
            .post-thumbnail{
                .banner-overlay{
                    opacity: .9;
                    transition: .3s;
                }
            }
        }
        .post-content{
            padding: 0 30px;
            position: absolute;
            top: 50%;
            left: 0;
            opacity: 0;
            transform: translate(0, -50%);
            .date-meta{
                font-size: 11px;
                line-height: 11px;
                margin-bottom: 10px;
            }
            .post-title-3{
                position: relative;
                padding-bottom: 10px;
                margin-bottom: 10px;
                a{
                    font-size: 22px;
                    font-family: $general-font-family-3;
                    line-height: 22px;
                    font-weight: 400;
                    margin-bottom: 10px;
                    color: $white-color;
                    &:hover{
                        color: $primary-color-4;
                    }
                }
                &:after{
                    position: absolute;
                    content: "";
                    left: 0;
                    bottom: 0;
                    margin: auto;
                    height: 1px;
                    background: #827021;
                    width: 80px;
                }
            }
            .btn-2{
                margin-top: 10px;
                a{
                    background-color: $white-color;
                    color: $theme-black-color;
                    font-size: 12px;
                    line-height: 20px;
                    padding: 8px 25px;
                    margin-top: 10px;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    &:hover{
                        background-color: $primary-color-5;
                        color: $white-color; 
                    }
                }
            }
        }
    }
    .post-thumbnail{
        display: block;
        overflow: hidden;
        .banner-overlay{
            content: "";
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            right: 0;
            background-color: rgb(66, 64, 58);
            opacity: 0;
            position: absolute;
            transition: .3s;
        }
    }
}
.blog-post-area-4{
    padding-top: 80px;
    .slick-list{
        margin-left: -20px;
        margin-right: -20px;
        @include breakpoint(max-xxl_device){
            margin: 0 -15px;
        }
    }
    .post-inner-box{
        @include breakpoint(max-xxl_device){
            padding: 0 15px;
        }
        .post-content-2{
            .post-title{
                a{
                    font-size: 24px;
                    font-family: $general-font-family-5;
                    font-weight: 400;
                    margin: 15px 0px;
                    display: block;
                    color: #000;
                    @include breakpoint(max-xxl_device){
                        font-size: 22px;
                    }
                }
            }
            .post-read-more{
                margin-top: 15px;
                display: block;
            }
        }
    }
}
.blog-post-area-5{
    .post-inner-box{
        .post-thumbnail{
            .date-post{
                font-family: $general-font-family-7;
            }
        }
        .post-content-2{
            .post-meta{
                padding-top: 15px;
                a{
                    text-transform: uppercase;
                    font-size: 11px;
                    font-weight: 600;
                    font-family: $general-font-family-7;
                    &:hover{
                        color: $primary-color-7;
                        text-decoration: underline;
                    }
                }
            }
            .post-title{
                a{
                    font-size: 24px;
                    font-weight: 400;
                    font-family: $general-font-family-6;
                    padding: 0px 20px 15px 20px;
                    display: block;
                    @include breakpoint(max-lg_device){
                        padding: 0 20px 18px 20px;
                        font-size: 22px;
                    }
                }
            }
            .read-more{
                a{
                    text-transform: uppercase;
                    text-decoration: underline;
                    font-size: 12px;
                    font-family: $body-font-family-name;
                    letter-spacing: 2px;
                    &:hover{
                        text-decoration: none;
                    }
                }
            }
        }
    }
}
.blog-post-area-6{
    .slick-list{
        padding: 0 -15px;
    }
    .post-inner-box{
        padding: 0 15px;
        .post-thumbnail{
            .date-post{
                font-family: $general-font-family-3;
            }
        }
        .post-content-2{
            .post-meta{
                a{
                    text-transform: uppercase;
                    font-size: 11px;
                    font-weight: 600;
                    font-family: $general-font-family-3;
                    &:hover{
                        color: $primary-color;
                        text-decoration: underline;
                    }
                }
            }
            .post-title{
                a{
                    font-size: 22px;
                    font-weight: 400;
                    font-family: $general-font-family-3;
                    padding: 20px 0 5px 0;
                    display: block;
                    color: #000;
                    &:hover{
                        color: $primary-color;
                    }
                }
            }
            .content{
                .desc-content{
                    font-size: 18;
                    font-family: $general-font-family-3;
                    line-height: 20px;
                    margin-bottom: 5px;
                    font-weight: 400;
                    color: #1a1a1a;
                }
            }
            .read-more{
                a{
                    text-transform: uppercase;
                    text-decoration: underline;
                    font-size: 12px;
                    font-family: $body-font-family-name;
                    letter-spacing: 2px;
                    &:hover{
                        text-decoration: none;
                    }
                }
            }
        }
    }
}