/*----------------------------------------*/
/*    27 - Components - Product Category
/*----------------------------------------*/
.product-category-area{
    padding-bottom: 41px;
    @include breakpoint(max-sm_device){
        padding-bottom: 36px;
    }
    .slick-list {
        margin-right: -25px;
        margin-left: -25px;
        @include breakpoint(max-lg_device){
            margin-right: -20px;
            margin-left: -20px;
        }
    }
} 
.single-category-one{
    padding: 0 25px;
    @include breakpoint(max-lg_device){
        padding: 0 20px;
    }
    @include breakpoint(max-xs_device){
        display: flex !important;
        justify-content: center;
    }
    .category-image{
        position: relative;
        width: fit-content;
        overflow: hidden;
        @extend %basetransition;
        margin-bottom: 32px;
        span{
            position: absolute;
            left: 50%;
            top: 60%;
            transform: translate(-50%, -50%);
            opacity: 0;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 16px;
            color: $white-color;
            @extend %basetransition;
        }
        img{
            transform: translate3d(-40px,0,0);
            max-width: none;
            width: calc(100% + 50px);
            @extend %basetransition;
        }
        &:hover{
            box-shadow: 15px 15px 0 0 $text-color;
            @extend %basetransition;
            span{
                opacity: 1;
                top: 50%;
                @extend %basetransition;
            }
            img{
                transform: translate3d(0,0,0);
                @extend %basetransition;
            }
        }
    }
}