/*----------------------------------------*/
/*    37 - Components - Shipping
/*----------------------------------------*/
.shipping-area{
    padding: 60px 0px;
    .shipping-wrapper{
        display: flex;
        justify-content: center;
    }
    .shipping-item{
        width: 365px;
        @include breakpoint(max-xl_device){
            width: 270px;
        }
        @include breakpoint(max-lg_device){
            width: 230px;
        }
        @include breakpoint(max-md_device){
            margin: 10px 0px;
        }
        .shipping-img{
            i{
                font-size: 50px;
            }
        }
        .shipping-content{
            .shipping-title{
                font-size: 18px;
                padding: 20px 0px 15px 0px;
            }
            .desc-content{
                font-size: 12px;
            }
        }
    }
}
.shipping-container{
    padding: 50px 0px;
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
}
.shipping-area-2{
    .shipping-item{
        display: flex;
        justify-content: center;
        align-items: center;
        @include breakpoint(max-sm_device){
            padding: 10px 0px;
        }
        .shipping-img{
            display: block;
            i{
                font-size: 48px;
                padding: 30px;
                border-radius: 50%;
                border: 1px solid $border-color;
                @include breakpoint(max-md_device){
                    font-size: 30px;
                    padding: 20px;
                }
            }
        }
        .shipping-content{
            display: block;
            padding-left: 46px;
            @include breakpoint(max-lg_device){
                padding-left: 20px;
            }
            @include breakpoint(max-md_device){
                padding-left: 10px;
            }
            .shipping-title{
                color: $text-color;
                font-family: $general-font-family-2;
                font-size: 20px;
                font-weight: normal;
                text-transform: uppercase;
                padding-bottom: 10px;
                @include breakpoint(max-md_device){
                    font-size: 16px;
                }
            }
            .desc-content{
                color: $text-color;
                font-size: 12px;
                line-height: 2em;
                @include breakpoint(max-md_device){
                    line-height: 1em;
                }
            }
        }
    }
}
.shipping-area-3{
    .shipping-item{
        display: flex;
        justify-content: center;
        align-items: center;
        @include breakpoint(max-sm_device){
            padding-bottom: 20px;
        }
        .shipping-img{
            display: block;
            i{
                font-size: 26px;
                padding: 30px;
                border-radius: 50%;
                color: $primary-color-4;
                background-color: $default-color;
                border: 1px solid $border-color;
                @include breakpoint(max-md_device){
                    font-size: 30px;
                    padding: 20px;
                }
            }
        }
        .shipping-content{
            display: block;
            padding-left: 30px;
            @include breakpoint(max-lg_device){
                padding-left: 20px;
            }
            @include breakpoint(max-md_device){
                padding-left: 10px;
            }
            .shipping-title{
                color: $text-color;
                font-family: $general-font-family-2;
                font-size: 20px;
                font-weight: normal;
                text-transform: uppercase;
                padding-bottom: 10px;
                @include breakpoint(max-lg_device){
                    font-size: 16px;
                }
                @include breakpoint(max-md_device){
                    font-size: 13px;
                    padding-bottom: 5px;
                }
                @include breakpoint(max-sm_device){
                    font-size: 20px;
                    padding-bottom: 10px;
                }
                @include breakpoint(max-xxs_device){
                    font-size: 16px;
                    padding-bottom: 10px;
                }
            }
            .desc-content{
                color: $text-color;
                font-size: 12px;
                line-height: 2em;
                @include breakpoint(max-lg_device){
                    line-height: 1.5em;
                }
                @include breakpoint(max-md_device){
                    line-height: 1.2em;
                }
            }
        }
    }
}
.shipping-area-4{
    padding-top: 60px;
    padding-bottom: 90px;
    .free-shipping-wrapper{
        border-radius: 80px;
        transition: .3s;
        .free-shipping-content{
            font-family: "Acme",Sans-serif;
            font-size: 36px;
            line-height: 50px;
            font-weight: 400;
            color: $white-color;
            padding: 40px;
            @include breakpoint(max-xl_device){
                font-size: 26px;
                line-height: 26px;
            }
            @include breakpoint(max-md_device){
                margin-bottom: 20px;
            }
            @include breakpoint(max-xxs_device){
                font-size: 20px;
                line-height: 20px;
            }
        }
        &.shipping-bg-1{
            background-color: #ff951e;
            &:hover{
                background-color: #f80;
                transition: .3s;
            }
        }
        &.shipping-bg-2{
            background-color: #8751ff;
            &:hover{
                background-color: #6016ff;
                transition: .3s;
            }            
        }
        &.shipping-bg-3{
            background-color: #ef519d;
            &:hover{
                background-color: #ef2185;
                transition: .3s;
            }
        }
    }
}