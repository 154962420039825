/*----------------------------------------*/
/*    19 - Pages - Faq
/*----------------------------------------*/
.faq-area{
    margin: 90px 0px;
    .faq-title{
        font-size: 32px;
        color: $primary-color;
        line-height: 26px;
        font-weight: 400;
        @include breakpoint(max-xxs_device){
            line-height: 32px;
        }
    }
    .faq-content-wrapper{
        margin-top: 50px;
        .faq-content-title{
            font-size: 24px;
            line-height: 16px;
            color: $theme-black-color;
            margin-bottom: 30px;
            @include breakpoint(max-xxs_device){
                line-height: 24px;
            }
        }
        .desc-content{
            font-size: 14px;
            font-family: $body-font-family-name;
        }
    }
    .faq-help-area{
        text-align: center;
        margin-top: 80px;
        .faq-help-title{
            align-items: center;
            display: flex;
            margin-bottom: 30px;
            &::before{
                display: block;
                content: "";
                border-bottom: 0;
                flex-grow: 1;
                border-top: 1px solid $border-color;
            }
            &::after{
                display: block;
                content: "";
                border-bottom: 0;
                flex-grow: 1;
                border-top: 1px solid $border-color;
            }
        }
        .title{
            font-size: 32px;
            font-weight: 400;
            line-height: 1;
            margin: 0 20px 0 20px;
            @include breakpoint(max-xxs_device){
                font-size: 24px;
                margin: 0 10px 0 10px;
            }
        }
        .desc-content{
            font-size: 14px;
            font-family: $body-font-family-name;
            width: 500px;
            margin: 0 auto;
            @include breakpoint(max-xs_device){
                width: 400px;
            }
            @include breakpoint(max-xxs_device){
                width: 250px;
            }
        }
        .faq-help-btn{
            display: flex;
            justify-content: center;
            margin-top: 30px;
        }
    }
}