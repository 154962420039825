/*----------------------------------------*/
/*    09 - Pages - Single Product
/*----------------------------------------*/
.single-product-main-area{
    .vertical-tab{
        display: flex;
        flex-direction: row-reverse;
        @include breakpoint(max-md_device){
            margin-bottom: 75px;
        }
        .product-details_slider{
            width: 85%;
            padding-left: 20px;
            @include breakpoint(max-xl_device){
                width: 82%;
            }
            @include breakpoint(max-xxs_device){
                padding-left: 10px;
                width: 80%;
            }
        }
        .pd-slider-nav{
            width: 15%;
            height: 100%;
            @include breakpoint(max-xl_device){
                width: 18%;
            }
            @include breakpoint(max-xxs_device){
                width: 20%;
            }
            .slick-list{
                margin-bottom: -10px;
                margin-top: -10px;
                padding: 0px;
            }
            .single-thumb{
                margin: 10px 0px;
                &.slick-active{
                    &.first-active{
                        img{
                            border: 1px solid $border-color-4;
                        }
                    }
                }
                img{
                    width: 100%;
                }
            }
        }
    }
    .product-details-img{
        .product-details_slider{
            .single-image{
                position: relative;
                .inner-stuff{
                    position: absolute;
                    bottom: 20px;
                    right: 0px;
                    z-index: 1;
                    opacity: 0;
                    visibility: hidden;
                    transition: .3s;
                    > ul{
                        > li{
                            &:not(:last-child){
                                margin-bottom: 15px;
                            }
                            > a{
                                box-shadow: 0 0 5px 1px rgba(0,0,0,.1);
                                background-color: $white-color;
                                width: 50px;
                                height: 50px;
                                line-height: 50px;
                                border-radius: 100%;
                                text-align: center;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                &:hover{
                                    background-color: $text-color;
                                    color: $white-color;
                                }
                                @include breakpoint(max-xs_device){
                                    width: 40px;
                                    height: 40px;
                                    line-height: 40px;
                                    font-size: 14px;
                                }
                                @include breakpoint(max-xxs_device){
                                    width: 30px;
                                    height: 30px;
                                    line-height: 30px;
                                    font-size:12px;
                                }
                            }
                        }
                    }
                }
                &:hover{
                    .inner-stuff{
                        right: 20px;
                        opacity: 1;
                        visibility: visible;
                        transition: .3s;
                    }
                }
            }
        }
    }
    .product-summery{
        .product-head{
            display: flex;
            justify-content: space-between;
            align-items: center; 
            .product-title{
                font-family: $heading-font-family-name;
                font-size: 22px;
                font-weight: 600;
                color: $text-color;
                @include breakpoint(max-xxs_device){
                    margin-bottom:10px;
                }
            }
        }
        .price-box{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 0px;
            @include breakpoint(max-xxs_device){
                flex-direction: column;
                align-items: flex-start;
            }
            .regular-price{
                font-size: 20px;
                @include breakpoint(max-xxs_device){
                    margin-bottom:10px;
                }
            }
            .rating-meta{
                display: flex;
                justify-content: space-between;
                align-items: center;
                @include breakpoint(max-xxs_device){
                    display: flex;
                    align-items: flex-start;
                    flex-direction: column;
                }
                .rating-box{
                    @include breakpoint(max-xxs_device){
                        margin-bottom:10px;
                    }
                    > ul{
                        > li{
                            a{
                                font-size: 12px;
                            }
                        }
                    }
                }
                .meta{
                    @include breakpoint(max-xxs_device){
                        margin-bottom:10px;
                    }
                    a{
                        font-size: 12px;
                        font-family: $body-font-family-name;
                        text-transform: lowercase;
                        margin-left: 10px;
                        @include breakpoint(max-xxs_device){
                            margin-left: 0px;
                        }
                    }
                }
            }
        }
        .product-description{
            margin-bottom: 40px;
            border-top: 1px solid rgba(0,0,0,.1);
            padding-top: 25px;
            p{
                font-size: 14px;
                font-family: $body-font-family-name;
            }
        }
        .product-variant{
            table{
                width: 100%;
                th{
                    font-size: 14px;
                    font-family: $body-font-family-name;
                    color: $text-color;
                    font-weight: 400;
                    padding: 0px 20px 20px 0px;
                }
                td{
                    font-size: 14px;
                    font-family: $body-font-family-name;
                    color: $text-color;
                    font-weight: 400;
                    padding: 0px 0px 20px 0px;
                    .myniceselect{
                        border-top: 0px;
                        border-right: 0px;
                        border-left: 0px;
                        border-radius: 0px;
                        color: $text-color;
                    }
                    .product-color{
                        a{
                            height: 22px;
                            width: 22px;
                            display: block;
                            border-radius: 50%;
                            display: inline-block;
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
        .quantity-with_btn{
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            .add-to_cart{
                .border-button{
                    @include breakpoint(max-sm_device){
                        width: 200px;
                        height: 60px;
                        line-height: 56px;
                        font-size: 16px;
                    }
                    @include breakpoint(max-xxs_device){
                        width: 180px;
                        height: 60px;
                        line-height: 56px;
                        font-size: 16px;
                    }
                }
            }
        }
        .add-actions{
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            padding-bottom: 25px;
            ul{
                display: flex;
                li{
                    a{
                        display: flex;
                        align-items: center;
                        vertical-align: middle;
                        font-size: 11px;
                        font-family: $body-font-family-name;
                        text-transform: uppercase;
                        margin-right: 10px;
                        i{
                            font-size: 14px;
                            padding-right: 5px;
                        }
                    }
                }
            }
        }
        .sku{
            margin-top: 20px;
            > span{
                font-size: 12px;
                line-height: 21.6px;
            }
        }
        .categories{
            li{
                text-transform: capitalize;
                color: #494949;
                display: inline-block;
                font-size: 12px;
                a{
                    color: $primary-color;
                    text-transform: capitalize;
                    line-height: 21.6px;
                    font-size: 12px;
                    font-family: $body-font-family-name;
                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
            &.tags{
                padding-bottom: 20px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            }
        }
    }
}
/*-- Single Product Tab --*/
.single-product-tab-area{
    .product-top-tab{
        border-bottom: 1px solid $border-color-2;
        margin-bottom: 55px;
        margin-top: 72px;
        display: flex;
        justify-content: center;
        @include breakpoint(max-xs_device){
            margin-top: 52px;
        }
        .nav{
            li{
                margin-right: 40px;
                @include breakpoint(max-xs_device){
                    margin-top: 20px;
                }
                a{
                    border-bottom: 5px solid transparent;
                    letter-spacing: 1px;
                    display: inline-block;
                    font-size: 14px;
                    padding: 0 0px 10px;
                    position: relative;
                    font-weight: 400;
                    color: $snow-color;
                    text-transform: uppercase;
                    font-family: $body-font-family-name;
                    @include breakpoint(max-xs_device){
                        line-height: 10px;
                    }
                    &.active{
                        border-bottom: 5px solid transparent;
                        letter-spacing: 1px;
                        display: inline-block;
                        font-size: 14px;
                        padding: 0 0px 10px;
                        position: relative;
                        font-weight: bold;
                        border-color: inherit;
                        color: $theme-black-color;
                        text-transform: uppercase;
                        font-family: $body-font-family-name;
                        &:before {
                            position: absolute;
                            content: "";
                            left: 0;
                            bottom: 0;
                            width: 100%;
                            height: 2px;
                            background-color: $theme-black-color;
                            z-index: 2;
                            opacity: 0;
                            visibility: hidden;
                        }
                        &:hover{
                            color: $theme-black-color;
                        }
                    }
                    &:hover{
                        color: $primary-color;
                    }
                }
            }
        }
    }
    .tab-content{
        .desc-body{
            display: flex;
            @include breakpoint(max-sm_device){
                flex-direction: column;
            }
            .inner-img{
                width: 30%;
                @include breakpoint(max-sm_device){
                    width: 100%;
                    padding-bottom: 30px;
                }
            }
            .inner-content{
                width: 70%;
                padding-left: 30px;
                .short-desc{
                    font-size: 14px;
                }
                @include breakpoint(max-sm_device){
                    width: 100%;
                    padding-left: 0px;
                }
            }
        }
        .additional-summery{
            .additional-summery-item{
                border: 1px solid #ebebeb;
                width: 100%;
                tbody{
                    tr{
                        th, td{
                            padding: 10px;
                            vertical-align: top;
                            border-bottom: 1px solid #ebebeb;
                            border-right: 1px solid #ebebeb;
                            font-weight: 400;
                            font-family: $body-font-family-name;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}
/* ---Review Body--- */
.review-body{
	> h3{
		&.heading{
            font-size: 24px;
            font-weight: 400;
            margin-bottom: 30px;
            display: block;
            color: $default-color;
            line-height: 1.4;
            font-family: $heading-font-family-name;
		}
	}
	.user-info{
		border-bottom: 1px solid $border-color;
		padding-bottom: 30px;
		display: flex;
		@include breakpoint(max-xs_device){
			display: block;
			text-align: center;
		}
		> li{
			&.user-avatar{
				@include breakpoint(max-xs_device){
					padding-bottom: 15px;
				}
			}
			&.user-comment{
				padding-left: 30px;
				@include breakpoint(max-xs_device){
					padding-left: 0;
				}
				.meta{
					font-family: $body-font-family-name;
                    color: $text-color;
                    font-size: 14px;
					padding: 5px 0px;
					strong{
						font-size: 14px;
					}
					span{
						font-size: 12px;
					}
				}
				> p{
					&.short-desc{
						font-family: $body-font-family-name;
                        color: $text-color;
                        font-size: 14px;
						line-height: 25.2px;
					}
				}
			}
		}
	}
	.user-feedback{
		padding-top: 55px;
		> h3{
			&.heading{
				padding-bottom: 30px;
				line-height: 33.6px;
				font-size: 24px;
			}
		}
		> p{
			&.short-desc{
				border-bottom: 1px solid $border-color;
				font-family:  $body-font-family-name;
				color: $text-color;
				padding-bottom: 10px;
				font-size: 14px;
				line-height: 25.2px;
			}
		}
		.rating-box{
			padding-top: 55px;
            display: flex;
            align-items: center;
			> span{
                padding-right: 25px;
                font-family:  $body-font-family-name;
                color: $text-color;
                font-size: 14px;
			}
			> ul{
				> li{
                    vertical-align: middle;
				}
			}
		}
		form{
			&.feedback-form{
				padding-top: 15px;
				.comment-field{
					label{
						&.label-field{
							padding-bottom: 10px;
                            display: block;
                            font-family:  $body-font-family-name;
                            color: $text-color;
                            font-size: 14px;
						}
					}
					textarea{
						&.textarea-field{
							border: 1px solid $border-color;
							@extend %basetransition;
							padding: 20px;
							height: 150px;
							width: 100%;
						}
						&:focus{
							border: 1px solid $primary-color;
						}
					}
				}
				.group-input{
					padding-top: 10px;
					display: flex;
					@include breakpoint(max-md_device){
						justify-content: space-between;
					}
					@include breakpoint(max-md_device){
						display: block;
					}
					label{
						&.label-field{
							padding-bottom: 10px;
                            display: block;
                            font-family:  $body-font-family-name;
                            color: $text-color;
                            font-size: 14px;
						}
					}
					input{
						&.input-field{
							margin-right: 30px;
							padding: 0 20px;
							height: 60px;
                            width: 370px;
                            @include breakpoint(max-lg_device){
                                width: 330px;
                            }
							@include breakpoint(max-md_device){
								width: 100%;
								margin-right: 0;
							}
						}
						&:focus{
							border: 1px solid $primary-color;
						}
					}
					.email-field{
						@include breakpoint(max-md_device){
							padding-top: 10px;
						}
					}
				}
				.field-checkbox{
					padding-top: 10px;
				}
				.comment-btn_wrap{
					padding-top: 20px;
				}
			}
		}
	}
}
.single-product-title{
    h4{
        text-transform: uppercase;
        font-weight: 400;
        margin-bottom: 40px;
        letter-spacing: 3px;
        font-family: $heading-font-family-name;
        font-size: 16px;
        line-height: 12px;
    }
}
/*-- Grouped Product --*/
.grouped-product-wrapper{
    .grouped-qty{
        border-bottom: 1px solid #e5e5e5;
        justify-content: flex-start;
        display: flex;
        .quantity{
            margin-right: 40px;
            .cart-plus-minus{
                .cart-plus-minus-box{
                    border: 0px;
                }
            }
        }
    }
}
/*-- Custom Style Product --*/
.custom-style-1-wrapper{
    .horizontal-tab{
        .pd-slider-nav{
            height: 100%;
            width: 80%;
            margin: 20px auto 0 auto;
            @include breakpoint(max-sm_device){
                margin: 20px auto 30px auto;
            }
            .slick-list{
                margin-right: -30px;
            }
            .single-thumb{
                padding: 0px 30px 0 0;
                &.slick-active{
                    &.first-active{
                        img{
                            border: 1px solid $border-color-4;
                        }
                    }
                }
                img{
                    width: 100%;
                    border: 1px solid transparent;
                }
            }
        }
    }
}
.custom-style-3-wrapper{
    .single-product-main-area{
        .vertical-tab{
            .product-details_slider{
                padding: 0 20px 0 0;
            }
        }
    }
}
/*-- Custom Sticky Product --*/
.sticky-sidebar-product{
    .single-image-small{
        display: flex;
        span{
            width: 50%;
        }
        img{
            width: 100%;
        }
    }
    .product-summery{
        @include breakpoint(max-sm_device){
            padding-top: 75px;
        }
        .inner-wrapper-sticky{
            @include breakpoint(max-sm_device){
                transform: none !important;
            }
        }
    }
}
/*-- Custom Slider Product --*/
.slider-sidebar-product{
    .product-details-img{
        margin-bottom: 75px;
        overflow: hidden;
        .single-image{
            img{
                padding: 0 15px;
            }
        }
        .slick-list{
            margin: 0 -15px;
        }
    }
    .pd-tab_item{
        .pb-tab-list-1{
            display: flex;
            flex-direction: column;
        }
        @include breakpoint(max-md_device){
            padding-top: 72px;
        }
        > ul{
            > li{
                &:not(:last-child){
                    margin-bottom: 20px;
                }
                > a{
                    border-bottom: 2px solid $text-color;
                    padding-bottom: 10px;
                    display: flex;
                    justify-content: space-between;
                    cursor: pointer;
                    font-family: $body-font-family-name;
                    color: $text-color;
                    font-size: 14px;
                    font-weight: 700;
                }
                > ul{
                    &.pd-tab_body{
                        display: none;
                        padding-top: 35px;
                        > li{
                            &.inner-body{
                                .brand-img{
                                    float: left;
                                    margin-right: 25px;
                                    width: 38.7%;
                                    @include breakpoint(max-xxs_device){
                                        width: 100%;
                                        margin-right: 0;
                                        padding-bottom: 25px;
                                    }
                                }
                                .pd-tab_content{
                                    width: 100%;
                                    > p{
                                        &.short-desc{
                                            font-family: $body-font-family-name;
                                            color: $text-color;
                                            padding-bottom: 20px;
                                            font-size: 14px;
                                        }
                                    }
                                    th, td{
                                        font-family: $body-font-family-name;
                                        color: $text-color;
                                        font-size: 14px;
                                        font-weight: 400;
                                    }
                                }
                            }
                        }
                    }
                }
                &.active{
                    .pd-tab_body{
                        display: block;
                    }
                    &.open{
                        a{
                            i{
                                &:before{
                                    content: "\ea68";
                                }
                            }
                        }
                    }
                }
                &.open{
                    a{
                        color: $primary-color;
                        i{
                            &:before{
                                content: "\ea68";
                            }
                        }
                    }
                }
            }
        }
        .pd-tab_content{
            .review-body{
                .user-info-2{
                    border-bottom: 1px solid $border-color;
                    padding-bottom: 30px;
                    display: flex !important;
                    @include breakpoint(max-xs_device){
                        display: block !important;
                        text-align: center;
                    }
                    > li{
                        &.user-avatar{
                            @include breakpoint(max-xs_device){
                                padding-bottom: 15px;
                            }
                        }
                        &.user-comment{
                            padding-left: 30px;
                            @include breakpoint(max-xs_device){
                                padding-left: 0;
                            }
                            .meta{
                                font-family: $body-font-family-name;
                                color: $text-color;
                                font-size: 14px;
                                padding: 5px 0px;
                                strong{
                                    font-size: 14px;
                                }
                                span{
                                    font-size: 12px;
                                }
                            }
                            > p{
                                &.short-desc{
                                    font-family: $body-font-family-name;
                                    color: $text-color;
                                    font-size: 14px;
                                    line-height: 25.2px;
                                }
                            }
                        }
                    }
                }
                .feedback-form{
                    .group-input{
                        .input-field{
                            width: calc(100% - 30px);
                        }
                    }
                }
            }
        }
    }
}
        