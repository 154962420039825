/*----------------------------------------*/
/*    01 - Base - Variables
/*----------------------------------------*/
//--- All Variables - Related Stuff Included In This Area ---↓
// project specific variables
$theme-color--default: #d4876c;
$default-color:        #041632;

// Color
$primary-color:     #d4876c;
$primary-color-2:   #f55555;
$primary-color-3:   #FF6060;
$primary-color-4:   #dda973;
$primary-color-5:   #827021;
$primary-color-6:   #ff794a;
$primary-color-7:   #73bcc3;
$footer-color:      #ababab;
$text-color:        #3e3e3e;
$heading-color:     #3e3e3e;
$heading-color-2:   #515151;
$theme-black-color: #1a1a1a;
$bg-color-1:        #2d2d2d;
$border-color:      #e5e5e5;
$border-color-2:    #ebebeb;
$border-color-3:    #d8d8d8;
$border-color-4:    #454545;
$banner-color-1:    #515151;

// Additional Color Variable
$white-color:       #ffffff;
$white-smoke_color: #f5f5f5;
$silver-color:      #bbbbbb;
$snow-color-2:      #f9f9f9;
$snow-color:        #b5b5b5;
$gamboge-color:     #DC9B0E;
$zircon-color:      #DAE5E7;
$gainsboro-color:   #dedede;
$wisp-pink_color :  #f3ebe9;
$night-rider_color :#313131;
$nobel-color:       #979797;
$charcoal-color:    #494949;
$eclipse-color:     #3e3e3e;

$arrow-color-1:     #ddeee9;
$cart-count-color:  #E92323;

// Slider Color Swatches
$burnt-orange_color:#ff832b;
$raw-umber_color:   #824900;
$black-color:       #000000;
$brown-color:       #a6311f;
$red-color:         #ff0000;

//----Social Link Color Variables ----↓
$facebook-color:    #3b579d;
$twitter-color:     #3acaff;
$google-plus-color: #dd4b39;
$linkedin-color:    #0097bd;
$pinterest-color:   #cb2027;
$instagram-color:   #833ab4;
$skype-color:       #00aff0;
$share-color:       #fe6d4c;
$youtube-color:     #d72525;
$vimeo-color:       #00adef;

// Font Family
$body-font-family-name: 'Poppins', sans-serif;
$heading-font-family-name: 'Zilla Slab', serif;
$general-font-family-name: 'Zilla Slab', serif; 
$general-font-family-2: 'Fjalla One', sans-serif;
$general-font-family-3: 'Lora', serif;
$general-font-family-4: 'Alegreya', serif;
$general-font-family-5: 'Acme', sans-serif;
$general-font-family-6: 'Playfair Display', serif;
$general-font-family-7: 'Lato', sans-serif;
$general-font-family-8: 'Oswald', sans-serif;
$general-font-family-9: 'Montserrat', sans-serif;