/*----------------------------------------*/
/*    39 - Components - Counter
/*----------------------------------------*/
.counter-area{
    &.background-1 {
        background-image: url(../images/bg/home/6-1.jpg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        height: 752px;
        position: relative;
        align-items: center;
        margin: 0px;
        @include breakpoint(max-xl_device){
            height: 720px;
        }
        @include breakpoint(max-lg_device){
            height: 620px;
        }
        @include breakpoint(max-md_device){
            height: 500px;
        }
        @include breakpoint(max-sm_device){
            height: 600px;
        }
    }
    &:after{
        content: "";
        background: #041632;
        opacity: .8;
        height: 100%;
        width: 100%;
        position: absolute;
        z-index: 1;
    }
    .counter-content{
        z-index: 2;
        position: relative;
        .large-title-3{
            position: relative;
            padding-bottom: 30px;
            margin-bottom: 37px;
            @include breakpoint(max-xl_device){
                font-size: 60px;
                margin-bottom: 30px;
            }
            @include breakpoint(max-lg_device){
                font-size: 50px;
                margin-bottom: 17px;
                padding-bottom: 20px;
            }
            @include breakpoint(max-md_device){
                font-size: 30px;
            }
            @include breakpoint(max-xxs_device){
                font-size: 25px;
                margin-bottom: 7px;
                padding-bottom: 10px;
            }
            &:after{
                position: absolute;
                bottom: 0;
                left: 0;
                content: "";
                height: 2px;
                background: $white-color;
                width: 20%;
            }
        }
        .desc-content{
            font-size: 18px;
            line-height: 1.8em;
            @include breakpoint(max-md_device){
                font-size: 16px;
                line-height: 1.4em;
            }
            @include breakpoint(max-xxs_device){
                font-size: 14px;
            }
        }
    }
    .counter-wrapper{
        position: relative;
        z-index: 2;
        text-align: center;
        top: 50%;
        transform: translateY(-50%);
        .conuter-box{
            padding: 25px 0;
            @include breakpoint(max-lg_device){
                padding: 20px 0;
            }
            @include breakpoint(max-sm_device){
                padding: 30px 0 0 0;
            }
        }
        .large-title-3{
            color: $primary-color-4;
            font-weight: 600;
            margin-bottom: 16px;
            @include breakpoint(max-xl_device){
                font-size: 60px;
            }
            @include breakpoint(max-lg_device){
                font-size: 50px;
            }
            @include breakpoint(max-md_device){
                font-size: 40px;
            }
            @include breakpoint(max-xxs_device){
                font-size: 30px;
                margin-bottom: 10px;
            }
        }
        .counter-text{
            font-family: $general-font-family-2;
            font-size: 24px;
            font-weight: normal;
            text-transform: uppercase;
            @include breakpoint(max-lg_device){
                font-size: 20px;
            }
            @include breakpoint(max-xxs_device){
                font-size: 14px;
            }
        }
    }
}
.product-countdown-area{
    .deal-img{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: -120px;
        @include breakpoint(max-md_device){
            margin-bottom: 10px;
        }
        .deal-img-1{
            position: relative;
            left: 80px;
            z-index: 1;
            @include breakpoint(max-xs_device){
                left: 100px;
            }
            @include breakpoint(max-xxs_device){
                display: none;
            }
        }
        .deal-img-2{
            position: relative;
            left: -40px;
            z-index: 2;
            @include breakpoint(max-xxs_device){
                left: 40px;
            }
        }
        .deal-img-3 {
            position: relative;
            top: -100px;
            left: -80px;
            z-index: 3;
            @include breakpoint(max-xs_device){
                left: -100px;
            }
            @include breakpoint(max-xxs_device){
                top: -225px;
                left: -42px;
            }
        }
    }
    .deal-content{
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        .countdown{
            @include breakpoint(max-md_device){
                display: flex;
                justify-content: center;
            }
        }
        .deal-title{
            color: $white-color;
            font-family: $general-font-family-5;
            font-size: 46px;
            font-weight: 400;
            line-height: 46px;
            text-align: center;
            margin-bottom: 40px;
            @include breakpoint(max-md_device){
                font-size: 40px;
                margin-bottom: 25px;
            }
            @include breakpoint(max-xxs_device){
                font-size: 30px;
                font-size: 30px;
            }
        }
    }
    .deal-price{
        text-align: center;
        position: relative;
        bottom: -140px;
        z-index: 4;
        @include breakpoint(max-md_device){
            bottom: 0px;
            margin-bottom: 40px;
        }
        span{
            color: $white-color;
            font-family: $general-font-family-5;
            font-size: 46px;
            font-weight: 400;
            line-height: 46px;
            @include breakpoint(max-xs_device){
                font-size: 40px;
                line-height: 40px;
            }
            @include breakpoint(max-xxs_device){
                font-size: 30px;
                line-height: 30px;
            }
        }
    }
    .deal-btn{
        display: flex;
        justify-content: center;
        margin-top: 50px;
        margin-bottom: -100px;
        position: relative;
        @include breakpoint(max-md_device){
            margin-top: 25px;
            margin-bottom: -80px;
        }
    }
    .countdown-wrapper{
        display: flex;
        .single-countdown{
            margin-right:30px;
            text-align:center;
            background-color: $white-color;
            border-radius: 100%;
            padding: 10px;
            width: 100px;
            height: 100px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            @include breakpoint(max-xs_device){
                margin-right: 20px;
                width: 80px;
                height: 80px;
            }
            @include breakpoint(max-xxs_device){
                margin-right: 10px;
                width: 50px;
                height: 50px;
            }
            .single-countdown_time{
                font-size:36px;
                line-height:36px;
                color:#000;
                font-family: $general-font-family-5;
                @include breakpoint(max-xs_device){
                    font-size: 24px;
                    line-height: 24px;
                }
                @include breakpoint(max-xxs_device){
                    font-size: 20px;
                    line-height: 20px;
                }
            }
            .single-countdown_text{
                font-size:18px;
                text-transform:lowercase;
                font-family: $general-font-family-5;
                color: #000;
                line-height:18px;
                @include breakpoint(max-xs_device){
                    font-size: 16px;
                    line-height: 16px;
                }
                @include breakpoint(max-xxs_device){
                    font-size: 14px;
                    line-height: 14px;
                }
            }
            &:last-child{
                margin-right: 0px;
            }
        }
    }
}
.single-product-countdown{
    .countdown-wrapper{
        display: flex;
        .single-countdown{
            margin-right:0px;
            text-align:center;
            background-color: $white-color;
            border-radius: 100%;
            padding: 0px 0px 10px 0px;
            width: 60px;
            height: 60px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            @include breakpoint(max-xs_device){
                margin-right: 20px;
                width: 80px;
                height: 80px;
            }
            @include breakpoint(max-xxs_device){
                margin-right: 10px;
                width: 50px;
                height: 50px;
            }
            .single-countdown_time{
                font-size:26px;
                line-height:26px;
                color:$text-color;
                font-weight: 400;
                padding: 0px;
                font-family: $body-font-family-name;
                @include breakpoint(max-xs_device){
                    font-size: 24px;
                    line-height: 24px;
                }
                @include breakpoint(max-xxs_device){
                    font-size: 20px;
                    line-height: 20px;
                }
            }
            .single-countdown_text{
                font-size: 14px;
                font-family: $body-font-family-name;
                color: $text-color;
                font-weight: 400;
                @include breakpoint(max-xs_device){
                    font-size: 16px;
                    line-height: 16px;
                }
                @include breakpoint(max-xxs_device){
                    font-size: 14px;
                    line-height: 14px;
                }
            }
            &:last-child{
                margin-right: 0px;
            }
        }
    }
}
.coming-soon-countdown{
    display: flex;
    justify-content: center;
    margin-top: 40px;
    .countdown-wrapper{
        display: flex;
        .single-countdown{
            margin-right: 110px;
            text-align:center;
            display: flex;
            justify-content: center;
            flex-direction: column;
            @include breakpoint(max-xs_device){
                margin-right: 20px;
                width: 80px;
                height: 80px;
            }
            @include breakpoint(max-xxs_device){
                margin-right: 10px;
                width: 50px;
                height: 50px;
            }
            .single-countdown_time{
                font-size: 32px;
                font-weight: 600;
                line-height: 1.4em;
                color:$white-color;
                padding: 0px;
                font-family: $body-font-family-name;
                @include breakpoint(max-xs_device){
                    font-size: 24px;
                    line-height: 24px;
                }
                @include breakpoint(max-xxs_device){
                    font-size: 20px;
                    line-height: 20px;
                }
            }
            .single-countdown_text{
                font-size: 14px;
                font-family: $body-font-family-name;
                color: $white-color;
                font-weight: 400;
                @include breakpoint(max-xs_device){
                    font-size: 16px;
                    line-height: 16px;
                }
                @include breakpoint(max-xxs_device){
                    font-size: 14px;
                    line-height: 14px;
                }
            }
            &:last-child{
                margin-right: 0px;
            }
        }
    }
}