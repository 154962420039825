/*----------------------------------------*/
/*    28 - Components - Product
/*----------------------------------------*/
.product-tab-area{
    .heading-one{
        padding-bottom: 38px;
        @include breakpoint(max-xxs_device){
            font-size: 24px;
            line-height: 18px;
        }
    }
}
.tab-section_area {
    display: flex;
    justify-content: center;
    align-items:center;
    padding-bottom: 33px;
}
.product-menu{
    @include breakpoint(max-sm_device){
        justify-content: center;
    }
    li {
        a{
            color: $snow-color;
            font-size: 18px;
            font-weight: 400;
            font-family: $body-font-family-name;
            padding: 0 30px;
            text-transform: uppercase;
            @include breakpoint(max-sm_device){
                padding: 0 24px;
            }
            @include breakpoint(max-xs_device){
                padding: 0 14px;
            }
            @include breakpoint(max-xxs_device){
                font-size: 14px;
                padding: 0 15px;
            }
            &:hover, &.active{
                color: $text-color;
            }
        }
    }
}
.product-item {
    .single-product {
        @extend %basetransition;
        .product-img {
            position: relative;
            text-align: center;
            overflow: hidden;
            > a {
                display: block;
                img{
                    width: 100%;
                    height: 100%;
                }
                .product-overlay{
                    position: absolute;
                    left: 0;
                    top: 0;
                    opacity: 0;
                    content: "";
                    background-color: $text-color;
                    width: 100%;
                    height: 100%;
                    @extend %basetransition;
                }
            }
            &:hover{
                a{
                    .product-overlay{
                        opacity: .3;
                    }
                }
            }
            .add-actions {
                position: absolute;
                bottom: 50%;
                transform: translate(0%, 80px);
                @extend %basetransition;
                opacity: 0;
                left: 0;
                right: 0;
                display: flex;
                justify-content: center;
                z-index: 9;
                > ul {
                    > li {
                        display: inline-block;
                        transform: translateY(0);
                        &:not(:last-child){
                            margin-right: 5px;
                        }
                        @extend %basetransition;
                        &:first-child {
                            transition-delay: 100ms;
                        }
                        &:nth-child(2) {
                            transition-delay: 150ms;
                        }
                        &:nth-child(3) {
                            transition-delay: 200ms;
                        }
                        &:last-child {
                            transition-delay: 250ms;
                            > a{
                                padding-right: 0;
                            }
                        }
                        > a {
                            background-color: $white-color;
                            border: 1px solid $border-color;
                            width: 40px;
                            height: 40px;
                            line-height: 42px;
                            display: block;
                            text-align: center;
                            color: $text-color;
                            transition: .03s !important;
                            &:hover{
                                background-color: $primary-color;
                                border-color: $primary-color;
                                color: $white-color !important;
                                transition: .03s !important;
                            }
                            > i {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
        .product-content {
            padding-top: 15px;
            .product-desc_info {
                .price-box {
                    @include new-price;
                    @include old-price;
                }
                h3{
                    &.product-name{
                        font-family: $body-font-family-name;
                        font-weight: 700;
                        margin-bottom: 10px;
                        font-size: inherit;
                        > a{
                            font-size: 18px;
                            line-height: 19px;
                            font-weight: 700;
                            @include breakpoint(max-xs_device){
                                font-size: 18px;
                            }
                        }
                    }
                }
                .price-box{
                    > span{
                        font-family: $body-font-family-name;
                        &.new-price{
                            color: $charcoal-color;
                            line-height: 20.8px;
                            font-size: 16px;
                        }
                    }
                }
            }
        }
        &:hover {
            .product-img {
                .add-actions {
                    opacity: 1;
                    @extend %basetransition;
                    > ul{
                        > li{
                            transform: translateY(-60px);
                        }
                    }
                }
            }
        }
    }
}
.box-label{
    .label-1{
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        border-top: 50px solid $black-color;
        border-right: 50px solid transparent;
        span{
            color: $white-color;
            position: absolute;
            bottom: 25px;
            left: 5px;
            text-transform: uppercase;
            font-size: 12px;

        }
    }
    .label-2{
        position: absolute;
        top: 5px;
        right: 5px;
        span{
            background: $black-color;
            color: $white-color;
            border-radius: 50%;
            font-size: 12px;
            height: 40px;
            width: 40px;
            line-height: 40px;
            display: block;
        }
    }
    .label-3{
        position: absolute;
        top: 0;
        right: 0;
        span{
            font-size: 12px;
            text-transform: uppercase;
            background-color: $black-color;
            color: $white-color;
            padding: 5px 5px;
        }
    }
    .label-4{
        position: absolute;
        top: 18px;
        right: 0;
        span{
            font-size: 12px;
            text-transform: uppercase;
            background-color: $black-color;
            color: $white-color;
            padding: 5px 8px;
        }
    }
}
.mgana-product-tab{
    .product-item{
        padding: 0 25px;
        @include breakpoint(max-lg_device){
            padding: 0 20px;
        }
    }
    .slick-list{
        margin-right: -25px;
        margin-left: -25px;
        @include breakpoint(max-lg_device){
            margin-right: -20px;
            margin-left: -20px;
        }
    }
    .custom-dots{
        .slick-dots{
            bottom: -80px;
        }
    }
}
.product-carousel-area{
    padding-bottom: 76px;
    .heading-one{
        @include breakpoint(max-xxs_device){
            font-size: 24px;
            letter-spacing: 2px;
        }
    }
}
/*--- Product Item 3 ---*/
.mgana-product-3{
    .slick-list{
        margin: 0 -20px;
    }
}
.product-item-2{
    padding: 0 20px;
    .single-product {
        @extend %basetransition;
        .product-img {
            position: relative;
            text-align: center;
            overflow: hidden;
            > a {
                display: block;
                img{
                    width: 100%;
                    height: 100%;
                }
                .product-overlay{
                    position: absolute;
                    left: 0;
                    top: 0;
                    opacity: 0;
                    content: "";
                    background-color: $text-color;
                    width: 100%;
                    height: 100%;
                    @extend %basetransition;
                }
                .primary-image{
                    opacity: 1;
                    background: $white-color;
                    @extend %basetransition;
                }
                .secondary-image{
                    opacity: 0;
                    background: $white-color;
                    position: absolute;
                    top: 0;
                    left: 0;
                    @extend %basetransition;
                }
            }
            &:hover{
                a{
                    .product-overlay{
                        opacity: .3;
                    }
                }
                .primary-image{
                    opacity: 0;
                    @extend %basetransition;
                }
                .secondary-image{
                    opacity: 1;
                    @extend %basetransition;
                }
            }
            .add-actions {
                position: absolute;
                @extend %basetransition;
                opacity: 0;
                top: 18%;
                right: 0;
                display: flex;
                justify-content: center;
                z-index: 9;
                > ul {
                    > li {
                        display: block;
                        transform: translateX(0);
                        &:not(:last-child){
                            margin-bottom: 8px;
                        }
                        @extend %basetransition;
                        &:first-child {
                            transition-delay: 100ms;
                        }
                        &:nth-child(2) {
                            transition-delay: 150ms;
                        }
                        &:nth-child(3) {
                            transition-delay: 200ms;
                        }
                        &:last-child {
                            transition-delay: 250ms;
                            > a{
                                padding-right: 0;
                            }
                        }
                        > a {
                            background-color: $theme-black-color;
                            border: 1px solid $theme-black-color;
                            width: 40px;
                            color: $white-color !important;
                            height: 40px;
                            line-height: 42px;
                            border-radius: 100%;
                            display: block;
                            text-align: center;
                            color: $text-color;
                            transition: .03s !important;
                            &:hover{
                                background-color: $primary-color-5;
                                border-color: $primary-color-5;
                                color: $white-color !important;
                                transition: .03s !important;
                            }
                            > i {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
        .product-content {
            background: rgb(255, 255, 255);
            .product-desc_info {
                padding: 20px 0;
                border-top: 1px solid $border-color-2;
                margin: 0 20px;
                .price-box {
                    @include new-price;
                    @include old-price;
                }
                h3{
                    &.product-name{
                        font-family: $general-font-family-4;
                        color: #000;
                        font-weight: 500;
                        margin-bottom: 10px;
                        font-size: 1.125em;
                        font-style: italic;
                        > a{
                            line-height: 19px;
                            @include breakpoint(max-xl_device){
                                font-size: 14px;
                            }
                        }
                    }
                }
                .price-box{
                    > span{
                        font-family: $body-font-family-name;
                        &.new-price{
                            color: $charcoal-color;
                            line-height: 20.8px;
                            font-size: 16px;
                        }
                    }
                }
            }
        }
        &:hover {
            .product-img {
                .add-actions {
                    opacity: 1;
                    @extend %basetransition;
                    > ul{
                        > li{
                            transform: translateX(-35px);
                        }
                    }
                }
            }
        }
    }
}
.product-item-4{
    padding: 0 20px;
    .single-product {
        @extend %basetransition;
        .product-img {
            position: relative;
            text-align: center;
            overflow: hidden;
            > a {
                display: block;
                img{
                    width: 100%;
                    height: 100%;
                }
                .product-overlay{
                    position: absolute;
                    left: 0;
                    top: 0;
                    opacity: 0;
                    content: "";
                    background-color: $text-color;
                    width: 100%;
                    height: 100%;
                    @extend %basetransition;
                }
            }
            &:hover{
                a{
                    .product-overlay{
                        opacity: .3;
                    }
                }
            }
            .add-actions {
                position: absolute;
                bottom: 50%;
                transform: translate(0%, 80px);
                @extend %basetransition;
                opacity: 0;
                left: 0;
                right: 0;
                display: flex;
                justify-content: center;
                z-index: 9;
                > ul {
                    > li {
                        display: inline-block;
                        transform: translateY(0);
                        &:not(:last-child){
                            margin-right: 5px;
                        }
                        @extend %basetransition;
                        &:first-child {
                            transition-delay: 100ms;
                        }
                        &:nth-child(2) {
                            transition-delay: 150ms;
                        }
                        &:nth-child(3) {
                            transition-delay: 200ms;
                        }
                        &:last-child {
                            transition-delay: 250ms;
                            > a{
                                padding-right: 0;
                            }
                        }
                        > a {
                            background-color: $white-color;
                            border: 1px solid $border-color;
                            width: 40px;
                            height: 40px;
                            line-height: 42px;
                            display: block;
                            text-align: center;
                            color: $text-color;
                            transition: .03s !important;
                            &:hover{
                                background-color: $primary-color-7;
                                border-color: $primary-color-7;
                                color: $white-color !important;
                                transition: .03s !important;
                            }
                            > i {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
        .product-content {
            padding: 20px 30px;
            background-color: $white-color;
            .product-desc_info {
                .price-box {
                    @include new-price;
                    @include old-price;
                }
                h3{
                    &.product-name{
                        > a{
                            font-family: $general-font-family-6;
                            font-weight: 400;
                            margin-bottom: 10px;
                            font-size: 18px;
                            line-height: 18px;
                            color: #000;
                            text-transform: capitalize;
                            &:hover{
                                color: $primary-color-7;
                            }
                        }
                    }
                }
                .price-box{
                    > span{
                        font-family: $general-font-family-7;
                        &.new-price{
                            color: #000;
                            line-height: 20.8px;
                            font-size: 16px;
                        }
                    }
                }
            }
        }
        &:hover {
            .product-img {
                .add-actions {
                    opacity: 1;
                    @extend %basetransition;
                    > ul{
                        > li{
                            transform: translateY(-60px);
                        }
                    }
                }
            }
        }
    }
}
/*--- Product Item 4 ---*/
.product-item-3{
    padding: 0 10px;
    .single-product {
        @extend %basetransition;
        border: 1px solid $border-color-2;
        border-radius: 16px;
        overflow: hidden;
        &:hover{
            box-shadow: 0 0 14px 0 rgba(0,0,0,.1);
        }
        .product-img {
            position: relative;
            text-align: center;
            overflow: hidden;
            > a {
                display: block;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .add-actions {
                position: absolute;
                @extend %basetransition;
                opacity: 0;
                top: 25%;
                right: 0;
                display: flex;
                justify-content: center;
                z-index: 9;
                > ul {
                    > li {
                        display: block;
                        transform: translateX(0);
                        &:not(:last-child){
                            margin-bottom: 8px;
                        }
                        @extend %basetransition;
                        &:first-child {
                            transition-delay: 100ms;
                        }
                        &:nth-child(2) {
                            transition-delay: 150ms;
                        }
                        &:nth-child(3) {
                            transition-delay: 200ms;
                        }
                        &:last-child {
                            transition-delay: 250ms;
                            > a{
                                padding-right: 0;
                            }
                        }
                        > a {
                            background-color: $theme-black-color;
                            border: 1px solid $theme-black-color;
                            width: 40px;
                            color: $white-color !important;
                            height: 40px;
                            line-height: 42px;
                            border-radius: 100%;
                            display: block;
                            text-align: center;
                            color: $text-color;
                            transition: .03s !important;
                            &:hover{
                                background-color: $primary-color-6;
                                border-color: $primary-color-6;
                                color: $white-color !important;
                                transition: .03s !important;
                            }
                            > i {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
        .product-content {
            background: rgb(255, 255, 255);
            .product-desc_info {
                padding: 20px 0;
                margin: 0 20px;
                .price-box {
                    @include new-price;
                    @include old-price;
                }
                h3{
                    &.product-name{
                        > a{
                            line-height: 19px;
                            font-family: $general-font-family-5;
                            color: #000;
                            font-weight: 400;
                            margin-bottom: 10px;
                            font-size: 28px;
                            &:hover{
                                color: $primary-color-6;
                            }
                            @include breakpoint(max-xl_device){
                                font-size: 14px;
                            }
                        }
                    }
                }
                .price-box{
                    > span{
                        font-family: $general-font-family-5;
                        &.new-price{
                            color: #000;
                            line-height: 20.8px;
                            font-size: 20px;
                        }
                        &.old-price{
                            font-size: 20px;
                        }
                    }
                }
            }
        }
        &:hover {
            .product-img {
                .add-actions {
                    opacity: 1;
                    @extend %basetransition;
                    > ul{
                        > li{
                            transform: translateX(-35px);
                        }
                    }
                }
            }
        }
    }
}
/*--- Product Tab 2 ---*/
.product-tab-area-2{
    .tab-section_area{
        padding-bottom: 84px;
        @include breakpoint(max-sm_device){
            padding-top: 10px;
            padding-bottom: 60px;
        }
    }
    .product-menu {
        li{
            a{
                font-size: 32px;
                letter-spacing: 2px;
                text-transform: uppercase;
                font-weight: 600;
                font-family: $general-font-family-name;
                @include breakpoint(max-md_device){
                    padding: 0 16px;
                }
                @include breakpoint(max-sm_device){
                    padding: 0 14px;
                    font-size: 22px;
                    line-height: 16px;
                }
                @include breakpoint(max-xs_device){
                    padding: 0 10px;
                    font-size: 18px;
                    line-height: 22px;
                }
            }
            @include breakpoint(max-xs_device){
                line-height: 12px;
            }
            @include breakpoint(max-xs_device){
                line-height: 16px;
            }
        }
    }
}
/*--- Product Tab 4 ---*/
.product-tab-4{
    padding-top: 87px;
    @include breakpoint(max-sm_device){
        padding-top: 55px;
    }
    @include breakpoint(max-xs_device){
        padding-top: 53px;
    }
}
/*--- Product Tab 6 ---*/
.product-tab-6{
    .product-menu{
        li{
            a{
                font-size: 20px;
                text-transform: capitalize;
            }
        }
    }
    .product-menu-2{
        justify-content: center;
        li{
            a{
                font-size: 36px;
                line-height: 36px;
                text-transform: uppercase;
                font-family: $general-font-family-2;
                color: $snow-color;
                font-weight: normal;
                letter-spacing: 4px;
                border-bottom: 4px solid transparent;
                padding-bottom: 24px;
                margin: 0 45px 38px 45px;
                display: block;
                @include breakpoint(max-md_device){
                    margin: 0px 25px 38px 25px;
                    font-size: 26px;
                    line-height: 26px;
                }
                @include breakpoint(max-md_device){
                    margin: 0px 20px 20px 20px;
                    font-size: 20px;
                    line-height: 20px;
                    padding-bottom: 15px;
                }
                &:hover {
                    color: $text-color;
                    border-bottom: 4px solid $snow-color;
                }
                &.active {
                    color: $text-color;
                    border-bottom: 4px solid $snow-color;
                }
            }
        }
    }
    .product-img{
        border: 1px solid $border-color-3;
    }
    .product-item {
        .single-product{
            .product-img{
                .add-actions{
                    > ul{
                        > li{
                            > a:hover{
                                background-color: $primary-color-4;
                                border-color: $primary-color-4;
                            }
                        } 
                    }
                }
            }
        }
    }
}
.mgana-product-2{
    padding-bottom: 66px;
    @include breakpoint(max-sm_device){
        padding-bottom: 62px;
    }
    .product-item{
        padding: 0 15px;
    }
    .slick-list{
        margin: 0 -15px;
    }
    .custom-dots{
        .slick-dots{
            bottom: -70px;
        }
    }
}
/*--- New Collection Product ---*/
.pet-product-area{
    padding-bottom: 80px;
    @include breakpoint(max-md_device){
        padding-bottom: 70px;
    }
    @include breakpoint(max-sm_device){
        padding-bottom: 65px;
    }
}
.new-product-wrapper{
    .product-header{
        max-width: 770px;
        margin: 0 auto;
        .desc-content{
            line-height: 30px;
        }
    }
    .trending-product-img{
        @include breakpoint(max-sm_device){
            margin-bottom: 20px;
        }
    }
    .trending-product-content{
        .heading-5{
            letter-spacing: normal;
            @include breakpoint(max-md_device){
                font-size: 30px;
                line-height: 30px;
            }
            @include breakpoint(max-sm_device){
                font-size: 24px;
                line-height: 24px;
            }
        }
        .desc-content{
            font-size: 18px;
            line-height: 30px;
            margin-top: 20px;
            @include breakpoint(max-md_device){
                font-size: 16px;
                line-height: 24px;
            }
        }
    }
}
/*--- Product Tab 7 ---*/
.product-tab-7{
    &.bg-fantasy-2{
        background-color: #f6f0f0;
        background-image: url(../images/bg/home/12-1.jpg);
        background-position: top center;
        background-repeat: no-repeat;
    }
    .tab-section_area{
        padding: 75px 0px;
    }
    .product-menu-2{
        li{
            a{
                font-family: $general-font-family-6;
                font-size: 46px;
                line-height: 36px;
                font-weight: 400;
                font-style: italic;
                color: $primary-color-7;
                padding: 10px 35px 10px 35px;
                &.active{
                    color: $text-color;
                }
                @include breakpoint(max-md_device){
                    font-size: 40px;
                }
                @include breakpoint(max-sm_device){
                    font-size: 34px;
                    padding: 10px 20px 10px 20px;
                }
                @include breakpoint(max-xs_device){
                    font-size: 30px;
                    padding: 10px;
                }
                @include breakpoint(max-xxs_device){
                    font-size: 20px;
                    padding: 5px;
                }
            }
        }
    }
}
/*--- Product Tab 8 ---*/
.product-tab-8{
    .tab-section_area{
        padding-bottom: 50px;
    }
    .product-menu{
        li{
            display: flex;
            @include breakpoint(max-sm_device){
                margin-bottom: 20px;
            }
            a{
                font-size: 20px;
                text-transform: capitalize;
                font-family: $general-font-family-7;
                line-height: 16px;
                display: inline-block;
            }
        }
    }
    .product-menu-2{
        justify-content: center;
        li{
            a{
                font-size: 36px;
                line-height: 36px;
                text-transform: uppercase;
                font-family: $general-font-family-2;
                color: $snow-color;
                font-weight: normal;
                letter-spacing: 4px;
                border-bottom: 4px solid transparent;
                padding-bottom: 24px;
                margin: 0 45px 38px 45px;
                display: block;
                @include breakpoint(max-md_device){
                    margin: 0px 25px 38px 25px;
                    font-size: 26px;
                    line-height: 26px;
                }
                @include breakpoint(max-md_device){
                    margin: 0px 20px 20px 20px;
                    font-size: 20px;
                    line-height: 20px;
                    padding-bottom: 15px;
                }
                &:hover {
                    color: $text-color;
                    border-bottom: 4px solid $snow-color;
                }
                &.active {
                    color: $text-color;
                    border-bottom: 4px solid $snow-color;
                }
            }
        }
    }
    .product-img{
        border: 1px solid $border-color-2;
    }
    .product-item {
        .single-product{
            .product-img{
                .add-actions{
                    > ul{
                        > li{
                            > a{
                                &:hover{
                                    background-color: $theme-color--default;
                                    border-color: $theme-color--default;
                                }
                            }
                        } 
                    }
                }
            }
        }
    }
    .product-item{
        .single-product{
            .product-content{
                .product-desc_info{
                    h3{
                        &.product-name {
                            > a{
                                font-size: 18px;
                                font-family: $general-font-family-7;
                                font-weight: 400;
                            }
                        }
                    }
                    .price-box {
                        > span{
                            font-size: 16px;
                            font-family: $general-font-family-7;
                            font-weight: 400;
                        }
                    }
                } 
            } 
        } 
    } 
}
.mgana-product-8{
    .product-item{
        padding: 0 15px;
    }
    .slick-list{
        margin: 0 -15px;
    }
    .custom-dots{
        .slick-dots{
            bottom: -70px;
        }
    }
}
/*-- Product Counter Area --*/
.product-countdown-area{
    padding-bottom: 90px;
    background-image: url(../images/bg/home/11-3.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    height: 894px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    @include breakpoint(max-md_device){
        height: 1200px;
        padding-bottom: 80px;
    }
    @include breakpoint(max-xxs_device){
        height: 1050px;
    }
}
/*-- Product Area --*/
.product-area-1{
    margin-top: -25px;
}
/*-- color categories --*/
.color-categories {
    line-height: 1;
    li {
        display: inline-block;
        padding: 1px;
        border-radius: 50%;
        border: 1px solid $border-color;
        transition: 0.4s;
        &:hover {
            border-color: $primary-color;
        }
        a {
            height: 12px;
            width: 12px;
            display: block;
            border-radius: 50%;
        }
    }
}
.c-black {
    background-color: #000;
}
.c-green {
    background-color: #6DA06F;
}
.c-orange {
    background-color: #FE9F13
}
.c-brown {
    background-color: #964B00;
}
