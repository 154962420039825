/*----------------------------------------*/
/*    34 - Components - Animation
/*----------------------------------------*/
.slick-active {
	&.animation-style-01 {
		.slide-content,
		.slide-content-2, 
		.inner-content-2,
		.inner-content {
			> h4{
				animation-name: fadeInLeft;
				animation-delay: .7s;
				animation-duration: .7s;
				animation-fill-mode: both;
			}
			> h1 {
				animation-name: fadeInLeft;
				animation-delay: 1s;
				animation-duration: .9s;
				animation-fill-mode: both;
				
			}
			> p {
				animation-name: fadeInLeft;
				animation-delay: 1.5s;
				animation-duration: 1.3s;
				animation-fill-mode: both;
			}
			> .slide-btn {
				animation-name: fadeInLeft;
				animation-delay: .7s;
				animation-duration: 1.3s;
				animation-fill-mode: both;
            }
            > span {
                animation-name: fadeInLeft;
				animation-delay: 1.7s;
				animation-duration: 1.3s;
				animation-fill-mode: both;
            }
		}
		.slide-img{
			img{
				animation-name: fadeInRight;
				animation-delay: 1s;
				animation-duration: 1s;
				animation-fill-mode: both;
			}
		}
	}
}
.slick-active {
	&.animation-style-02 {
		.slide-content,
		.slide-content-4,
		.video-slide_content, 
		.inner-content-4,
		.inner-content {
			> h4{
				animation-name: zoomIn;
				animation-delay: .7s;
				animation-duration: .7s;
				animation-fill-mode: both;
			}
			> h2 {
				animation-name: zoomIn;
				animation-delay: 1s;
				animation-duration: .9s;
				animation-fill-mode: both;
				
			}
			> .slide-btn {
				animation-name: zoomIn;
				animation-delay: .7s;
				animation-duration: 1.3s;
				animation-fill-mode: both;
            }
		}
	}
}
.slick-active {
	&.animation-style-03 {
		 h4{
			animation-name: fadeInLeft;
			animation-delay: .7s;
			animation-duration: .7s;
			animation-fill-mode: both;
		}
		 h2 {
			animation-name: fadeInLeft;
			animation-delay: 1s;
			animation-duration: .9s;
			animation-fill-mode: both;
			
		}
		 .slide-btn {
			animation-name: fadeInLeft;
			animation-delay: .7s;
			animation-duration: 1.3s;
			animation-fill-mode: both;
		}
	}
}
.slick-active {
	&.animation-style-04 {
		 h4{
			animation-name: fadeInRight;
			animation-delay: .7s;
			animation-duration: .7s;
			animation-fill-mode: both;
		}
		 h2 {
			animation-name: fadeInRight;
			animation-delay: 1s;
			animation-duration: .9s;
			animation-fill-mode: both;
			
		}
		 .slide-btn {
			animation-name: fadeInRight;
			animation-delay: .7s;
			animation-duration: 1.3s;
			animation-fill-mode: both;
		}
	}
}
.slick-active {
	&.animation-style-05 {
		 h4{
			animation-name: fadeInUp;
			animation-delay: .7s;
			animation-duration: .7s;
			animation-fill-mode: both;
		}
		 h2 {
			animation-name: bounceIn;
			animation-delay: 1s;
			animation-duration: .9s;
			animation-fill-mode: both;
			
		}
		 .slide-btn {
			animation-name: fadeInUp;
			animation-delay: .7s;
			animation-duration: .9s;
			animation-fill-mode: both;
		}
	}
} 