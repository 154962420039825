/*----------------------------------------*/
/*    07 - Layout - Footer
/*----------------------------------------*/
.footer-area-1{
    .footer-top-area{
        padding: 90px 0px 82px;
        .logo{
            img{
                width: 180px;
                @include breakpoint(max-md_device){
                    margin-bottom: 50px;
                }
            }
        }
    }
    .custom-col-1{
        flex: 0 0 27.2%;
        max-width: 27.2%;
        @include breakpoint (max-md_device){
            flex: 0 0 100%;
            max-width: 100%;
            text-align: center;
        }
    }
    .custom-col-2{
        flex: 0 0 72.8%;
        max-width: 72.8%;
        @include breakpoint (max-md_device){
            flex: 0 0 100%;
            max-width: 100%;
        }
        .sm-space{
            @include breakpoint (max-sm_device){
                padding-bottom: 40px;
            }
        }
        .xsm-space{
            @include breakpoint (max-xs_device){
                padding-bottom: 40px;
            }
        }
    }
    .footer-widgets-area{
        @include breakpoint (max-xs_device){
            text-align: center;
        }
        .footer-widgets{
            .heading-3{
                color: $white-color;
                margin-bottom: 30px;
            }
            ul{
                li{
                    a{
                        font-size: 14px;
                        font-family: $body-font-family-name;
                        color: $footer-color;
                        padding-bottom: 10px;
                        display: flex;
                        @include breakpoint (max-xs_device){
                            justify-content: center;
                            display: block;
                        }
                        &:hover{
                            color: $white-color;
                        }
                        i{
                            font-size: 18px;
                            margin-right: 20px;
                            position: relative;
                            top: 3px;
                            @include breakpoint (max-xs_device){
                                margin-right: 10px;
                            }
                        }
                        span{
                            vertical-align: middle;
                        }
                    }
                    &:last-child{
                        a{
                            padding-bottom: 0px;
                        }
                    }
                }
            }
            .footer-widgets-content{
                font-size: 14px;
                color: $footer-color;
                display: block;
            }
        }
    }
    .footer-bottom-area{
        padding: 10px 0;
        border-top: 1px solid $border-color-4;
        .copyright{
            @include breakpoint(max-xs_device){
                display: flex;
                justify-content: center;
                margin-bottom: 10px;
            }
            @include breakpoint(max-xxs_device){
                display: block;
                text-align: center;
            }
            span, a{
                color: $footer-color;
                font-family: $body-font-family-name;
                font-size: 11px;
                text-transform: uppercase;
            }
            a:hover{
                color: $primary-color;
            }
        }
    }
}
.footer-area-2{
    background-color: $white-color;
    .footer-widgets-area{
        .logo{
            .desc-content{
                width: 80%;
            }
        }
        .footer-widgets{
            .heading-3{
                font-family: $general-font-family-2;
                color: $text-color;
                font-size: 18px;
                letter-spacing: normal;
            }
            ul{
                li{
                    a{
                        color: $text-color;
                        &:hover{
                            color: $primary-color-4;
                        }
                    }
                }
            }
            .footer-widgets-content{
                color: $text-color;
            }
        }
    }
    .footer-bottom-area {
        border-color: $border-color-3;
    }

    .footer-bottom-area{
        .copyright{
            span, a{
                color: $text-color;
            }
        } 
    }
    .social-link-2{
        ul{
            li{
                a{
                    color: $text-color;
                    &:hover{
                        color: $primary-color-4;
                    }
                }
            }
        }
    }
}
.footer-area-3{
    @include breakpoint(max-xl_device){
        .footer-top-area{
            .logo{
                img{
                    margin-bottom: 50px;
                }
            }
        }
    }
    .custom-col-1 {
        flex: 0 0 19.6%;
        max-width: 19.6%;
        @include breakpoint(max-xl_device){
            flex: 0 0 100%;
            max-width: 100%;
            text-align: center;
        }
    }
    .custom-col-2 {
        flex: 0 0 80.4%;
        max-width: 80.4%;
        @include breakpoint(max-xl_device){
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    .footer-widgets-area{
        .logo{
            .desc-content{
                width: 80%;
            }
        }
        .footer-widgets{
            .heading-3{
                font-family: $general-font-family-2;
                color: $white-color;
                font-size: 18px;
                letter-spacing: normal;
            }
            ul{
                li{
                    a{
                        &:hover{
                            color: $white-color;
                        }
                    }
                }
            }
        }
    }
    .footer-bottom-area {
        border-color: $border-color-4;
    }
    .social-link-2{
        ul{
            li{
                a{
                    color: $white-color;
                    &:hover{
                        color: $primary-color-4;
                    }
                }
            }
        }
    }
}
.footer-area-4{
    background-color: $theme-black-color;
    opacity: .9;
    padding: 50px 0px;
    margin-top: 90px;
    .footer-wrapper{
        width: 530px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @include breakpoint(max-xs_device){
            width: 400px;
        }
        @include breakpoint(max-xxs_device){
            width: 280px;
        }
        .footer-logo{
            img{
                width: 180px;
            }
            margin-bottom: 38px;
        }
        .footer-desc{
            font-family: $general-font-family-3;
            font-style: italic;
            font-size: 20px;
            color: $snow-color;
            margin-bottom: 20px;
        }
        .social-link-2{
            display: flex;
            justify-content: center;
            margin-bottom: 46px;
        }
        .copyright{
            color: $snow-color;
            text-transform: uppercase;
            font-size: 10px;
            letter-spacing: 3px;
            line-height: 10px;
            @include breakpoint(max-xxs_device){
                letter-spacing: 2px;
            }
            a{
                text-transform: uppercase;
                color: $snow-color;
                font-size: 10px;
                letter-spacing: 3px;
                line-height: 10px;
                &:hover{
                    color: $primary-color-5;
                }
            }
        }
    }
}
.footer-area-5{
    padding-top: 45px;
    margin-top: 90px;
    border-top: 1px solid #ebebeb;
    background-color: $white-color;
    &.footer-5-no-border{
        border: none;
        margin-top: 80px;
        padding: 0;
    }
    .footer-widgets-area{
        .logo{
            img{
                width: 230px;
                @include breakpoint(max-md_device){
                    margin-bottom: 50px;
                }
            }
        }
        .footer-widgets{
            .heading-3{
                font-family: $general-font-family-6;
                color: $theme-black-color;
                font-size: 18px;
                letter-spacing: normal;
                font-weight: 700;
                line-height: 18px;
                margin-bottom: 30px;

            }
            ul{
                li{
                    a{
                        color: $text-color;
                        font-size: 13px;
                        line-height: 13px;
                        font-family: $body-font-family-name;
                        margin-bottom: 20px;
                        display: flex;
                        &:hover{
                            color: $primary-color-7;
                        }
                    }
                    &:last-child{
                        a{
                            margin-bottom: 0px;
                        }
                    }
                    i{
                        font-size: 18px;
                        margin-right: 20px;
                        position: relative;
                        @include breakpoint (max-xs_device){
                            margin-right: 10px;
                        }
                    }
                    span{
                        vertical-align: middle;
                        position: relative;
                        top: 2px;
                    }
                }
            }
            .footer-widgets-content{
                color: $text-color;
                font-size: 14px;
            }
        }
    }
    .custom-col-1{
        flex: 0 0 27.2%;
        max-width: 27.2%;
        @include breakpoint (max-md_device){
            flex: 0 0 100%;
            max-width: 100%;
            text-align: center;
        }
    }
    .custom-col-2{
        flex: 0 0 72.8%;
        max-width: 72.8%;
        @include breakpoint (max-md_device){
            flex: 0 0 100%;
            max-width: 100%;
        }
        .sm-space{
            @include breakpoint (max-sm_device){
                padding-bottom: 40px;
            }
        }
        .xsm-space{
            @include breakpoint (max-xs_device){
                padding-bottom: 40px;
            }
        }
    }
    .footer-bottom-area{
        margin-top: 45px;
        padding: 7px 0px;
        border-top: 1px solid #ebebeb;
        .copyright{
            span, a{
                color: #454545;
                font-family: $general-font-family-7;
                font-size: 11px;
                text-transform: uppercase;
                letter-spacing: 1px;
            }
            a{
                &:hover{
                    color: $primary-color-7;
                }
            }
        } 
    }
}
.header-bottom-footer{
    padding-top: 20px;
    margin-top: 20px;
    border-top: 3px solid $border-color-3;
    .footer-widgets-area{
        .footer-widgets{
            ul{
                li{
                    a{
                        color: $text-color;
                        font-size: 13px;
                        line-height: 13px;
                        font-family: $body-font-family-name;
                        margin-bottom: 20px;
                        display: flex;
                        &:hover{
                            color: $primary-color;
                        }
                    }
                    &:last-child{
                        a{
                            margin-bottom: 0px;
                        }
                    }
                    i{
                        font-size: 18px;
                        margin-right: 20px;
                        position: relative;
                        @include breakpoint (max-xs_device){
                            margin-right: 10px;
                        }
                    }
                    span{
                        vertical-align: middle;
                        position: relative;
                        top: 2px;
                    }
                }
            }
            .footer-widgets-content{
                color: $text-color;
                font-size: 14px;
            }
        }
    }
}
.footer-area-transparent{
    position: absolute;
    width: 100%;
    bottom: 20px;
    left: 0;
    @include breakpoint(max-sm_device){
        position: static;
        bottom: 0px;
        padding: 20px 0px;
    }
    .copyright{
        @include breakpoint(max-xs_device){
            text-align: center;
        }
        span, a{
            font-family: "Fjalla One", Sans-serif;
            font-size: 11px;
            font-weight: normal;
            text-transform: uppercase;
            letter-spacing: 1px;
            color: $white-color;
            @include breakpoint(max-sm_device){
                color: $text-color;
            }
        }
    }
    .social-link-2{
        ul{
            li{
                a{
                    color: $text-color;
                    &:hover{
                        color: $primary-color-4;
                    }
                }
            }
        }
    }
}
.mobile-footer{
    position: sticky;
    bottom: 0px;
    left: 0;
    right: 0;
    z-index: 99;
    transition: all .3s ease;
    box-shadow: 0 2px 12px 2px rgba(0,0,0,0.1);
    background: #1a1a1a;
    display: none;
    @include breakpoint(max-sm_device){
        display: block;
    }
    .mobile-footer-nav{
        display: flex;
        justify-content: center;
        > li{
            flex-basis: 100%;
            margin: 0;
            padding: 20px 0;
            color: #fff;
            border-left: 1px solid rgba(255,255,255,0.1);
            text-align: center;
            &:first-child{
                border-left: 0;
            }
            > a{
                color: $white-color;
                justify-content: center;
                align-items: center;
                position: relative;
                vertical-align: middle;
                line-height: 10px;
                .badge{
                    background-color: $primary-color;
                    color: #ffffff;
                    position: absolute;
                    top: -12px;
                    right: -10px;
                    width: 15px;
                    height: 15px;
                    line-height: 17px;
                    font-size: 10px;
                    text-align: center;
                    border-radius: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-family: $general-font-family-3;
                }
            }
            .mobile-footer-dropdown{
                display: none;
                text-align: left;
                background-color: #1a1a1a;
                width: 100%;
                bottom: 100%;
                left: 0;
                position: absolute;
                margin: 0;
                border-bottom: 1px solid rgba(255,255,255,.1);
                box-shadow: 0 2px 12px 2px rgba(0,0,0,.1);
                padding: 15px;
                li{
                    a{
                        width: 100%;
                        color: $white-color;
                        font-size: 18px;
                        padding: 8px 10px;
                        display: block;
                    }
                }
            }
        }
    }
}
.footer-top-area-2{
    padding: 170px 0px 66px;
    margin-top: 90px;
    @include breakpoint(max-xxl_device){
        padding: 170px 0px 70px;
    }
    @include breakpoint(max-xs_device){
        padding: 90px 0px 60px;
    }
    .logo{
        img{
            width: 253px;
            @include breakpoint(max-md_device){
                width: 270px;
                padding-bottom: 80px;
            }
        }
    }
    .custom-col-1{
        flex: 0 0 27.2%;
        max-width: 27.2%;
        @include breakpoint(max-lg_device){
            flex: 0 0 26%;
            max-width: 26%;
        }
        @include breakpoint(max-md_device){
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    .custom-col-2{
        flex: 0 0 72.8%;
        max-width: 72.8%;
        @include breakpoint(max-lg_device){
            flex: 0 0 74%;
            max-width: 74%;
        }
        @include breakpoint(max-md_device){
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    .footer-widgets-area{
        .footer-widgets{
            ul{
                li{
                    a{
                        @include breakpoint(max-xl_device){
                            font-size: 13px;
                        }
                        @include breakpoint(max-lg_device){
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
}
.bg-footer-image{
    background-image: url(../images/bg/home/11-6.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    height: 493px;
    position: relative;
    align-items: center;
    @include breakpoint(max-xxl_device){
        height: 500px;
    }
    @include breakpoint(max-md_device){
        height: 632px;
    }
    @include breakpoint(max-sm_device){
        height: 872px;
    }
    @include breakpoint(max-xs_device){
        background-image: none;
        background-color: #3E3E3E;
        height: 100%;
    }
}
