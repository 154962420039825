/*----------------------------------------*/
/*    05 - Global - Color
/*----------------------------------------*/
/* ---Background Color--- */
.bg-smoke_color{
    background-color: $white-smoke_color;
}
.bg-white_color{
    background-color: $white-color;
}
.bg-wisp-pink{
    background-color: $wisp-pink_color;
}
.bg-snow{
    background-color: $snow-color;
}
.bg-clear_day{
    background-color: #ddeee9;
}
.bg-black{
    background-color: #000;
}
.bg-night_rider{
    background-color: #313131;
}
.bg-fantasy{
    background-color: #f1f1ee;
}
.bg-dim_gray{
    background-color: #6E6E6E;
}
.bg-snow{
    background-color: #f9f9f9;
}
.bg-cavern_pink{
    background-color: #dCb6b6;
}
.bg-hint-of_red{
    background-color: #f6f4f2;
}
.bg-tranquil{
    background-color: #e0f4f0;
}
.bg-footer-1{
    background-color: #2a2a2a;
}
.bg-sorbus{
    background-color: $primary-color;
}
/*---- Title Colors ----*/
.white-title-color{
    color: $white-color !important;
}
.default-title-color{
    color: $primary-color;
}
.default-title-color-2{
    color: $primary-color-2;
}
.default-title-color-3{
    color: $primary-color-3;
}
.default-title-color-6{
    color: $primary-color-6;
}
.black-title-color{
    color: $text-color;
}