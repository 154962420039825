/*----------------------------------------*/
/*    26 - Components - Banner
/*----------------------------------------*/
/*--- Free Banner Area start ---*/
.free-banner-wrapper{
    padding: 38px 0px;
    background: $primary-color-2;
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
    overflow: hidden;
    @include breakpoint (max-xl_device){
        padding: 30px 0;
    }
    @include breakpoint(max-md_device){
        display: block;
        text-align: center;
    }
    .free-banner-style{
        position: absolute;
        top: 0;
        right: 0;
        @include breakpoint(max-md_device){
            top: 10%;
        }
    }
    .free-banner-content{
        margin-right: 90px;
        @include breakpoint(max-lg_device){
            margin-right: 30px;
        }
        @include breakpoint(max-md_device){
            margin-right: 0px;
        }
        @include breakpoint(max-xs_device){
            margin-right: 0px;
        }
        .free-banner-heading{
            color: $white-color;
            font-family: $body-font-family-name;
            text-transform: uppercase;
            font-size: 32px;
            letter-spacing: 3px;
            margin: 0px;
            @include breakpoint(max-xl_device){
                font-size: 20px;
            }
            @include breakpoint(max-md_device){
                font-size: 24px;
            }
            @include breakpoint(max-xs_device){
                font-size: 20px;
                letter-spacing: 2px;
            }
            @include breakpoint(max-xxs_device){
                font-size: 12px;
                letter-spacing: 1px;
            }
        }
    }
    .free-button{
        .mgana-btn{
            @include breakpoint(max-lg_device){
                width: 150px;
                height: 50px;
                line-height: 50px;
            }
        }
        @include breakpoint(max-md_device){
            margin-top: 20px;
            display: flex;
            justify-content: center;
        }
    }
    .free-banner-style{
        @include breakpoint(max-sm_device){
            display: none;
        }
    }
}
/*--- Home 2 Banner Area start ---*/
.banner-content{
    &.left-37{
        left: 37%;
    }
    @include absCenter;
    .banner-title-1{
        font-size: 16px;
        font-family: $body-font-family-name;
        font-weight: 400;
        color: $banner-color-1;
        text-transform: initial;
        @include breakpoint(max-md_device){
            font-size: 12px;
        }
    }
    .banner-title-2{
        font-size: 56px;
        font-weight: 600;
        color: $banner-color-1;
        @include breakpoint(max-lg_device){
            font-size: 46px;
        }
        @include breakpoint(max-md_device){
            font-size: 30px;
        }
    }
    .banner-btn{
        .mgana-btn.mgana-btn-2 {
            @include breakpoint(max-lg_device){
                height: 35px;
                line-height: 35px;
                width: 100px;
                font-size: 12px;
            }
        }
    }
}
.banner-wrapper{
    overflow: hidden;
    .banner-image{
        a{
            img{
                width: 100%;
            }
        }
    }
    .custom-row{
        display: flex;
        margin: 0 -15px;
        @include breakpoint(max-sm_device){
            display: block;
            margin: 0;
        }
        .custom-col{
                position: relative;
                padding: 0 15px;
                overflow: hidden;
                padding-bottom: 30px;
        }
        .custom-col-sm-6{
            @include breakpoint(max-sm_device){
                max-width: 50%;
                width: 50%;
                float: left;
            }
        }
        .custom-col-xs-12{
            @include breakpoint(max-xs_device){
                max-width: 100%;
                width: 100%;
                float: none;
            }
        }
        .custom-col-sm-12{
            @include breakpoint(max-sm_device){
                max-width: 100%;
                width: 100%;
            }
        }
    }
}
.banner-wrapper-2 {
    .custom-col-1 {
        flex: 0 0 29.8%;
        max-width: 29.8%;
        padding: 0 15px;
        position: relative;
        min-height: 1px;
        @include breakpoint(max-xl_device){
            flex: 0 0 29.5%;
            max-width: 29.5%;
        }
        @include breakpoint(max-md_device){
            flex: 0 0 29.1%;
            max-width: 29.1%;
        }
        @include breakpoint(max-sm_device){
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    .custom-col-2 {
        flex: 0 0 70.2%;
        max-width: 70.2%;
        position: relative;
        padding: 0 15px;
        min-height: 1px;
        @include breakpoint(max-xl_device){
            flex: 0 0 70.5%;
            max-width: 70.5%;
        }
        @include breakpoint(max-md_device){
            flex: 0 0 70.9%;
            max-width: 70.9%;
        }
        @include breakpoint(max-sm_device){
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    .custom-col-3{
        flex: 0 0 58.5%;
        max-width: 58.5%;
        padding: 0 15px;
        position: relative;
        min-height: 1px;
        @include breakpoint(max-xl_device){
            flex: 0 0 58.3%;
            max-width: 58.3%;
        }
        @include breakpoint(max-md_device){
            flex: 0 0 58%;
            max-width: 58%;
        }
        @include breakpoint(max-xs_device){
            flex: 0 0 100%;
            max-width: 100%;
            margin-bottom: 30px;
        }
    }
    .custom-col-4{
        flex: 0 0 41.5%;
        max-width: 41.5%;
        padding: 0 15px;
        position: relative;
        min-height: 1px;
        @include breakpoint(max-xl_device){
            flex: 0 0 41.7%;
            max-width: 41.7%;
        }
        @include breakpoint(max-md_device){
            flex: 0 0 42%;
            max-width: 42%;
        }
        @include breakpoint(max-xs_device){
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    .single-banner {
        position: relative;
        display: block;
        overflow: hidden;
        &:hover{
            .banner-image{
                a{
                    .banner-overlay{
                        opacity: .5;
                        @extend %basetransition;
                    }
                }
            }
            .banner-content-3{
                a{
                    background-color: $primary-color-4;
                    @extend %basetransition;
                    color: $white-color;
                }
            }
        }
    }
    .banner-image {
        position: relative;
        a {
            img {
                width: 100%;
            }
            .banner-overlay{
                position: absolute;
                left: 0;
                top: 0;
                opacity: 0;
                content: "";
                background-color: #000;
                width: 100%;
                height: 100%;
                @extend %basetransition;
            }
        }
    }
    .banner-content-2 {
        position: absolute;
        bottom: 0px;
        left: 0px;
        .banner-title-2 {
            color: $white-color;
            font-size: 16px;
            font-weight: 600;
            text-transform: uppercase;
            line-height: 1.5em;
            letter-spacing: 1px;
            padding: 14px 50px;
            background: $theme-black-color;
            text-align: center;
            @include breakpoint(max-md_device){
                font-size: 14px;
                line-height: 1em;
                padding: 10px 20px;
            }
        }
    }
    .banner-content-3 {
        position: absolute;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
        a {
            font-family: "Fjalla One", Sans-serif;
            font-size: 26px;
            line-height: 40px;
            width: 410px;
            background-color: $white-color;
            color: $text-color;
            padding: 20px 20px 20px 20px;
            text-transform:uppercase;
            display: block;
            text-align: center;
            @extend %basetransition;
            @include breakpoint(max-xl_device){
                width: 350px;
            }
            @include breakpoint(max-md_device){
                width: 410px;
            }
            @include breakpoint(max-xs_device){
                width: 250px;
                padding: 10px;
                font-size: 18px;
                line-height: 36px;
            }
        }
    }
    .bottom-res {
        margin-bottom: 30px;
        @include breakpoint(max-xxl_device){
            margin-bottom: 30px;
        }
    }
}
/*--- Banner Style 3 Start here ---*/
.banner-3-area{
    .banner-img{
        width: 50%;
        position: relative;
        display: block;
        overflow: hidden;
        @include breakpoint(max-sm_device){
            width: 100%;
            margin-bottom: 28px;
        }
        a{
            .banner-overlay{
                position: absolute;
                left: 0;
                top: 0;
                opacity: 0;
                content: "";
                background-color: #3e3e3e;
                width: 100%;
                height: 100%;
                @extend %basetransition;
            }
        }
        &:hover{
            a{
                .banner-overlay{
                    opacity: .2;
                    @extend %basetransition;
                }
            }
        }
    }
    .banner-content-3{
        width: 50%;
        @include breakpoint(max-sm_device){
            width: 100%;
        }
        .banner-inner-content{
            width: 88%;
            padding-left: 120px;
            @include breakpoint(max-lg_device){
                padding-left: 50px;
            }
            @include breakpoint(max-xs_device){
                padding-left: 20px;
                width: 94%;
            }
            .large-title-1{
                @include breakpoint(max-lg_device){
                    font-size: 50px;
                }
                @include breakpoint(max-md_device){
                    font-size: 40px;
                }
                @include breakpoint(max-xs_device){
                    font-size: 30px;
                }
            }
            span{
                border: 1px solid $border-color-3;
                display: block;
                width: 120px;
                margin-top: 32px;
                margin-bottom: 40px;
                @include breakpoint(max-lg_device){
                    margin-top: 22px;
                    margin-bottom: 30px;
                }
                @include breakpoint(max-md_device){
                    margin-top: 12px;
                    margin-bottom: 10px;
                }
            }
            .desc-one{
                padding-bottom: 20px;
                @include breakpoint(max-md_device){
                    padding-bottom: 0px;
                    font-size: 14px;
                }
            }
        }
    }
}
/*--- Banner Style 4 Start here ---*/
.banner-4-area{
    &.background-3{
        background-image: url(../images/banner/3-3.jpg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        height: 895px;
        position: relative;
        @include breakpoint(max-xl_device){
            height: 800px;
        }
        @include breakpoint(max-lg_device){
            height: 700px;
        }
        @include breakpoint(max-md_device){
            height: 600px;
        }
        @include breakpoint(max-sm_device){
            height: 500px;
        }
        @include breakpoint(max-xs_device){
            height: 450px;
        }
    }
    .large-title-1{
        font-size: 56px;
        margin-bottom: 20px;
        position: relative;
        display: block;
        @include breakpoint(max-md_device){
            font-size: 40px;
        }
        @include breakpoint(max-md_device){
            font-size: 30px;
        }
        @include breakpoint(max-xxs_device){
            font-size: 20px;
            margin-bottom: 10px;
        }
        &:before{
            content: '';
            position: absolute;
            top: 50%;
            border-top: 2px solid $white-color;
            width: 100px;
            transition: inherit;
            left: 88%;
            @include breakpoint(max-md_device){
                width: 60px;
            }
            @include breakpoint(max-md_device){
                width: 40px;
            }
            @include breakpoint(max-xs_device){
                display: none;
            }
        }
        &:after{
            content: '';
            position: absolute;
            top: 50%;
            border-top: 2px solid $white-color;
            width: 100px;
            transition: inherit;
            right: 88%;
            @include breakpoint(max-md_device){
                width: 60px;
            }
            @include breakpoint(max-md_device){
                width: 40px;
            }
            @include breakpoint(max-xs_device){
                display: none;
            }
        }
    }
    .desc-content{
        font-size: 24px;
        font-family: $general-font-family-name;
        line-height: 2em;
        margin-bottom: 35px;
        @include breakpoint(max-md_device){
            font-size: 18px;
            line-height: 1.5em;
        }
        @include breakpoint(max-xxs_device){
            font-size: 16px;
            margin-bottom: 20px;
        }
    }
    .popup-video{
        a{
            font-size: 30px;
            padding: 30px;
            background: $white-color;
            border-radius: 50%;
            @include breakpoint(max-md_device){
                font-size: 20px;
                padding: 30px;
                border-radius: 100%;
                line-height: 15px;
            }
            @include breakpoint(max-xxs_device){
                padding: 20px;
                line-height: 12px;
            }
            &:hover{
                background: $primary-color-3;
                color: $white-color;
            }
        }
    }
    .banner-content-4{
        max-width: 970px;
        margin: 0 auto;
        @include breakpoint(max-md_device){
            max-width: 760px;
        }
    }
}
/*--- Banner Style 5 Start here ---*/
.banner-5-area{
    .slick-list{
        margin: 0 -20px;
    }
    .banner-wrapper{
        position: relative;
        top: -110px;
        overflow: visible;
        .custom-dots{
            .slick-dots{
                bottom: -78px;
            }
        }
        @include breakpoint(max-sm_device){
            top: 0;
            overflow: inherit;
            position: inherit;
            padding: 90px 0px 105px 0px;
        }
    }
    .single-banner{
        display: block;
        overflow: hidden;
        padding: 0 20px;
    }
    .content-image{
        display: block;
        overflow: hidden;
        position: relative;
        &:hover{
            .banner-title-2{
                background: $theme-black-color;
                color: $white-color;
                @extend %basetransition;
            }
        }
    }
    .banner-content-2{
        @include absCenter;
        text-align: center;
        .banner-title-2{
            font-size: 18px;
            font-weight: 400;
            text-transform: uppercase;
            line-height: 2em;
            letter-spacing: 3px;
            padding: 5px;
            background: $white-color;
            width: 250px;
            @extend %basetransition;
            @include breakpoint(max-sm_device){
                width: 200px;
            }
        }
    }
}
/*--- Banner Style 6 Start here ---*/
.banner-6-area{
    padding-top: 53px;
    @include breakpoint(max-xxs_device){
        padding-top: 50px;
    }
    .banner-wrapper {
        display: flex;
        flex-wrap: wrap;

    }
    .single-banner-item {
        flex: 50%;
        max-width: 50%;
        position: relative;
        display: block;
        overflow: hidden;
        @include breakpoint(max-sm_device){
            flex: 100%;
            max-width: 100%;
        }
    }
    .button-style-2 {
        @include absCenter;
        text-align: center;
        .border-button-2{
            font-size: 24px;
            line-height: 1.3;
            letter-spacing: 3px;
            width: 460px;
            padding: 30px;
            border: 3px solid $white-color;
            color: $white-color;
            background: transparent;
            position: relative;
            @extend %basetransition;
            @include breakpoint(max-xl_device){
                width: 350px;
                padding: 20px;
            }
            @include breakpoint (max-lg_device){
                width: 260px;
                padding: 10px;
                font-size: 18px;
            }
            @include breakpoint (max-xxs_device){
                width: 200px;
                font-size: 12px;
            }
        }
    }
    .content-image{
        position: relative;
        &:hover{
            &:before{
                opacity: 1;
                transform: rotate(45deg) translateX(0);
                z-index: 9;
            }
            &:after{
                opacity: 1;
                transform: rotate(45deg) translateX(0);
                z-index: 9;
            }
            .border-button-2{
                background: $white-color;
                color: $text-color;
                &:before{
                    content: '';
                    position: absolute;
                    left: -3px;
                    top: -3px;
                    right: -3px;
                    bottom: -3px;
                    border: 3px solid $white-color;
                    transition: inherit;
                    transform: translate(10px,10px);
                    @extend %basetransition;
                }
            }
        }
        &:before{
            content: '';
            position: absolute;
            right: 25%;
            border-right: 12px solid rgba(255,255,255,.75);
            height: 150%;
            top: -4%;
            transition: all .35s;
            transform: rotate(45deg) translateX(100px);
            opacity: 0;
            @include breakpoint (max-xxl_device){
                top: 5%;
            }
        }
        &:after{
            content: '';
            position: absolute;
            right: 17%;
            border-right: 12px solid rgba(255,255,255,.4);
            height: 150%;
            top: 2%;
            transition: all .5s;
            transform: rotate(45deg) translateX(100px);
            opacity: 0;
            @include breakpoint (max-xxl_device){
                top: 10%;
            }
        }
    }
}
/*--- Banner Style 7 Start here ---*/
.banner-7-area{
    .banner-item{
        position: relative;
        display: block;
        overflow: hidden;
        &:hover{
            .view-more-btn{
                top: 50%;
                opacity: 1;
                transition: .5s;
            }
            .banner-overlay{
                opacity: .5;
                transition: .5s;
            }
        }
        .banner-overlay{
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
            content: "";
            background-color: $text-color;
            width: 100%;
            height: 100%;
            transition: .5s;
        }
        .view-more-btn{
            display: block;
            overflow: hidden;
            position: absolute;
            top: 60%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: .5s;
            opacity: 0;
            .view-more-3{
                font-family: $general-font-family-2;
                font-size: 24px;
                font-weight: normal;
                @include breakpoint(max-md_device){
                    font-size: 20px;
                }
            }
        }
    }
	.banner-text{
		position: absolute;
		top: 64%;
		right: 0;
		transform: translateY(-64%);
		text-align: center;
		&.banner-text_left {
			right: auto;
			left: 3%;
			@include breakpoint(max-xxs_device){
				left: 50%;
				top: 64%;
				transform: translate(-50%, -64%);
				width: 100%;
			}
		}
		&.banner-text_left-0 {
			right: auto;
			left: 0;
			@include breakpoint(max-xxs_device){
				left: 50%;
				top: 64%;
				transform: translate(-50%, -64%);
				width: 100%;
				text-align: center !important;
			}
		}
		&.banner-text_center {
			left: 50%;
			top: 64%;
			transform: translate(-50%, -64%);
			width: 100%;
		}
		&.banner-text_right {
			left: auto;
			right: 3%;
			@include breakpoint(max-xxs_device){
				left: 50%;
				top: 64%;
				transform: translate(-50%, -64%);
				width: 100%;
			}
		}
		.title-1{
			color: $white-color;
			text-transform: uppercase;
			&:hover{
				color: #ff7960;
			}
		}
		.heading-1{
			font-size: 80px;
			font-weight: 400;
			text-transform: uppercase;
			letter-spacing: 8px;
			color: #ff7960;
			margin-bottom: 30px;
			@include breakpoint(max-md_device){
				font-size: 70px;
				letter-spacing: 6px;
			}
			@include breakpoint(max-sm_device){
				font-size: 50px;
				letter-spacing: 5px;
			}
			@include breakpoint(max-xs_device){
				font-size: 40px;
				letter-spacing: 3px;
			}
			@include breakpoint(max-xxs_device){
				font-size: 30px;
				letter-spacing: 3px;
			}
		}
	}
	.banner-text-2{
		position: relative;
		text-align: center;
		&.banner-text_left-2 {
			right: auto;
			left: 5%;
			@include breakpoint(max-xxs_device){
				left: 50%;
				transform: translateX(-50%);
			}
		}
		&.banner-text_right-2 {
			left: auto;
			right: 5%;
			@include breakpoint(max-xxs_device){
				left: 50%;
				transform: translateX(-50%);
			}
		}
		.banner-button{
			font-family: $general-font-family-3;
			font-size: 26px;
			text-transform: uppercase;
			line-height: 50px;
			fill: #3e3e3e;
			color: #3e3e3e;
			background-color: #ffffff;
			padding: 30px 60px 30px 60px;
			text-transform: uppercase;
			&:hover{
				background-color: $primary-color-4;
				color: $white-color;
			}
			@include breakpoint(max-xxs_device){
				font-size: 20px;
				padding: 20px 40px;
			}
		}
	}
}
/*--- Banner Style 8 Start here ---*/
.banner-8-area{
    .banner-item{
        position: relative;
        display: block;
        overflow: hidden;
        &:hover{
            .banner-overlay{
                opacity: .8;
                transition: .5s;
            }
            .border-button-2{
                border-color:$primary-color-4;
                background-color: $primary-color-4;
            }
        }
        .banner-overlay{
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
            content: "";
            background-color: $default-color;
            width: 100%;
            height: 100%;
            transition: .5s;
        }
        .banner-info{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            .heading-5{
                letter-spacing: normal;
                margin-bottom: 30px;
            }
            .banner-button{
                display: flex;
                justify-content: center;
            }
        }
    }
}
/*--- Banner Style 3 Start here ---*/
.banner-9-area{
    .banner-img{
        width: 50%;
        position: relative;
        display: block;
        overflow: hidden;
        @include breakpoint(max-sm_device){
            width: 100%;
            margin-bottom: 28px;
        }
        &.background-3{
            background-image: url(../images/banner/7-5.jpg);
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            height: 740px;
            position: relative;
            @include breakpoint(max-xl_device){
                height: 730px;
            }
            @include breakpoint(max-lg_device){
                height: 700px;
            }
            @include breakpoint(max-md_device){
                height: 600px;
            }
            @include breakpoint(max-sm_device){
                height: 500px;
            }
            @include breakpoint(max-xs_device){
                height: 450px;
            }
        }
        .popup-video{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            a{
                font-size: 30px;
                padding: 30px;
                background: $white-color;
                border-radius: 50%;
            }
        }
    }
    .banner-content-4{
        width: 50%;
        @include breakpoint(max-sm_device){
            width: 100%;
        }
        .banner-inner-content{
            width: 88%;
            padding-left: 120px;
            @include breakpoint(max-xl_device){
                padding-left: 50px;
            }
            @include breakpoint(max-xs_device){
                padding-left: 20px;
                width: 94%;
            }
            .small-title-2{
                font-family: $general-font-family-2;
                color: $primary-color-4;
                letter-spacing: normal;
                margin-bottom: 13px;
            }
            .large-title-3{
                color: $heading-color-2;
                @include breakpoint(max-xl_device){
                    font-size: 50px;
                }
                @include breakpoint(max-md_device){
                    font-size: 40px;
                }
                @include breakpoint(max-xs_device){
                    font-size: 30px;
                }
            }
            span{
                border: 1px solid $heading-color-2;
                display: block;
                width: 120px;
                margin-top: 20px;
                margin-bottom: 23px;
                @include breakpoint(max-md_device){
                    margin-top: 12px;
                    margin-bottom: 10px;
                }
            }
            .desc-one{
                padding-bottom: 20px;
                @include breakpoint(max-md_device){
                    padding-bottom: 0px;
                    font-size: 14px;
                }
            }
        }
    }
}
/*--- Banner Style 10 Start here ---*/
.banner-10-area{
    .banner-img{
        width: 100%;
        position: relative;
        display: block;
        overflow: hidden;
        &.background-3{
            background-image: url(../images/banner/7-10.jpg);
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            height: 655px;
            position: relative;
            @include breakpoint(max-xl_device){
                height: 600px;
            }
            @include breakpoint(max-lg_device){
                height: 600px;
            }
            @include breakpoint(max-md_device){
                height: 600px;
            }
            @include breakpoint(max-sm_device){
                height: 500px;
            }
            @include breakpoint(max-xs_device){
                height: 450px;
            }
        }

    }
    .banner-content-5{
        position: relative;
        .banner-inner-content-2{
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            background: rgba(221, 169, 115, 0.8);
        }
    }
}
.banner-inner-content-2{
    padding: 100px 60px 55px;
    width: 470px;
    @include breakpoint(max-sm_device){
        padding: 50px 30px 35px;
        width: 400px;
    }
    @include breakpoint (max-xxs_device){
        padding: 40px 20px 25px;
        width: 290px;
    }
    .small-title-2 {
        font-family: $general-font-family-2;
        letter-spacing: normal;
        font-weight: normal;
    }
    .large-title{
        font-size: 150px;
        font-family: $general-font-family-2;
        font-weight: normal;
        position: relative;
        padding-top: 12px;
        @include breakpoint(max-sm_device){
            font-size: 125px;
        }
        span{
            top: 10%;
            font-size: 40px;
            position: absolute;
            right: 15%;
            @include breakpoint(max-sm_device){
                right: 26%;
            }
            @include breakpoint(max-xs_device){
                right: 4%;
            }
            @include breakpoint(max-xxs_device){
                right: 0;
            }
        }
    }
}
/*--- Banner Style 11 Start here ---*/
.banner-11-area{
    @include breakpoint(max-md_device){
        margin-bottom: 30px;
    }
    .banner-img{
        width: 100%;
        position: relative;
        display: block;
        overflow: hidden;
        &.background-3{
            background-image: url(../images/banner/9-1.jpg);
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            height: 938px;
            position: relative;
            @include breakpoint(max-xl_device){
                height: 770px;
            }
            @include breakpoint(max-lg_device){
                height: 600px;
            }
            @include breakpoint(max-md_device){
                height: 600px;
            }
            @include breakpoint(max-sm_device){
                height: 500px;
            }
            @include breakpoint(max-xs_device){
                height: 450px;
            }
        }
    }
    .banner-content-6 {
        position: relative;
        .banner-inner-content-2{
            position: absolute;
            left: 4%;
            top: 50%;
            transform: translateY(-50%);
            background: rgba(4,22,50,0.9);
            .large-title{
                color: $primary-color-4;
                @include breakpoint(max-xs_device){
                    font-size: 100px;
                }
            }
            @include breakpoint(max-lg_device){
                padding: 60px 24px 40px;
                width: 380px;
            }
            @include breakpoint(max-xs_device){
                padding: 30px 20px 30px;
                width: 250px;
                left: 0;
            }
            .small-title-2{
                @include breakpoint(max-xs_device){
                    font-size: 18px;
                }
            }
        }
    }
}
/*--- Banner Style 12 Start here ---*/
.banner-12-area{
    position: relative;
    .banner-img{
        width: 100%;
        position: relative;
        display: block;
        overflow: hidden;
        .image9-2{
            background-image: url(../images/banner/9-2.jpg);
        }
        .image9-3{
            background-image: url(../images/banner/9-3.jpg);
        }
        .background-3{
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            height: 454px;
            display: block;
            position: relative;
            @include breakpoint(max-xl_device){
                height: 370px;
            }
            @include breakpoint(max-lg_device){
                height: 285px;
            }
            @include breakpoint(max-md_device){
                height: 370px;
            }
            @include breakpoint(max-sm_device){
                height: 350px;
            }
            @include breakpoint(max-xs_device){
                height: 300px;
            }
        }
        .banner-overlay{
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
            content: "";
            background-color: $text-color;
            width: 100%;
            height: 100%;
            transition: .3s;
        }
    }
    .banner-info{
        position: absolute;
        bottom: 9%;
        left: 5%;
        .heading-5{
            font-family: $general-font-family-2;
            line-height: 46px;
            font-size: 46px;
            letter-spacing: normal;
            font-weight: normal;
            margin-bottom: 20px;
            @include breakpoint(max-lg_device){
                font-size: 36px;
                line-height: 36px;
            }
            @include breakpoint(max-xs_device){
                font-size: 30px;
                line-height: 30px;
            }
        }
    }
    .border-button-2{
        @include breakpoint(max-lg_device){
            width: 150px;
            height: 45px;
            line-height: 42px;
        }
    }
    &:hover{
        .border-button-2{
            background: $primary-color-4;
            border-color: $primary-color-4;
        }
        .banner-overlay{
            opacity: .4;
            transition: .3s;
        }
    }
}
/*--- Banner Style 13 Start here ---*/
.banner-13-area{
    .banner-img{
        width: 100%;
        position: relative;
        display: block;
        overflow: hidden;
        &.background-3{
            background-image: url(../images/banner/9-4.jpg);
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            height: 645px;
            position: relative;
            @include breakpoint(max-xl_device){
                height: 500px;
            }
            @include breakpoint(max-lg_device){
                height: 400px;
            }
            @include breakpoint(max-md_device){
                height: 400px;
            }
            @include breakpoint(max-sm_device){
                height: 400px;
            }
            @include breakpoint(max-xs_device){
                height: 400px;
            }
        }
    }
    .banner-content-5 {
        position: relative;
        .banner-inner-content-3 {
            position: absolute;
            right: -15%;
            background: rgba(4,22,50,0.9);
            height: 100%;
            transform: skew(-30deg);
            width: 55%;
            @include breakpoint(max-lg_device){
                width: 62%;
            }
            @include breakpoint(max-sm_device){
                width: 82%;
                right: -24%;
            }
            @include breakpoint(max-xxs_device){
                width: 100%;
                right: -36%;
            }
        }
    }
}
.banner-inner-content-3 {
    .content-wrapper{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: skew(30deg) translate(-50%, -50%);
        text-align: center;
        width: 100%;
        @include breakpoint(max-xxs_device){
            top: 70%;
        }
        .small-title-2 {
            font-family: $general-font-family-2;
            letter-spacing: normal;
            font-weight: normal;
            @include breakpoint(max-xxs_device){
                font-size: 18px;
            }
        }
        .large-title{
            font-size: 150px;
            font-family: $general-font-family-2;
            font-weight: normal;
            position: relative;
            color: $primary-color-4;
            padding-top: 12px;
            @include breakpoint (max-xl_device){
                font-size: 100px;
            }
            @include breakpoint (max-md_device){
                font-size: 80px;
            }
            @include breakpoint(max-xs_device){
                font-size: 70px;
            }
            @include breakpoint(max-xxs_device){
                font-size: 45px;
            }
        }
        .slide-btn{
            display: flex;
            justify-content: center;
        }
    }
}
/*--- Banner Style 14 Start here ---*/
.banner-14-area{
    &.background-3{
        background-image: url(../images/banner/10-1.jpg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        height: 750px;
        position: relative;
        @include breakpoint(max-xl_device){
            height: 750px;
        }
        @include breakpoint(max-lg_device){
            height: 700px;
        }
        @include breakpoint(max-md_device){
            height: 600px;
        }
        @include breakpoint(max-sm_device){
            height: 500px;
        }
        @include breakpoint(max-xs_device){
            height: 450px;
        }
    }
    .large-title-1{
        font-size: 42px;
        line-height: 42px;
        letter-spacing: normal;
        font-weight: 400;
        font-family: $general-font-family-3;
        padding-bottom: 45px;
        margin-bottom: 30px;
        position: relative;
        display: block;
        @include breakpoint(max-md_device){
            font-size: 40px;
        }
        @include breakpoint(max-md_device){
            font-size: 30px;
        }
        @include breakpoint(max-xs_device){
            font-size: 20px;
            margin-bottom: 10px;
            padding-bottom: 10px;
        }
        &:after{
            content: '';
            position: absolute;
            bottom: 0%;
            border-top: 1px solid $white-color;
            width: 100px;
            transition: inherit;
            left: 50%;
            transform: translateX(-50%);
        }
    }
    .desc-content{
        font-size: 24px;
        font-family: $general-font-family-name;
        line-height: 2em;
        margin-bottom: 35px;
        @include breakpoint(max-md_device){
            font-size: 22px;
            line-height: 1.5em;
        }
        @include breakpoint(max-xxs_device){
            font-size: 16px;
            margin-bottom: 20px;
        }
    }
    .popup-video{
        a{
            font-size: 30px;
            padding: 30px;
            background: transparent;
            border: 1px solid $border-color-3;
            color: $white-color;
            border-radius: 50%;
            @include breakpoint(max-md_device){
                font-size: 20px;
                padding: 30px;
                border-radius: 100%;
                line-height: 15px;
            }
            @include breakpoint(max-xxs_device){
                padding: 20px;
                line-height: 12px;
            }
            &:hover{
                color: $primary-color-4;
            }
        }
    }
    .banner-content-4{
        max-width: 800px;
        margin: 0 auto;
        @include breakpoint(max-md_device){
            max-width: 760px;
        }
        @include breakpoint(max-sm_device){
            max-width: 530px;
        }
        @include breakpoint(max-xs_device){
            max-width: 440px;
        }
        @include breakpoint(max-xxs_device){
            max-width: 280px;
        }
    }
}
/*--- Banner Style 15 Start here ---*/
.banner-15-area{
    .slick-list{
        margin: 0 -20px;
    }
    .single-banner{
        position: relative;
        margin: 0px 20px;
        .banner-content-2{
            position: absolute;
            left: 65%;
            top: 58%;
            transform: translate(-50%, -50%);
            .banner-title-3{
                font-size: 36px;
                font-family: $general-font-family-5;
                text-transform: uppercase;
                font-weight: 400;
                @include breakpoint(max-xl_device){
                    font-size: 26px;
                }
            }
            .banner-title-4{
                font-size: 30px;
                font-family: $general-font-family-5;
                text-transform: uppercase;
                font-weight: 400;
                @include breakpoint(max-xl_device){
                    font-size: 22px;
                }
            }
            .left-70{
                left: 70%;
            }
        }
    }
}
/*--- Banner Style 16 Start here ---*/
.banner-16-area{
    overflow: hidden;
    .single-banner{
        transition: .3s;
        &:hover{
            .banner-overlay{
                position: absolute;
                left: 0;
                top: 0;
                opacity: 0.1;
                content: "";
                background-color: #3e3e3e;
                width: 100%;
                height: 100%;
                transition: .3s;
            }
        }
        .banner-image{
            img{
                width: 100%;
            }
        }
    }
    .banner-content-3{
        position: absolute;
        top:0px;
        left: 0px;
        height: 100%;
        width: 100%;
        backface-visibility: hidden;
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        .inner-content{
            height: 72%;
            position: relative;
            margin-top: 4%;
            display: flex;
            flex-flow: column;
            width: 350px;
            @include breakpoint(max-xl_device){
                width: 300px;
            }
            @include breakpoint(max-lg_device){
                width: 250px;
            }
            @include breakpoint(max-md_device){
                width: 180px;
            }
            @include breakpoint(max-sm_device){
                width: 190px;
            }
            @include breakpoint(max-xs_device){
                width: 220px;
            }
            &:before{
                content: '';
                position: absolute;
                left: 0;
                top: 10px;
                right: 0;
                bottom: 100px;
                border-top: 3px solid #2b2b2c;
                z-index: 1;
                border-bottom: 3px solid #4b4a4f;
            }
            &:after{
                content: '';
                border: 3px solid #fff;
                opacity: .3;
                position: absolute;
                left: -30px;
                top: 10px;
                right: -30px;
                bottom: 100px;
                z-index: 0;
            }
        }
        .banner-title-1{
            font-size: 18px;
            font-family: $general-font-family-7;
            font-weight: 700;
            letter-spacing: 1px;
            color: $white-color;
            text-transform: initial;
            position: relative;
            z-index: 2;
            flex-grow: 1;
            @include breakpoint(max-md_device){
                font-size: 12px;
            }
        }
        .banner-title-2{
            font-size: 50px;
            font-weight: 700;
            letter-spacing: 5px;
            color: $primary-color-6;
            font-family: $general-font-family-7;
            line-height: 50px;
            @include breakpoint(max-lg_device){
                font-size: 46px;
            }
            @include breakpoint(max-md_device){
                font-size: 30px;
            }
        }
        .banner-btn{
            .mgana-btn.mgana-btn-2 {
                @include breakpoint(max-lg_device){
                    height: 35px;
                    line-height: 35px;
                    width: 100px;
                    font-size: 12px;
                }
            }
        }
    }
    .banner-content-4{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 5%;
        z-index: 2;
        text-align: center;
        .inner-content-4{
            display: flex;
            flex-flow: column;
            width: 300px;
            @include breakpoint(max-md_device){
                width: 186px;
            }
            .banner-title-1{
                font-size: 18px;
                line-height: 1.4em;
                font-family: $general-font-family-6;
                font-style: italic;
                color: rgba(62,62,62,1);
                padding: 0 30px 0 30px;
                @include breakpoint(max-md_device){
                    font-size: 16px;
                }
            }
            .banner-title-2{
                font-size: 60px;
                font-family: $general-font-family-7;
                font-weight: 900;
                line-height: 50px;
                color: rgba(62,62,62,1);
                letter-spacing: 3px;
                margin: 10px 0 30px 0;
                @include breakpoint(max-md_device){
                    font-size: 40px;
                    margin-bottom: 20px;
                }
            }
        }
    }
    .banner-content-5{
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        z-index: 2;
        text-align: center;
        margin: 0 auto;
        width: 100%;
        .inner-content-5{
            display: flex;
            flex-flow: column;
            width: 450px;
            @include breakpoint(max-md_device){
                width: 256px;
            }
            .banner-title-1{
                font-size: 18px;
                line-height: 1.4em;
                font-family: $general-font-family-6;
                font-style: italic;
                color: rgba(62,62,62,1);
                padding: 0 30px 0 30px;
                @include breakpoint(max-md_device){
                    font-size: 14px;
                    line-height: 14px;
                    padding-bottom: 15px;
                }
            }
            .banner-title-2{
                font-size: 32px;
                font-family: $general-font-family-6;
                font-weight: 700;
                font-style: italic;
                color: rgba(62,62,62,1);
                margin: 0 0 30px 0;
                line-height: 1.3em;
                @include breakpoint(max-md_device){
                    font-size: 18px;
                    margin-bottom: 10px;
                }
            }
        }
    }
    .banner-content-6{
        @include  absCenter;
        background-color: #fff;
        padding: 40px 40px 0 40px;
        box-shadow: 0 0 30px 0 rgba(0,0,0,.3);
        max-width: 670px;
        width: 670px;
        @include breakpoint(max-lg_device){
            max-width: 580px;
            width: 580px;
        }
        @include breakpoint(max-md_device){
            max-width: 430px;
            width: 430px;
        }
        @include breakpoint(max-xxs_device){
            max-width: 260px;
            width: 260px;
            padding: 20px 15px 0 15px;
        }
        .inner-content-6{
            .banner-title-1{
                font-size: 24px;
                font-weight: 400;
                font-family: $general-font-family-6;
                font-style: italic;
                @include breakpoint(max-md_device){
                    font-size: 18px;
                }
                @include breakpoint(max-xxs_device){
                    font-size: 12px;
                }
            }
            .banner-title-2{
                color: #ff7a4b;
                font-family: $general-font-family-6;
                font-size: 118px;
                font-weight: 400;
                line-height: 1em;
                margin: 5px 0 20px 0;
                @include breakpoint(max-md_device){
                    font-size: 88px;
                }
                @include breakpoint(max-xxs_device){
                    font-size: 32px;
                    margin: 5px 0 10px 0;
                }
            }
            .banner-btn{
                margin: 30px 0 -22px 0;
                @include breakpoint(max-xxs_device){
                   margin: 0px 0 -18px 0;
                }
            }
        }
    }
    .banner-content-7{
        @include  absCenter;
        .inner-content-7{
            width: 350px;
            @include breakpoint(max-md_device){
                width: 220px;
            }
            .banner-title-1{
                font-size: 32px;
                font-weight: 700;
                font-family: $general-font-family-6;
                font-style: italic;
                line-height: 1.3;
                color: $white-color;
                @include breakpoint(max-md_device){
                    font-size: 22px;
                }
            }
        }
        .popup-video{
            margin-top: 20px;
            a{
                font-size: 24px;
                padding: 35px;
                color: $white-color;
                border: 3px solid $white-color;
                border-radius: 100%;
                &:hover{
                    border-color: #818a91;
                }
            }
        }
    }
}
/*--- Banner Style 17 Start here ---*/
.banner-17-area{
    .custom-margin{
        margin-bottom: 40px;
    }
    .custom-margin-2{
        @include breakpoint(max-xs_device){
            margin-bottom: 40px;
        }
    }
    .banner-17-row{
        display: flex;
        flex-wrap: wrap;
        margin-right: -20px;
        margin-left: -20px;
        .custom-col-1{
            flex: 0 0 33.333333%;
            max-width: 33.333333%;
            position: relative;
            width: 100%;
            padding-right: 20px;
            padding-left: 20px;
            @include breakpoint(max-xs_device){
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
        .custom-col-2{
            flex: 0 0 66.666667%;
            max-width: 66.666667%;
            position: relative;
            width: 100%;
            padding-right: 20px;
            padding-left: 20px;
            @include breakpoint(max-xs_device){
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
    }
    .banner-item{
        position: relative;
        display: block;
        overflow: hidden;
        &:hover{
            .banner-content{
                top: 50%;
                opacity: 1;
                transition: .5s;
            }
            .banner-overlay{
                opacity: .5;
                transition: .5s;
            }
        }
        img{
            width: 100%;
            height: 460px;
            object-fit: cover;
        }
        .banner-overlay{
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
            content: "";
            background-color: $text-color;
            width: 100%;
            height: 100%;
            transition: .5s;
        }
        .banner-content{
            display: block;
            overflow: hidden;
            position: absolute;
            top: 60%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: .5s;
            opacity: 0;
            width: 100%;
            padding: 0 10px;
            .content-1{
                color: #ffffff;
                font-family: $general-font-family-3;
                font-size: 18px;
                font-weight: normal;
                text-transform: uppercase;
                letter-spacing: 3px;
                text-align: center;
            }
        }
    }
}
/*--- Banner Style 18 Start here ---*/
.banner-18-area{
    padding-top: 80px;
    .banner-img{
        width: 100%;
        position: relative;
        display: block;
        overflow: hidden;
        &.background-3{
            background-image: url(../images/banner/13-5.jpg);
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            height: 685px;
            position: relative;
            @include breakpoint(max-lg_device){
                height: 510px;
            }
            @include breakpoint(max-md_device){
            }
            @include breakpoint(max-sm_device){
            }
            @include breakpoint(max-xs_device){
            }
        }
    }
    .banner-inner-content-4 {
        position: absolute;
        top: 50%;
        left: 10%;
        transform: translateY(-50%);
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 460px;
        @include breakpoint(max-sm_device){
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        @include breakpoint(max-xxs_device){
            width: 300px;
        }
        .content-wrapper{
            align-items: center;
            .large-title {
                font-family: $general-font-family-8;
                font-size: 150px;
                font-weight: 600;
                color: #041632;
                line-height: 0.9em;
                @include breakpoint(max-lg_device){
                    font-size: 100px;
                }
            }
            .small-title-3 {
                font-family: $general-font-family-9;
                font-size: 26px;
                font-weight: 600;
                letter-spacing: 5px;
                color: #dda973;
                line-height: 1;
                margin-top: 25px;
                @include breakpoint(max-lg_device){
                    font-size: 18px;
                }
            }
            .small-title-2 {
                font-family: $general-font-family-9;
                font-size: 46px;
                font-weight: 900;
                letter-spacing: 9px;
                margin: 8px 0px 35px 0px;
                color: #3e3e3e;
                @include breakpoint (max-lg_device){
                    font-size: 30px;
                    letter-spacing: 5px;
                }
                @include breakpoint(max-xs_device){
                }
                @include breakpoint(max-xxs_device){
                }
            }
            .slide-btn{
                display: flex;
                justify-content: center;
                .border-button-3{
                    font-family: "Lato", Sans-serif;
                    font-size: 14px;
                    font-weight: bold;
                    text-transform: uppercase;
                    line-height: 20px;
                    letter-spacing: 2px;
                    fill: #3e3e3e;
                    color: #3e3e3e;
                    background-color: rgba(255,255,255,0);
                    padding: 5px 0px 5px 0px;
                    border-style: solid;
                    border-width: 2px 0px 2px 0px;
                    border-color: #979797;
                    &:hover{
                        color: #dda973;
                    }
                }
            }
        }
    }
}
/*--- Banner Style 19 Start here ---*/
.banner-19-area{
    padding-top: 80px;
    .banner-img{
        width: 100%;
        position: relative;
        display: block;
        overflow: hidden;
        &.background-3{
            background-image: url(../images/banner/14-8.jpg);
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            height: 600px;
            position: relative;
            @include breakpoint(max-lg_device){
                height: 510px;
            }
            @include breakpoint(max-md_device){
            }
            @include breakpoint(max-sm_device){
            }
            @include breakpoint(max-xs_device){
            }
        }
    }
    .banner-inner-content-4 {
        position: absolute;
        top: 50%;
        right: 5%;
        transform: translateY(-50%);
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 460px;
        @include breakpoint(max-sm_device){
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        @include breakpoint(max-xxs_device){
            width: 300px;
        }
        .content-wrapper{
            align-items: center;
            .large-title {
                color: $white-color;
                font-family: $general-font-family-9;
                font-size: 140px;
                font-weight: 900;
                line-height: .8em;
                text-align: center;
                @include breakpoint(max-xxs_device){
                    font-size: 100px;
                }
            }
            .small-title-3 {
                color: $white-color;
                font-family: $general-font-family-9;
                font-size: 170px;
                font-weight: 300;
                line-height: .9em;
                text-align: center;
                @include breakpoint(max-xxs_device){
                    font-size: 130px;
                }
            }
            .small-title-2 {
                font-family: $general-font-family-3;
                font-size: 36px;
                letter-spacing: 2px;
                color: $white-color;
                @include breakpoint(max-xxs_device){
                    font-size: 24px;
                }
            }
            .slide-btn{
                display: flex;
                justify-content: center;
                .border-button-3{
                    font-family: "Lato", Sans-serif;
                    font-size: 14px;
                    font-weight: bold;
                    text-transform: uppercase;
                    line-height: 20px;
                    letter-spacing: 2px;
                    color:$white-color;
                    background-color: #3e3e3e;
                    padding: 15px 45px 15px 45px;
                    &:hover{
                        color: $white-color;
                        background-color: #dda973;
                    }
                }
            }
        }
    }
}
/*--- Banner Style 20 Start here ---*/
.banner-20-area{
    .banner-img{
        width: 100%;
        position: relative;
        display: block;
        overflow: hidden;
        &.collection-bg-1{
            background-image: url(../images/shop/collection-2/bg-1.jpg);
        }
        &.collection-bg-2{
            background-image: url(../images/shop/collection-2/bg-2.jpg);
        }
        &.collection-bg-3{
            background-image: url(../images/shop/collection-2/bg-3.jpg);
        }
        &.background-3{
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            height: 470px;
            position: relative;
            @include breakpoint(max-lg_device){
                height: 470px;
            }
            @include breakpoint(max-md_device){
                height: 400px;
            }
            @include breakpoint(max-sm_device){
                height: 350px;
            }
            @include breakpoint(max-xs_device){
                height: 350px;
            }
        }

    }
    .banner-content-5{
        position: relative;
        .banner-inner-content{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            text-align: center;
            .banner-title{
                font-size: 60px;
                font-weight: 400;
                text-transform: uppercase;
                letter-spacing: 6px;
                @include breakpoint(max-md_device){
                    font-size: 40px;
                    letter-spacing: 3px;
                }
            }
        }
    }
}
/*--- Banner Style 21 Start here ---*/
.banner-21-area{
    .banner-item{
        position: relative;
        display: block;
        overflow: hidden;
        &:hover{
            .view-more-btn{
                top: 50%;
                opacity: 1;
                transition: .5s;
            }
            .banner-overlay{
                opacity: .9;
                transition: .5s;
            }
        }
        .banner-overlay{
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
            content: "";
            background-color: $primary-color;
            width: 100%;
            height: 100%;
            transition: .5s;
        }
        .view-more-btn{
            display: block;
            overflow: hidden;
            position: absolute;
            top: 60%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: .5s;
            opacity: 0;
            .view-more-3{
                font-family: $heading-font-family-name;
                font-size: 16px;
                font-weight: 400;
                @include breakpoint(max-md_device){
                    font-size: 14px;
                }
            }
        }
    }
	.banner-text{
		position: absolute;
		top: 64%;
		right: 0;
		transform: translateY(-64%);
		text-align: center;
		&.banner-text_left {
			right: auto;
			left: 3%;
			@include breakpoint(max-xxs_device){
				left: 50%;
				top: 64%;
				transform: translate(-50%, -64%);
				width: 100%;
			}
		}
		&.banner-text_left-0 {
			right: auto;
			left: 0;
			@include breakpoint(max-xxs_device){
				left: 50%;
				top: 64%;
				transform: translate(-50%, -64%);
				width: 100%;
				text-align: center !important;
			}
		}
		&.banner-text_center {
			left: 50%;
			top: 64%;
			transform: translate(-50%, -64%);
			width: 100%;
		}
		&.banner-text_right {
			left: auto;
			right: 3%;
			@include breakpoint(max-xxs_device){
				left: 50%;
				top: 64%;
				transform: translate(-50%, -64%);
				width: 100%;
			}
		}
		.title-1{
			color: $white-color;
			text-transform: uppercase;
			&:hover{
				color: #ff7960;
			}
		}
		.heading-1{
			font-size: 80px;
			font-weight: 400;
			text-transform: uppercase;
			letter-spacing: 8px;
			color: #ff7960;
			margin-bottom: 30px;
			@include breakpoint(max-md_device){
				font-size: 70px;
				letter-spacing: 6px;
			}
			@include breakpoint(max-sm_device){
				font-size: 50px;
				letter-spacing: 5px;
			}
			@include breakpoint(max-xs_device){
				font-size: 40px;
				letter-spacing: 3px;
			}
			@include breakpoint(max-xxs_device){
				font-size: 30px;
				letter-spacing: 3px;
			}
		}
	}
	.banner-text-2{
		position: relative;
		text-align: center;
		&.banner-text_left-2 {
			right: auto;
			left: 5%;
			@include breakpoint(max-xxs_device){
				left: 50%;
				transform: translateX(-50%);
			}
		}
		&.banner-text_right-2 {
			left: auto;
			right: 5%;
			@include breakpoint(max-xxs_device){
				left: 50%;
				transform: translateX(-50%);
			}
		}
		.banner-button{
			font-family: $general-font-family-3;
			font-size: 26px;
			text-transform: uppercase;
			line-height: 50px;
			fill: #3e3e3e;
			color: #3e3e3e;
			background-color: #ffffff;
			padding: 30px 60px 30px 60px;
			text-transform: uppercase;
			&:hover{
				background-color: $primary-color-4;
				color: $white-color;
			}
			@include breakpoint(max-xxs_device){
				font-size: 20px;
				padding: 20px 40px;
			}
		}
	}
}