/*----------------------------------------*/
/*    38 - Components - Testimonial
/*----------------------------------------*/
.testimonial-area{
    padding-bottom: 130px;
    @include breakpoint(max-sm_device){
        padding-bottom: 125px;
    }
    .testimonial-icon-img{
        width: 230px;
        margin: 0 auto -25px auto; 
        background-color: $white-color;
        text-align: center;
        position: relative;
        z-index: 1;
        .mgana-svg{
            height: 45px;
            width: 45px;
        }
    }
    .client-details{
        padding: 70px 100px 0px 100px;
        border-style: solid;
        border-width: 1px 1px 1px 1px;
        border-color: #979797;
        @include breakpoint(max-sm_device){
            padding: 50px 70px 0 70px;
        }
        @include breakpoint(max-xs_device){
            padding: 50px 50px 0 50px;
        }
        @include breakpoint(max-xxs_device){
            padding: 50px 20px 0 20px;
        }
    }
    .client-info{
        .feedback{
            font-family: $general-font-family-name;
            font-size: 18px;
            line-height: 1.8em;
        }
        .client-name{
            font-family: $general-font-family-name;
            font-size: 16px;
            padding: 0 0 60px 0;
        }
        .img-area{
            padding: 20px 0px;
            display: flex;
            justify-content: center;
        }
    }
}
.testimonial-area-2{
    .testimonial-icon-img{
        i{
            font-size: 40px;
        }
    }
    .client-info {
        .feedback{
            font-family: $body-font-family-name;
        }
        .client-name{
            font-family: $general-font-family-2;
            font-weight: 400;
        }
    }
    .testimonial-wrapper-2{
        background: #e2f1f8;
        .testimonial-icon-img{
            background-color: transparent;
            padding-top: 55px;
        }
        .client-details{
            border: none;
            padding: 50px 0px 0px 0px;
            width: 970px;
            margin: 0 auto;
            .client-name{
                margin-top: 28px;
            }
            @include breakpoint(max-lg_device){
                width: 850px;
            }
            @include breakpoint(max-md_device){
                width: 700px;
            }
            @include breakpoint (max-sm_device){
                width: 510px;
            }
            @include breakpoint (max-xs_device){
                width: 400px;
            }
            @include breakpoint (max-xxs_device){
                width: 260px;
            }
        }
    }
}
.testimonial-area-3{
    &.background{
        background-image: url(../images/banner/10-2.jpg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        height: 635px;
        position: relative;
        @include breakpoint(max-xl_device){
            height: 600px;
        }
        @include breakpoint(max-lg_device){
            height: 600px;
        }
        @include breakpoint(max-md_device){
            height: 600px;
        }
        @include breakpoint(max-sm_device){
            height: 500px;
        }
        @include breakpoint(max-xs_device){
            height: 500px;
        }
    }
    .img-area {
        padding: 20px 0px;
        display: flex;
        justify-content: center;
    }
    .feedback{
        font-size: 22px;
        font-family: $general-font-family-3;
        line-height: 1.7em;
        @include breakpoint(max-md_device){
            font-size: 20px;
            line-height: 1.5em;
        }
        @include breakpoint(max-sm_device){
            font-size: 18px;
        }
        @include breakpoint(max-xxs_device){
            font-size: 16px;
        }
    }
    .client-info{
        width: 940px;
        margin: 0 auto;
        @include breakpoint(max-lg_device){
            width: 700px;
        }
        @include breakpoint(max-md_device){
            width: 600px;
        }
        @include breakpoint(max-sm_device){
            width: 500px;
        }
        @include breakpoint(max-xs_device){
            width: 420px;
        }
        @include breakpoint(max-xxs_device){
            width: 280px;
        }
    }
    .section-title-3{
        .heading-5{
            @include breakpoint(max-sm_device){
                margin-bottom: 20px;
                font-size: 40px;
                line-height: 40px;
            }
            @include breakpoint(max-xs_device){
                font-size: 30px;
                line-height: 30px;
            }
            @include breakpoint(max-xxs_device){
                font-size: 20px;
                line-height: 20px;
                padding-bottom: 20px;
            }
        }
    }
}
.testimonial-area-4{
    &.background{
        background-image: url(../images/bg/home/14-7.jpg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        height: 592px;
        position: relative;
        @include breakpoint(max-xl_device){
            height: 600px;
        }
        @include breakpoint(max-lg_device){
            height: 600px;
        }
        @include breakpoint(max-md_device){
            height: 600px;
        }
        @include breakpoint(max-sm_device){
            height: 500px;
        }
        @include breakpoint(max-xs_device){
            height: 500px;
        }
    }
    .title-icon{
        .icon-border{
            color: $white-color;
            &:before{
                border-color: $white-color;
            }
            &:after{
                border-color: $white-color;
            }
        }
    }
    .img-area {
        padding: 20px 0px;
        display: flex;
        justify-content: center;
    }
    .feedback{
        width: 880px;
        margin: 0 auto;
        color: $border-color-3;
        font-family: $general-font-family-3;
        font-size: 22px;
        font-style: italic;
        line-height: 1.7em;
        @include breakpoint (max-lg_device){
            width: 700px;
        }
        @include breakpoint(max-md_device){
            font-size: 20px;
            line-height: 1.5em;
            width: 600px;
        }
        @include breakpoint(max-sm_device){
            font-size: 18px;
            width: 500px;
        }
        @include breakpoint(max-xs_device){
            width: 400px;
        }
        @include breakpoint(max-xxs_device){
            font-size: 16px;
            width: 280px;
        }
    }
    .client-info{
        width: 940px;
        margin: 0 auto;
        @include breakpoint(max-lg_device){
            width: 700px;
        }
        @include breakpoint(max-md_device){
            width: 600px;
        }
        @include breakpoint(max-sm_device){
            width: 500px;
        }
        @include breakpoint(max-xs_device){
            width: 420px;
        }
        @include breakpoint(max-xxs_device){
            width: 280px;
        }
    }
    .section-title-3{
        .heading-5{
            @include breakpoint(max-sm_device){
                margin-bottom: 20px;
                font-size: 40px;
                line-height: 40px;
            }
            @include breakpoint(max-xs_device){
                font-size: 30px;
                line-height: 30px;
            }
            @include breakpoint(max-xxs_device){
                font-size: 20px;
                line-height: 20px;
                padding-bottom: 20px;
            }
        }
    }
}