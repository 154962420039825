/*----------------------------------------*/
/*    29 - Components - Modal
/*----------------------------------------*/
.modal-wrapper {
	display: block;
	&.modal {
		visibility: hidden;
		opacity: 0;
		@extend %basetransition;
		z-index: -9;
	}
	&.show {
		background-color: rgba(0, 0, 0, .9);
		visibility: visible;
		opacity: 1;
		z-index: 1050;
	}
	.modal-dialog {
		max-width: 62%;
		@include breakpoint (max-xl_device){
			max-width: 95%;
		}
		@include breakpoint (max-xs_device) {
			max-width: 100%;
			left: 0;
		}
		.modal-content {
			padding: 30px 30px 25px;
			overflow-y: auto;
			@include breakpoint (max-large) {
				height: 505px;
				overflow-y: auto;
				border-radius: 0;
			}
			.modal-body {
				padding: 0;
				.close {
					font-size: 30px;
					font-weight: 400;
					opacity: 1;
					position: absolute;
					right: 15px;
					top: 0;
					z-index: 99;
					&:hover{
						color: $primary-color;
					}
					@extend %basetransition;
					@include breakpoint (max-normal) {
						font-size: 25px;
					}
				}
			}
		}
	}
	.modal-inner-area{
		@include breakpoint(max-md_device){
			height: 500px;
		}
		.product-summery{
			padding-bottom: 20px;
			@include breakpoint(max-sm_device){
				padding: 20px 0;
			}
			.quantity-with_btn{
				.add-to_cart{
					.border-button{
						@include breakpoint(max-xxs_device){
							width: 150px;
						}
					}
				} 
			}
		}
	}
	.modal-content{
		border-radius: 0%;
		@include breakpoint(max-xxs_device){
			padding: 20px !important;
		}
	}
}
.modal-open{
	overflow: hidden !important;
}
