/*----------------------------------------*/
/*    30 - Components - New Arrival
/*----------------------------------------*/
.new-arrival-area{
    &.background-1{
        background-image: url(../images/bg/home/1.jpg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        height: 515px;
        position: relative;
    }
    .small-title-1{
        margin-bottom: 10px;
    }
    .large-title-1{
        margin-bottom: 18px;
    }
    .arrival-button{
        display: flex;
        justify-content: center;
    }
}
.new-arrival-2{
    &.background-2{
        background-image: url(../images/bg/home/2-1.jpg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        height: 738px;
        position: relative;
        @include breakpoint(max-lg_device){
            height: 600px;
        }
        @include breakpoint(max-md_device){
            height: 500px;
        }
        @include breakpoint(max-xs_device){
            height: 400px;
        }
    }
    .new-arrival-content{
        display: block;
        width: 400px;
        left: 15%;
        position: relative;
        @include breakpoint(max-xs_device){
            left: 0%;
        }
        @include breakpoint(max-xxs_device){
            width: 300px
        }
        .small-title-1{
            color: $banner-color-1;
            font-family: $body-font-family-name;
            font-size: 18px;
            font-weight: 400;
            letter-spacing: normal;
        }
        .large-title-1{
            color: $banner-color-1;
            font-family: $heading-font-family-name;
            font-size: 64px;
            font-weight: 600;
            line-height: 1em;
            letter-spacing: normal;
            @include breakpoint(max-xxs_device){
                font-size: 40px;
            }
        }
    }
}
.new-arrival-3{
    @include breakpoint(max-xs_device){
        display: block !important;
    }
    .banner-thumb-area{
        position: relative;
        width: 29%;
        @media #{$desktop-device-2}{
            width: 33%;
        }
        @include breakpoint(max-lg_device){
            width: 33%;
        }
        @include breakpoint(max-md_device){
            width: 35%;
        }
        @include breakpoint(max-sm_device){
            width: 50%;
        }
        @include breakpoint(max-xs_device){
            width: 100%;
        }
        .demo-image{
            display: block;
            overflow: hidden;
            position: relative;
            a{
                .banner-overlay{
                    position: absolute;
                    left: 0;
                    top: 0;
                    opacity: 0;
                    content: "";
                    background-color: #3e3e3e;
                    width: 100%;
                    height: 100%;
                    @extend %basetransition;
                }
            }
        }
        &:hover{
            .demo-image{
                a{
                    .banner-overlay{
                        opacity: .2;
                        @extend %basetransition;
                    }
                }
            }
        }
        .demo-content{
            position: absolute;
            top: 0;
            @include absCenter;
            width: 270px;
            @include breakpoint(max-lg_device){
                width: 250px;
            }
            .small-title-2{
                @include breakpoint(max-lg_device){
                    font-size: 20px;
                }
            }
            .large-title-2{
                padding: 20px 0 40px 0;
                @include breakpoint(max-xxl_device){
                    font-size: 80px;
                }
                @include breakpoint(max-lg_device){
                    font-size: 70px;
                    padding: 10px 0px 30px 0px;
                }
            }
            .border-button{
                width: 150px;
                height: 46px;
                line-height: 42px;
            }
        }
    }
    .product-slider{
        width: 71%;
        position: relative;
        display: block;
        overflow: hidden;
        @media #{$desktop-device-2}{
            width: 67%;
        }
        @include breakpoint(max-lg_device){
            width: 67%;
        }
        @include breakpoint(max-md_device){
            width: 65%;
        }
        @include breakpoint(max-sm_device){
            width: 50%;
        }
        @include breakpoint(max-xs_device){
            width: 100%;
        }
        .product-section{
            @include absCenter;
            width: 100%;
            padding: 0px 140px 70px 140px;
            @include breakpoint(max-xxl_device){
                padding: 0px 80px 60px 80px;
            }
            @include breakpoint(max-lg_device){
                padding: 0px 40px 30px 40px;
            }
            @include breakpoint(max-md_device){
                padding: 0px 20px 30px 20px;
            }
            @include breakpoint(max-xs_device){
                position: relative;
                top: 0;
                left: 0;
                transform: translate(0, 0);
                padding: 80px 20px 118px 20px;
            }
            .section-title{
                padding-bottom: 30px;
                @include breakpoint(max-lg_device){
                    padding-bottom: 10px;
                }
                @include breakpoint(max-xs_device){
                    padding-bottom: 30px;
                }
                .heading-4{
                    @include breakpoint(max-lg_device){
                        font-size: 30px;
                    }
                    @include breakpoint(max-md_device){
                        font-size: 24px;
                    }
                    @include breakpoint(max-xs_device){
                        font-size: 30px;
                    }
                }
            }
        }
    }
    .mgana-product-tab{
        .custom-dots{
            .slick-dots{
                bottom: -60px;
                @include breakpoint(max-lg_device){
                    bottom: -35px;
                }
                li{
                    @include breakpoint(max-lg_device){
                        width: 12px;
                        height: 12px;
                    }
                }
            }
        }
    }
}
.new-arrival-4{
    width: 100%;
    .title-icon{
        text-align: center;
        display: flex;
        justify-content: center;
        .icon-border{
            position: relative;
            display: flex;
            justify-content: center;
            padding: 0px 15px;
            &:before {
                content: '';
                position: absolute;
                top: 50%;
                border-top: 2px solid #3e3e3e;
                width: 30px;
                transition: inherit;
                left: 100%;
            }
            &:after {
                content: '';
                position: absolute;
                top: 50%;
                border-top: 2px solid #3e3e3e;
                width: 30px;
                transition: inherit;
                right: 100%;
            }
            i{
                font-size: 28px;
                position: relative;
            }
        }
    }
    .arrival-4-content{
        width: 770px;
        margin: 0 auto;
        text-align: center;
        .desc-content{
            font-family: $general-font-family-3;
            font-size: 20px;
            line-height: 1.6em;
            color: #3e3e3e;
            margin-top: 30px;
            @include breakpoint(max-xs_device){
                font-size: 18px;
            }
            @include breakpoint(max-xxs_device){
                font-size: 16px;
            }
        }
        @include breakpoint(max-md_device){
            width: 750px;
        }
        @include breakpoint(max-sm_device){
            width: 550px;
        }
        @include breakpoint(max-xs_device){
            width: 450px;
        }
        @include breakpoint(max-xxs_device){
            width: 300px;
        }
    }
    .border-btn{
        display: flex;
        justify-content: center;
        margin-top: 40px;
    }
}