/*----------------------------------------*/
/*    42 - Components - Breadcrumb
/*----------------------------------------*/
.breadcrumb-area {
    background-color: #2c2c2c;
    padding: 50px 0px;
	.breadcrumb-content {
		display: flex;
        justify-content: left;
        flex-direction: column;
		> .breadcrumb-title {
            font-size: 28px;
            color: $white-color;
            font-family: $heading-font-family-name;
            text-transform: uppercase;
            font-weight: 400;
			@include breakpoint (max-lg_device) {
				font-size: 20px;
			}
			@include breakpoint(max-xxs_device){
				padding-bottom: 20px;
			}
		}
		ul {
			> li {
				font-family: $body-font-family-name;
				display: inline-block;
				padding-left: 20px;
                position: relative;
                text-transform: uppercase;
                font-size: 12px;
                color: $white-color;
                > a{
                    color: $snow-color;
                    font-family: $body-font-family-name;
                    text-transform: uppercase;
                    font-size: 12px;
                    &:hover{
                        color: $white-color;
                    }
                }
				&:first-child {
                    padding-left: 0;
				}
				&:before {
					content: "/";
					position: absolute;
					font-size: 10px;
					font-weight: 600;
					right: -15px;
					top: 50%;
                    transform: translateY(-50%);
                    color: $white-color;
				}
				&:last-child {
					&:before {
						display: none;
					}
				}
			}
		}
	}
}
.breadcrumb-area-2{
	padding: 60px 0;
	border-top: 1px solid #e5e5e5;
	 .breadcrumb_content{
		 & ul{
			 & li{
				 display: inline-block;
				 text-transform: capitalize;
				 font-size: 14px;
				 margin-right: 10px;
				 padding-right: 15px;
				 position: relative;
				 color: #1a1a1a;
				 &::before{
					 position: absolute;
					 content: ">";
					 font-size: 18px;
					 right: -4px;
					 top: 50%;
					 transform: translateY(-50%);
				 }
				 &:last-child{
					 margin-right: 0;
					 &::before{
						 display: none;
					 }
				 }
				 & a{
					 color: $snow-color;
					 &:hover{
						 color:  #1a1a1a;
					 }
				 }
				 h1{
					display: inline-block;
					text-transform: capitalize;
					font-size: 14px;
					position: relative;
					color:  #1a1a1a;
					font-family: $body-font-family-name;
					font-weight: 400;
				 }
			 }
		 }
	 }
}