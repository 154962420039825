/*----------------------------------------*/
/*    31 - Components - Newsletter
/*----------------------------------------*/
.newsletter-group{
    background-color: $bg-color-1;
    height: 245px;
    @include breakpoint(max-sm_device){
        margin-top: 85px;
    }
    &.newslatter-2{
        @include breakpoint(max-sm_device){
            margin-top: 0px;
        }
    }
    &.newsletter-3{
        margin-top: 80px !important;
    }
    .newsletter-title{
        padding-bottom: 60px;
        .heading-two{
            @include breakpoint(max-xxs_device){
                font-size: 24px;
            }
        }
    }
    .mc-form{
        position: relative;
        width: 60%;
        @include breakpoint(max-sm_device){
            width: 80%;
        }
        @include breakpoint(max-xs_device){
            width: 100%;
        }
        .email-box{
            border: none;
            background-color: transparent;
            border-bottom: 1px solid $border-color-2;
            color: $white-color;
            height: 50px;
            width: 100%;
            padding-right: 100px;
            @include breakpoint(max-xxs_device){
                font-size: 12px;
                padding-right: 80px;
            }
        }
        .newsletter-btn{
            font-size: 18px;
            font-weight: 600;
            line-height: 30px;
            color: $white-color;
            border: none;
            position: absolute;
            padding: 10px 0px 10px 0px;
            right: 0;
            @include breakpoint(max-xxs_device){
                font-size: 14px;
            }
            &:hover{
                color: $primary-color-2;
            }
        }
        input[type="email"].email-box::-webkit-input-placeholder {
            color: $white-color;
            background-color: transparent;
        }
    }
}
.newsletter-group-2{
    background-image: url(../images/bg/home/4-1.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    height: 400px;
    position: relative;
    margin-top: 82px;
    @include breakpoint(max-sm_device){
        height: 300px;
    }
    .newsletter-title{
        padding-bottom: 35px;
    }
    .mc-form {
        .email-box{
            background-color: transparent;
            border: 1px solid #ebebeb;
            color: #ffffff;
            height: 90px;
            width: 100%;
            padding: 0 120px 0 20px;
            @include breakpoint(max-xxs_device){
                height: 70px;
                padding: 0 100px 0 10px;
            }
        }
        .newsletter-btn{
            height: 100%;
            padding-right: 20px;
        }
    }
}
.newsletter-group-3{
    background-image: url(../images/bg/home/13-1.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    height: 400px;
    position: relative;
    margin-top: 48px;
    @include breakpoint(max-sm_device){
        height: 300px;
    }
    @include breakpoint(max-xs_device){
        margin-top: 36px;
    }
    .newsletter-title{
        padding-bottom: 35px;
        .heading-two{
            font-family: $general-font-family-6;
            font-size: 36px;
            font-weight: normal;
            text-transform: uppercase;
            line-height: 1.2em;
            letter-spacing: 2px;
            @include breakpoint(max-xxs_device){
                font-size: 24px;
                letter-spacing: 1px;
            }
        }
    }
    .mc-form {
        width: 80%;
        .email-box{
            background-color: transparent;
            border: 1px solid #ebebeb;
            color: #ffffff;
            height: 90px;
            width: 100%;
            padding: 0 120px 0 20px;
            @include breakpoint(max-xxs_device){
                height: 70px;
                padding: 0 90px 0 10px;
            }
        }
        .newsletter-btn{
            height: 100%;
            padding-right: 20px;
        }
    }
}
.newslatter-4{
    .newsletter-group{
        background-color: #041632;
        padding: 77px 0px;
        height: inherit;
        @include breakpoint(max-sm_device){
            margin-top: 0px;
        }
        .mc-form{
            padding-left: 115px;
            width: 75%;
            @include breakpoint(max-md_device){
                padding-left: 60px;
                width: 95%;
            }
            @include breakpoint(max-sm_device){
                padding-left: 30px;
            }
            @include breakpoint(max-xs_device){
                padding-left: 20px;
            }
            @include breakpoint(max-xxs_device){
                padding-left: 15px;
            }
            .newsletter-btn{
                &:hover{
                    color: $primary-color-4;
                }
            }
        }
        .newsletter-box{
            @include breakpoint(max-sm_device){
                text-align: center !important;
                padding-bottom: 20px;
            }
        }
    }
    .newsletter-title{
        padding: 0px 20px 0px 0px;
        @include breakpoint(max-md_device){
            padding: 0px 0px 0px 20px;
        }
        @include breakpoint(max-sm_device){
            padding: 0px;
        }
        @include breakpoint(max-xs_device){
            padding: 0px;
        }
        @include breakpoint(max-xxs_device){
            padding: 0px;
        }
        .heading-two{
            font-family: $general-font-family-2;
            font-size: 32px;
            font-weight: normal;
            text-transform: uppercase;
            line-height: 1.2em;
            letter-spacing: 3px;
            @include breakpoint(max-md_device){
                font-size: 26px;
            }
        }
    }
}
.newsletter-group-5{
    .mc-form {
        width: 770px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
        @include breakpoint(max-md_device){
            width: 700px;
        }
        @include breakpoint(max-sm_device){
            width: 500px;
        }
        @include breakpoint(max-xs_device){
            width: 400px;
        }
        @include breakpoint(max-xxs_device){
            width: 280px;
            margin-top: 30px; 
        }
        .email-box{
            background-color: transparent;
            border: 1px solid $border-color-2;
            color: $heading-color-2;
            height: 80px;
            width: 100%;
            padding: 0 120px 0 20px;
            @include breakpoint(max-xxs_device){
                height: 70px;
                padding: 0 90px 0 10px;
            }
        }
        .newsletter-btn {
            font-size: 18px;
            font-weight: 600;
            line-height: 30px;
            color: $heading-color-2;
            border: none;
            position: absolute;
            padding: 10px 15px 10px 0px;
            right: 0;
            &:hover{
                color: $primary-color-5;
            }
            @include breakpoint(max-xxs_device){
                font-size: 16px;
                padding: 10px 10px 10px 0px;
            }
        }
    }
}
.newsletter-group-6{
    background-image: url(../images/bg/home/11-5.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    height: 560px; 
    position: relative;
    align-items: center;
    @include breakpoint(max-md_device){
        height: 500px;
    }
    @include breakpoint(max-md_device){
        height: 400px;
    }
    @include breakpoint(max-xxs_device){
        height: 350px;
    }
    .newsletter-box{ 
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .mc-form {
        width: 770px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
        @include breakpoint(max-md_device){
            width: 700px;
        }
        @include breakpoint(max-sm_device){
            width: 500px;
        }
        @include breakpoint(max-xs_device){
            width: 380px;
        }
        @include breakpoint(max-xxs_device){
            width: 280px;
            margin-top: 30px; 
        }
        .email-box{
            background-color: $white-color;
            border-radius: 50px 50px 50px 50px;
            border: 1px solid $border-color-2;
            color: $heading-color-2;
            height: 80px;
            width: 100%;
            padding: 0 120px 0 20px;
            @include breakpoint(max-xxs_device){
                height: 60px;
                padding: 0 100px 0 10px;
            }
            @include breakpoint(max-xs_device){
                height: 60px;
                padding: 0px 140px 0px 10px;
            }
        }
        .newsletter-btn {
            font-size: 18px;
            font-weight: 600;
            border-radius: 50px 50px 50px 50px;
            line-height: 30px;
            background-color: $primary-color-6;
            color: $white-color;
            border: none;
            position: absolute;
            padding: 10px 20px 10px 20px;
            right: 20px;
            &:hover{
                background-color: #ff581c;
            }
            @include breakpoint(max-xxs_device){
                font-size: 16px;
                padding: 10px 10px 10px 10px;
                right: 6px;
            }
            @include breakpoint(max-xs_device){
                right: 6px;
            }
        }
    }
}
.newsletter-group-7{
    background-image: url(../images/bg/home/12-2.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    height: 406px; 
    position: relative;
    align-items: center;
    @include breakpoint(max-md_device){
        height: 406px;
    }
    @include breakpoint(max-sm_device){
        margin-top: 0px;
    }
    @include breakpoint(max-xxs_device){
        height: 350px;
    }
    .newsletter-title{
        .newsletter-heading{
            font-family: $general-font-family-6;
            font-size: 46px;
            line-height: 46px;
            font-weight: 700;
            font-style: italic;
            @include breakpoint(max-md_device){
                font-size: 42px;
                line-height: 42px;
            }
            @include breakpoint(max-sm_device){
                font-size: 32px;
                line-height: 32px;
            }
            @include breakpoint(max-xs_device){
                font-size: 26px;
                line-height: 26px;
            }
        }
    }
}