/*----------------------------------------*/
/*    22 - Components - Button
/*----------------------------------------*/
.mgana-btn {
	box-shadow: 0px 8px 15px rgba(0, 0, 0, 0);
	background-color: $text-color;
    color: $white-color;
	width: 200px;
	height: 60px;
	line-height: 60px;
	display: block;
	text-align: center;
	text-transform: uppercase;
	font-weight: 400;
    @extend %basetransition;
	font-size: 16px;
	@include breakpoint(max-sm_device){
		width: 120px;
		height: 40px;
		line-height: 40px;
		font-size: 14px;
	}
	@include breakpoint(max-xs_device){
		width: 100px;
		height: 35px;
		line-height: 35px;
		font-size: 14px;
	}
	&.mgana-btn-2{
		height: 46px;
		line-height: 46px;
		width: 150px;
		font-size: 12px;
		@include breakpoint(max-xxs_device){
			height: 35px;
			line-height: 35px;
			width: 120px;
		}
	}
	&:hover{
		background-color: $primary-color;
		color: $white-color;
	}
	&.white-hover{
		&:hover{
			background-color: $white-color;
			color: $text-color;
		}
	}
	&.mgana-btn-13{
		height: 46px;
		line-height: 46px;
		width: 150px;
		font-size: 10px;
		letter-spacing: 1px;
		text-transform: uppercase;
		font-family: $general-font-family-7;
		@include breakpoint(max-xxs_device){
			height: 35px;
			line-height: 35px !important;
			width: 120px;
		}
		&.color-13{
			background-color: rgb(255, 75, 75)
		}
		&:hover{
			background-color: rgb(221, 169, 115);
			color: $white-color;
		}
		&.border-13{
			background-color: transparent;
			border: 1px solid $white-color;
			line-height: 43px;
			&:hover{
				border: 1px solid rgb(221, 169, 115);
				background-color: rgb(221, 169, 115);
				line-height: 43px;
			}
		}
	}
	&.mgana-btn-14{
		text-transform: uppercase;
		font-family: $general-font-family-7;
		@include breakpoint(max-xxs_device){
		}
		&:hover{
			background-color: rgb(221, 169, 115);
			color: $white-color;
		}
		&.border-14{
			background-color: transparent;
			border: 2px solid #b5b5b5;
			font-size: 14px;
			font-weight: bold;
			text-transform: uppercase;
			line-height: 4;
			letter-spacing: 2px;
			color: #3e3e3e;
			&:hover{
				border: 2px solid rgb(221, 169, 115);
				background-color: rgb(221, 169, 115);
				line-height: 4;
				color: $white-color;
			}
			@include breakpoint(max-sm_device){
				width: 180px;
				height: 60px;
				line-height: 4;
			}
		}
	}
	// Fullwidth
	&.btn_fullwidth{
		width: 100%;
	}
	// Color
	&.white-color{
		background-color: $white-color;
		color: $text-color;
		&:hover{
			background-color: $text-color;
			color: $white-color;
		}
		&.specific-hover_color{
			&:hover{
				background-color: $primary-color;
				color: $white-color;
			}
		}
		&.specific-hover_color-2{
			&:hover{
				background-color: $primary-color-2;
				color: $white-color;
			}
		}
		&.specific-hover_color-3{
			&:hover{
				background-color: $primary-color-3;
				color: $white-color;
			}
		}
	}
	&.primary-color{
		background-color: $primary-color;
		color: $white-color;
		width: 180px;
		&:hover{
			background-color: $white-color;
			color: $text-color;
		}
	}
	&.whisper-color{
		border: 1px solid $nobel-color;
		color: $theme-black-color !important;
		font-family: $body-font-family-name;
		width: 150px;
		line-height: 57px;
		@include breakpoint(max-sm_device){
			height: 60px;
		}
		&:hover{
			background-color: $primary-color;
			border-color: $primary-color;
			color: $white-color !important;
		}
	}
	// Transparent
	&.transparent-btn{
		background-color: transparent;
	}
}
.header-6{
	.mgana-btn{
		font-family: $general-font-family-2;
	}
}
.home-style-2{
	.mgana-btn-3{
		background-color: $default-color;
		font-family: $general-font-family-2;
		line-height: 59px;
		font-size: 18px;
		color: $white-color;
		width: 196px;
		height: 59px;
		display: block;
		text-align: center;
		@include breakpoint(max-sm_device){
			width: 130px;
			height: 50px;
			line-height: 50px;
			font-size: 14px;
		}
		@include breakpoint(max-xs_device){
			width: 100px;
			height: 35px;
			line-height: 35px;
			font-size: 12px;
		}
		&.hover-1{
			&:hover{
				background-color: $black-color;
				color: $white-color;
			}
		}
		&.hover-2{
			&:hover{
				background-color: $primary-color-4;
				color: $white-color;
			}
		}
		&.hover-3{
			&:hover{
				background-color: $default-color;
				color: $white-color;
			}
		}
		// Fullwidth
		&.btn_fullwidth{
			width: 100%;
		}
		// White color
		&.white-color{
			background-color: $white-color;
			color: $default-color;
		}
	}
	.mgana-btn-4{
		background-color: $default-color;
		font-family: $general-font-family-2;
		line-height: 52px;
		font-size: 16px;
		color: $white-color;
		width: 175px;
		height: 52px;
		display: block;
		text-align: center;
		@include breakpoint(max-sm_device){
			width: 130px;
			height: 50px;
			line-height: 50px;
			font-size: 14px;
		}
		@include breakpoint(max-xs_device){
			width: 100px;
			height: 35px;
			line-height: 35px;
			font-size: 12px;
		}
		&.hover-2{
			&:hover{
				background-color: $primary-color-4;
				color: $white-color;
			}
		}
	}
	.border-button-2{
		box-shadow: 0px 8px 15px rgba(0, 0, 0, 0);
		background-color: transparent;
		border: 2px solid $white-color;
		color: $white-color;
		width: 200px;
		height: 60px;
		line-height: 56px;
		display: block;
		text-align: center;
		text-transform: uppercase;
		font-family: $general-font-family-2;
		font-weight: 400;
		@extend %basetransition;
		font-size: 18px;
		@include breakpoint(max-sm_device){
			width: 120px;
			height: 40px;
			line-height: 35px;
			font-size: 14px;
		}
		@include breakpoint(max-xs_device){
			width: 100px;
			height: 35px;
			line-height: 31px;
			font-size: 14px;
		}
		&.hover-2{
			&:hover{
				background-color: $primary-color-4;
				color: $white-color;
				border-color: $primary-color-4;
			}
		}
	}
}
.home-style-3{
	.mgana-btn-5{
		background-color: $default-color;
		font-family: $body-font-family-name;
		line-height: 59px;
		font-size: 14px;
		font-weight: 600;
		text-transform: uppercase;
		color: $white-color;
		width: 196px;
		height: 59px;
		display: block;
		text-align: center;
		@include breakpoint(max-sm_device){
			width: 130px;
			height: 50px;
			line-height: 50px;
			font-size: 14px;
		}
		@include breakpoint(max-xs_device){
			width: 115px;
			height: 35px;
			line-height: 35px;
			font-size: 12px;
		}
		&.hover-1{
			&:hover{
				background-color: $primary-color-5;
				color: $white-color;
			}
		}
		&.hover-2{
			&:hover{
				background-color: #000;
				color: $white-color;
			}
		}
		// Default color
		&.default-color{
			background-color: $primary-color-5;
			color: $white-color;
		}
		// White color
		&.white-color{
			background-color: $white-color;
			color: $black-color;
		}
	}
}
.home-style-4{
	.mgana-btn-6{
		background-color: $primary-color-6;
		font-family: $body-font-family-name;
		line-height: 59px;
		font-size: 16px;
		font-weight: 600;
		border-radius: 30px;
		text-transform: uppercase;
		color: $white-color;
		width: 196px;
		height: 59px;
		display: block;
		text-align: center;
		@include breakpoint(max-sm_device){
			width: 130px;
			height: 50px;
			line-height: 50px;
			font-size: 14px;
		}
		@include breakpoint(max-xs_device){
			width: 100px;
			height: 35px;
			line-height: 35px;
			font-size: 12px;
		}
		&.small-btn{
			line-height: 30px;
			font-size: 12px;
			border-radius: 30px;
			width: 115px;
			height: 30px;
			display: block;
			text-align: center;
		}
		&.hover-1{
			&:hover{
				background-color: $primary-color-6;
				color: $white-color;
			}
		}
		&.hover-2{
			&:hover{
				background-color: #ff581c;
				color: $white-color;
			}
		}
		// Fullwidth
		&.btn_fullwidth{
			width: 100%;
		}
		// Default color
		&.default-color{
			background-color: $primary-color-6;
			color: $white-color;
		}
		// White color
		&.white-color{
			background-color: $white-color;
			color: $black-color;
		}
	}
}
.home-style-5{
	.mgana-btn-7{
		background-color: $text-color;
		font-family: $body-font-family-name;
		line-height: 45px;
		font-size: 10px;
		font-weight: 400;
		text-transform: uppercase;
		color: $white-color;
		width: 130px;
		height: 45px;
		display: block;
		text-align: center;
		@include breakpoint(max-sm_device){
			width: 130px;
			height: 50px;
			line-height: 50px;
			font-size: 14px;
		}
		@include breakpoint(max-xs_device){
			width: 100px;
			height: 35px;
			line-height: 35px;
			font-size: 12px;
		}
		&.hover-1{
			&:hover{
				background-color: $primary-color-6;
				color: $white-color;
			}
		}
		&.hover-2{
			&:hover{
				background-color: $primary-color;
				color: $white-color;
			}
		}
		&.hover-3{
			&:hover{
				background-color: $primary-color-6;
				border-color: $primary-color-6;
				color: $white-color;
			}
		}
		// Fullwidth
		&.btn_fullwidth{
			width: 100%;
		}
		// Default color
		&.default-color{
			background-color: $primary-color-6;
			color: $white-color;
		}
		// White color
		&.white-color{
			background-color: $white-color;
			color: $black-color;
			width: 160px;
			font-family: $general-font-family-7;
			font-size: 14px;
			font-weight: 700;
			text-transform: uppercase;
		}
		// Border-Button
		&.border-button{
			border: 2px solid $text-color;
			background: rgba(255,255,255,0);
			font-size: 14px;
			font-family: $general-font-family-7;
			font-weight: 700;
			color: $text-color;
			line-height: 48px;
			height: 50px;
		}
	}
}
/*--- Button Border Style  ---*/
.border-button{
	box-shadow: 0px 8px 15px rgba(0, 0, 0, 0);
	background-color: transparent;
	border: 2px solid $white-color;
    color: $white-color;
	width: 200px;
	height: 60px;
	line-height: 56px;
	display: block;
	text-align: center;
	text-transform: uppercase;
	font-weight: 400;
    @extend %basetransition;
	font-size: 16px;
	@include breakpoint(max-sm_device){
		width: 120px;
		height: 40px;
		line-height: 35px;
		font-size: 14px;
	}
	@include breakpoint(max-xs_device){
		width: 100px;
		height: 35px;
		line-height: 31px;
		font-size: 14px;
	}
	&.border-color-2{
		border: 1px solid #3e3e3e;
		color: #3e3e3e;
		font-weight: 400;
		font-family: $body-font-family-name;
		&:hover{
			color: $white-color;
		}
	}
	&:hover{
		border: 2px solid $primary-color-3;
		background-color: $primary-color-3;
		color: $white-color;
		@extend %basetransition;
	}
}
/*--- View More Button ---*/
.view-more-btn_wrap{
	display: flex;
	justify-content: center;
	margin: 0 auto;
	padding-top: 62px;
	text-align: center;
	.view-more{
		position: relative;
		font-size: 12px;
		text-transform: uppercase;
		line-height: 18px;
		padding: 10px 20px;
		min-width: 170px;
		background-color: transparent;
		font-family: $body-font-family-name;
		color: $theme-black-color;
		border: 1px solid $border-color-2;
		&:hover{
			color: $white-color;
			background-color: $primary-color;
			border-color: $primary-color;
			&:before{
				border-color: $primary-color;
			}
			&:after{
				border-color: $primary-color;
			}
		}
		&:before{
			content: '';
			position: absolute;
			top: 50%;
			border-top: 1px solid $border-color-2;
			width: 60px;
			transition: inherit;
			left: 100%;
		}
		&:after{
			content: '';
			position: absolute;
			top: 50%;
			border-top: 1px solid $border-color-2;
			width: 60px;
			transition: inherit;
			right: 100%;
		}
	}
	.hover-2{
		&:hover{
			background-color: $primary-color-4;
			border-color: $primary-color-4;
		}
	}
	.hover-3{
		&:hover{
			background-color: $primary-color-5;
			border-color: $primary-color-5;
			&:before{
				border-top: 1px solid $primary-color-5;
			}
			&:after{
				border-top: 1px solid $primary-color-5;
			}
		}
	}
	.hover-4{
		&:hover{
			background-color: $primary-color-7;
			border-color: $primary-color-7;
			&:before{
				border-top: 1px solid $primary-color-7;
			}
			&:after{
				border-top: 1px solid $primary-color-7;
			}
		}
	}
	.view-more-2{
		font-family: "Fjalla One", Sans-serif;
		font-size: 18px;
		letter-spacing: 5px;
		text-transform: uppercase;
		margin: 0px 0px 90px 0px;
		padding: 0px 0px 15px 0px;
		border-style: solid;
		border-width: 0px 0px 2px 0px;
		border-color: $border-color-3;
	}
	.view-more-3{
		font-family: "Acme",Sans-serif;
		font-size: 26px;
		font-weight: 400;
		fill: #3e3e3e;
		color: #3e3e3e;
		display: flex;
	}
	.view-more-4{
		border-color: $primary-color-7;
		&:before{
			border-color: $primary-color-7; 
		}
		&:after{
			border-color: $primary-color-7;
		}
	}
	.view-more-5{
		position: relative;
		font-size: 12px;
		text-transform: uppercase;
		line-height: 18px;
		padding: 10px 20px;
		min-width: 170px;
		background-color: transparent;
		font-family: $body-font-family-name;
		color: $theme-black-color;
		border: 1px solid #c7c7c7;
		&:hover{
			background-color: #3e3e3e;
			border-color: #3e3e3e;
			color: $white-color;
		}
	}
}

