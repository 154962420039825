/*----------------------------------------*/
/*    14 - Pages - Coming Soon
/*----------------------------------------*/
.coming-soon_area{
    background-image: url('../images/coming-soon/coming-soon-bg.jpg');
    background-repeat: no-repeat;
	background-position: center center;
    background-size: cover;
    height: 100vh;
    position: relative;
    @include breakpoint(max-xxs_device){
        background-position: left;
    }
    .coming-soon_content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        height: 100%;
        width: 700px;
        margin-left: 200px;
        @include breakpoint(max-lg_device){
            margin-left: 70px;
        }
        @include breakpoint(max-md_device){
            margin-left: 20px;
        }
        @include breakpoint(max-sm_device){
            width: 100%;
            margin: 0 0px;
        }
        > h1{
            &.heading{
                position: relative;
                color: $white-color;
                font-size: 90px;
                font-weight: normal;
                letter-spacing: -3px;
                font-family: $heading-font-family-name;
                margin-bottom: 40px;
                padding: 0 10px;
                @include breakpoint(max-sm_device){
                    font-size: 70px;
                }
                @include breakpoint(max-xxs_device){
                    font-size: 60px;
                }
            }
        }
        > h2{
            &.heading-two{
                font-family: $heading-font-family-name;
                visibility: visible;
                text-align: center;
                line-height: 58px;
                letter-spacing: 5px;
                font-weight: 600;
                font-size: 40px;
                padding: 35px 0px 35px 0px;
                border-style: solid;
                border-width: 4px 0px 4px 0px;
                border-color: rgba(255,255,255,0.2);
                width: 470px;
                margin: 0 auto;
                color: $white-color;
                text-transform: uppercase;
                @include breakpoint(max-xs_device){
                    width: 400px;
                    font-size: 35px;
                    letter-spacing: 3px;
                }
                @include breakpoint(max-xxs_device){
                    width: 280px;
                    font-size: 26px;
                    letter-spacing: 1px;
                }
            }
        }
        .coming-soon_btn{
            display: flex;
            justify-content: center;
            padding-top: 40px;
            > a{
                &.mgana-btn{
                    font-family: $body-font-family-name;
                }
            }
        }
    }
}