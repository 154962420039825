/*----------------------------------------*/
/*    10 - Pages - Blog
/*----------------------------------------*/
.blog-area-wrapper{
    margin-top: 80px;
    .blog-inner-box{
        background-color: #fff;
        box-shadow: 0 0 10px 0 rgba(0,0,0,0.13);
        .blog-thumbnail{
            position: relative;
            display: block;
            @include breakpoint(max-sm_device){
                display: block;
            }
            img{
                width: 100%;
            }
            .date-post{
                position: absolute;
                height: 70px;
                width: 70px;
                background-color: rgba(35,35,35,.9);
                opacity: .9;
                display: grid;
                justify-content: center;
                bottom: 0;
                right: 0;
                padding: 10px 0;
                text-align: center;
                font-family: $body-font-family-name;
                @include breakpoint(max-xxs_device){
                    height: 50px;
                    width: 50px;
                }
                span{
                    color: $white-color;
                }
                .day-post{
                    font-size: 21px;
                    text-transform: uppercase;
                    @include breakpoint(max-xxs_device){
                        font-size: 16px;
                        line-height: 16px;
                    }
                }
                .month-post{
                    font-size: 14px;
                    text-transform: uppercase;
                    @include breakpoint(max-xxs_device){
                        font-size: 10px;
                        line-height: 10px;
                    }
                }
            }
        }
        .blog-thumb-content{
            background-color: $primary-color;
            content: "";
            top: 0;
            left: 0;
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 1;
            cursor: pointer;
            pointer-events: none;
            z-index: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            .format-content{
                padding: 30px;
                width: 100%;
                @include breakpoint(max-xxs_device){
                    padding: 15px;
                }
                .quote-content{
                    font-size: 26px;
                    line-height: 1.7;
                    font-family: $heading-font-family-name;
                    letter-spacing: 3px;
                    width: 100%;
                    font-weight: 600;
                    margin: 10px auto 15px;
                    color: $white-color;
                    @include breakpoint(max-lg_device){
                        font-size: 24px;
                        line-height: 1.4;
                        letter-spacing: 1px;
                    }
                    @include breakpoint(max-xxs_device){
                        font-size: 20px;
                        line-height: 1.4;
                        margin: 10px auto 12px;
                        letter-spacing: 1px;
                    }
                    &:before{
                        content: '\ea7a';
                        font-family: LaStudioIcons;
                        font-size: 30px;
                        line-height: 1;
                        display: block;
                        margin-bottom: 20px;
                        @include breakpoint(max-xxs_device){
                            font-size: 18px;
                            margin-bottom: 10px;
                        }
                    }
                }
                .quote-author{
                    font-family: $heading-font-family-name;
                    font-style: italic;
                    font-size: 16px;
                    color: $white-color;
                    @include breakpoint(max-xxs_device){
                        font-size: 12px;
                        line-height: 12px;
                    }
                    &:before{
                        content: '';
                        width: 50px;
                        border-top: 2px solid;
                        display: block;
                        margin: 0 auto 12px;
                        opacity: .3;
                        @include breakpoint(max-xxs_device){
                            margin: 0 auto 10px;
                        }
                    }
                }
            }
        }
        .blog-thumb-video{
            content: "";
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            position: absolute;
            z-index: 1;
            .popup-video{
                i{
                    font-size: 20px;
                    height: 66px;
                    width: 66px;
                    line-height: 66px;
                    background: #ffffff;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
        .blog-content{
            padding-bottom: 30px;
            .blog-meta{
                padding-top: 15px;
                a{
                    text-transform: uppercase;
                    font-size: 11px;
                    font-weight: 700;
                    font-family: $body-font-family-name;
                    color: $theme-black-color;
                    &:hover{
                        color: $primary-color;
                        text-decoration: underline;
                    }
                    @include breakpoint(max-xxs_device){
                        font-size: 10px;
                        line-height: 10px;
                    }
                }
            }
            .blog-title{
                a{
                    font-size: 22px;
                    padding: 0px 20px 18px 20px;
                    display: block;
                    font-family: $heading-font-family-name;
                    color: $theme-black-color;
                    &:hover{
                        color: $primary-color;
                    }
                    @include breakpoint(min-xxl_device){
                        font-size: 28px;
                        line-height: 1.2;
                    }
                    @include breakpoint(max-xxs_device){
                        font-size: 18px;
                        line-height: 24px;
                        padding: 0px 10px 10px 10px;
                    }
                }
            }
            .desc-content{
                p{
                    margin-bottom: 20px;
                    @include breakpoint(max-xs_device){
                        font-size: 14px;
                    }
                }
                &:last-child{
                    p{
                        margin-bottom: 0px;
                    }
                }
            }
            .read-more{
                a{
                    text-transform: uppercase;
                    text-decoration: underline;
                    font-size: 12px;
                    font-family: $body-font-family-name;
                    letter-spacing: 1px;
                    color: $theme-black-color;
                    &:hover{
                        text-decoration: none;
                        color: $primary-color;
                    }
                    @include breakpoint(max-xxs_device){
                        font-size: 10px;
                        line-height: 10px;
                    }
                }
            }
            .read-more-2{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 30px;
                &::before{
                    content: '';
                    display: inline-block;
                    width: 100%;
                    border-top: 1px solid $border-color;
                    margin-right: 30px;
                    flex: 1;
                }
                a{
                    line-height: 26px;
                    padding: 10px 40px;
                    font-size: 12px;
                    font-family: $heading-font-family-name;
                    letter-spacing: 1px;
                    background-color: $theme-black-color;
                    color: $white-color;
                    text-transform: uppercase;
                    &:hover{
                        background-color: $primary-color;
                    }
                }
            }
            .read-more-3{
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 30px;
                a{
                    line-height: 26px;
                    padding: 10px 40px;
                    font-size: 12px;
                    font-family: $heading-font-family-name;
                    letter-spacing: 1px;
                    background-color: $theme-black-color;
                    color: $white-color;
                    text-transform: uppercase;
                    &:hover{
                        background-color: $primary-color;
                    }
                }
            }
        }
    }
    .blog-thumbnail-quote{
        .blog-inner-box{
            .blog-thumb-content{
                margin-bottom: 30px;
                .format-content{
                    background-color: $snow-color-2;
                    .quote-content{
                        color: $text-color;
                        font-weight: 400;
                        width: 430px;
                        @include breakpoint(max-xs_device){
                            width: 400px;
                        }
                        @include breakpoint(max-xxs_device){
                            width: 230px;
                        }
                    }
                    .quote-author{
                        color: $text-color;
                    }
                }
            }
        }
    }
    .nav-post-link{
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        @include breakpoint(max-xxs_device){
            justify-content: center;
            flex-direction: column;
            text-align: center;
        }
        .nav-post-button{
            font-size: 12px;
            font-family: $body-font-family-name;
            margin-top: 10px;
            display: block;
        }
        .nav-previous{
            width: 50%;
            @include breakpoint(max-xxs_device){
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            .nav-post-title{
                width: 230px;
                color: $text-color;
                display: block;
                &:hover{
                    color: $primary-color;
                }
            }
        }
        .nav-next{
            width: 50%;
            @include breakpoint(max-xxs_device){
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 20px;
            }
            .nav-post-title{
                width: 230px;
                margin: 0 0 0 auto;
                color: $text-color;
                display: block;
                @include breakpoint(max-xxs_device){
                    margin: 0 auto;
                    text-align: center;
                }
                &:hover{
                    color: $primary-color;
                }
            }
        }
    }
    .blog-feedback_area{
        padding-top: 45px;
        > h2{
            &.heading{
                font-size: 24px;
                line-height: 33.6px;
                padding-bottom: 25px;
            }
        }
        > li{
            &:not(:last-child){
                margin-bottom: 30px;
            }
            &.user-body{
                display: flex;
                @include breakpoint(max-xxs_device){
                    display: block;
                    text-align: center;
                }
                &.sub-user_body{
                    margin-left: 25px;
                }
                .user-pic{
                    width: 75px;
                    @include breakpoint(max-xxs_device){
                        padding-bottom: 30px;
                        width: 100%;
                    }
                }
                .user-content{
                    box-shadow: 0 0 10px rgba(0,0,0,.14);
                    border: 1px solid $border-color-3;
                    width: calc(100% - 75px);
                    margin-left: 25px;
                    padding: 30px;
                    @include breakpoint(max-xxs_device){
                        width: 100%;
                        margin-left: 0;
                        padding: 15px;
                    }
                    > h3{
                        display: flex;
                        padding-bottom: 20px;
                        &.user-name{
                            > a{
                                font-size: 16px;
                                line-height: 19.2px;
                            }
                        }
                        .user-meta{
                            color: $nobel-color;
                            padding-left: 5px;
                            font-size: 12px;
                            line-height: 21px;
                            font-family: $body-font-family-name;
                            font-weight: 400;
                        }
                    }
                    > p{
                        &.user-feedback{
                            border-bottom: 1px solid $border-color-3;
                            font-family: $body-font-family-name;
                            font-size: 14px;
                            line-height: 25.2px;
                            padding-bottom: 20px;
                        }
                    }
                    .reply-btn_wrap{
                        padding-top: 10px;
                        > a{
                            &.reply-btn{
                                color: $text-color;
                                font-weight: 600;
                                font-size: 12px;
                                line-height: 21px;
                                display: inline-block;
                                font-family: $body-font-family-name;
                                > span{
                                    padding-right: 5px;
                                    vertical-align: middle;
                                }
                            }
                            &:hover{
                                color: $primary-color;
                            }
                        }
                    }
                }
            }
        }
    }
    .review-body{
        .user-feedback{
            .heading{
                font-weight: 400;
            }
        }
    }
    .blog-post-title{
        > h2{
            &.heading{
                font-size: 24px;
                line-height: 16px;
                font-weight: 600; 
                text-transform: uppercase;
                > span{
                    position: relative;
                    padding-bottom: 15px;
                    margin-bottom: 50px;
                    display: inline-block;
                    &:before{
                        background-color: $text-color;
                        width: 20%;
                        height: 2px;
                        content: "";
                        position: absolute;
                        bottom: 0;
                        left: 0;
                    }
                }
            }
        }
    }
    .single-blog-post-carousel{
        .slick-list{
            margin: 0 -20px;
        }
        .blog-post-wrapper{
            .post-inner-box{
                box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.13);
                background-color: $white-color;
                padding: 0px;
                margin: 0 20px 10px 20px;
                .post-content{
                    .post-title{
                        a{
                            padding: 0px 20px 18px 20px;
                        }
                    }
                }
            }
    }
    }
    &.blog-custom-style-1{
        .blog-inner-box{
            position: relative;
            .blog-content{
                width: 570px;
                background-color: $white-color;
                position: absolute;
                padding: 10px 50px 44px 50px;
                bottom: 50px;
                left: 50px;
                @include breakpoint(max-lg_device){
                    padding: 0 40px 24px 40px;
                }
                @include breakpoint(max-md_device){
                    bottom: 10px;
                    left: 10px;
                }
                @include breakpoint(max-sm_device){
                    padding: 0px 20px 0px 20px;
                    width: 100%;
                    position: relative;
                    left: 0;
                    right: auto;
                    bottom: 0px;
                }
                .blog-meta{
                    @include breakpoint(max-md_device){
                        padding-top: 0px;
                    }
                    @include breakpoint(max-sm_device){
                        padding-top: 15px;
                    }
                }
                .blog-title{
                    a{
                        padding-right: 0px;
                    }
                }
                .read-more{
                    @include breakpoint(max-sm_device){
                        line-height: 14px;
                    }
                }
                .date-post{
                    position: absolute;
                    height: 70px;
                    width: 70px;
                    background-color: rgba(35, 35, 35, 1);
                    opacity: .9;
                    display: grid;
                    justify-content: center;
                    bottom: 0;
                    right: 0;
                    padding: 10px 0;
                    text-align: center;
                    font-family: $body-font-family-name;
                    @include breakpoint(max-sm_device){
                        display: none;
                    }
                    .day-post{
                        color: $white-color;
                        font-size: 21px;
                    }
                    .month-post{
                        color: $white-color;
                        font-size: 14px;
                    }
                }
            }
        }
    }
    &.blog-custom-style-2{
        margin: 0;
        .blog-inner-box{
            .blog-content{
                position: relative;
                top: 50%;
                transform: translateY(-50%);
                padding: 0 55px;
                @include breakpoint(max-md_device){
                    padding: 0 20px;
                }
                @include breakpoint(max-sm_device){
                    padding: 50px 20px;
                }
                .blog-meta{
                    padding: 0px;
                    line-height: 8px;
                }
                .blog-title{
                    a{
                        padding-right: 0px;
                    }
                }
                .desc-content{
                    @include breakpoint(max-md_device){
                        font-size: 14px;
                        margin-bottom: 10px;
                    }
                }
                .read-more-3{
                    @include breakpoint(max-md_device){
                        margin-top: 10px;
                    }
                }
            }
        }
        .paginatoin-area{
            margin-top: 50px;
            @include breakpoint(max-sm_device){
                margin-top: 0px;
            }
        }
    }
}
