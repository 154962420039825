/*----------------------------------------*/
/*    41 - Components - Instagram
/*----------------------------------------*/
.instagram-area{
    padding: 0 15px;
    .instagram-sub-col{
        flex: 0 0 25%;
        max-width: 25%;
    }
    .instagram-sub-col-2{
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
    .instagram-img{
        margin: 5px;
        position: relative;
        &:before{
            background-color: rgba(84,89,95,0.6);
            content: "";
            width: 100%;
            height: 100%;
            opacity: 0;
            position: absolute;
            @extend %basetransition;
            cursor: pointer;
            pointer-events: none;
        }
        &:hover{
            &:before{
                opacity: 0.8;
            }
            .add-action{
                opacity: 1;
            }
        }
        .add-action{
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            pointer-events: none;
            opacity: 0;
            @extend %basetransition;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            span,
            i{
                color: $white-color;
            }
            i{
                padding-right: 5px;
            }
        }
    }
}