/*----------------------------------------*/
/*    18 - Pages - Cart
/*----------------------------------------*/
.mgana-cart_area {
	padding: 90px 0;
}
.cart-content {
	table {
		border-color: $border-color;
		border-radius: 0;
		border-style: solid;
		border-width: 0 0 1px 0;
		text-align: center;
		width: 100%;
        margin-bottom: 0;
		th {
			border-top: medium none;
			font-size: 15px;
			text-transform: capitalize;
			padding: 20px 10px;
			text-align: center;
			font-weight: 500;
            vertical-align: middle;
            border: none;
            background-color: #f7f7f7;
		}
		td {
			border-top: medium none;
			padding: 18px 10px;
			vertical-align: middle;
			font-size: 13px;
			&.mgana-product_remove {
				font-size: 20px;
				> a {
					> i {
						font-size: 14px;
						@include transition(all 0.3s ease-in-out);
					}
				}
			}
			&.mgana-product-thumbnail{
				img{
					width: auto;
					height: 100%;
				}
			}
			&.mgana-product-name {
				font-size: 16px;
                text-transform: capitalize;
                min-width: 180px
			}
			&.mgana-product-price {
				font-size: 16px;
			}
			&.mgana-product-stock-status {
                min-width: 100px;
				span {
					&.in-stock,
					&.out-stock {
						font-size: 12px;
						text-transform: capitalize;
					}
					&.out-stock {
						color: $red-color;
					}
				}
			}
		}
	}
}
.cart-shipping-wrapper{
    background: #F9F9F9;
    padding: 30px 30px 0;
    top: 30px;
    .cart-totals{
        .title{
            font-size: 20px;
            padding: 10px 0;
            margin-bottom: 20px;
            color: $theme-black-color;
        }
    }
    table{
        width: 100%;
        tbody{
            display: flex;
            flex-direction: column;
            .cart-subtotal, .cart-shipping, .order-total{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }
            tr{
                th{
                    color: $theme-black-color;
                    font-size: 14px;
                    font-family: $body-font-family-name;
                    padding: 10px 0;
                }
                td{
                    display: flex;
                    align-items: center;
                    padding: 10px 0px;
                    span{
                        font-family: $body-font-family-name;
                        font-size: 14px;
                        color: $theme-black-color;
                        font-weight: 600;
                    }
                    .shipping-method{
                         > li:nth-child(-n+2) {
                            margin-bottom: 20px;
                            font-size: 14px;
                            font-family: $body-font-family-name;
                        }
                    }
                }
            }
            .order-total{
                border-top: 1px solid #DBDBDB;
            }
        }
    }
    .shipping-calculator{
        .shipping-calculator-form{
            display: none;
            margin-top: 15px;
            .nice-select{
                border-radius: 0;
                margin-bottom: 10px;
                .current{
                    font-size: 12px;
                    font-weight: 400;
                }
            }
            .checkout-form-list{
                margin-bottom: 10px;
                .input-text{
                    height: 40px;
                    line-height: 40px;
                    display: inline-block;
                    padding: 0 20px;
                    border-radius: 0;
                    border: solid 1px #e8e8e8;
                    &:placeholder-shown{
                        font-size: 12px;
                        font-weight: 400;
                        color: $theme-black-color;
                    }
                }
                .shipping-inner_btn{
                    font-size: 14px;
                    display: inline-block;
                    background-color: $theme-black-color;
                    color: #fff;
                    margin: 0;
                    padding: 18px 30px;
                    cursor: pointer;
                    text-align: center;
                    line-height: 1;
                    border: none;
                    transition: all .3s ease;
                    &:hover{
                        background-color: $primary-color;
                    }
                }
            }
        }
    }
}
.cart-collaterals{
    @include breakpoint(max-md_device){
        margin-top: 60px;
    }
    .order-button-payment{
        .mgana-btn{
            background-color: $theme-black-color;
            font-family: $body-font-family-name;
            text-transform: capitalize;
            &:hover{
                background-color: $primary-color;
            }
        }
    }
}