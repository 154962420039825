/*----------------------------------------*/
/*    04 - Global - Helper
/*----------------------------------------*/
/*--- Mgana's Custom Container ---*/
.container-fluid{
	padding: 0 140px;
	@include breakpoint(max-xxl_device){
		padding: 0 60px;
	}
	@include breakpoint(max-lg_device){
		padding: 0 20px;
	}
}
.container-fluid-2{
	width: 100%;
	padding: 0 80px;
	margin-right: auto;
	margin-left: auto;
	@include breakpoint(max-xxl_device){
		padding: 0 60px;
	}
	@include breakpoint(max-xl_device){
		padding: 0 30px;
	}
	@include breakpoint(max-lg_device){
		padding: 0 20px;
	}
}
.container-fluid-3{
	width: 100%;
	padding: 0 250px;
	margin-right: auto;
	margin-left: auto;
	@include breakpoint(max-xxl_device){
		padding: 0 60px;
	}
	@include breakpoint(max-xl_device){
		padding: 0 30px;
	}
	@include breakpoint(max-lg_device){
		padding: 0 20px;
	}
}
.container-fluid-4{
	width: 100%;
	padding: 0 238px;
	margin-right: auto;
	margin-left: auto;
	@include breakpoint(max-xxl_device){
		padding: 0 60px;
	}
	@include breakpoint(max-xl_device){
		padding: 0 30px;
	}
	@include breakpoint(max-lg_device){
		padding: 0 20px;
	}
}
.container-fluid-5{
	padding: 0 160px;
	@include breakpoint(max-xxl_device){
		padding: 0 60px;
	}
	@include breakpoint(max-lg_device){
		padding: 0 20px;
	}
}
.container-fluid-custom{
	width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
// End Here
/* ---Mgana Preloader---*/
#preloader{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: $white-smoke_color;
	z-index: 99999999;
}
.preloader{
	width: 50px;
	height: 50px;
	display: inline-block;
	padding: 0px;
	text-align: left;
	box-sizing: border-box;
	position: absolute;
	top: 50%;
	left: 50%;
	margin-left: -25px;
	margin-top: -25px;
	span{
		position: absolute;
		display: inline-block;
		width: 50px;
		height: 50px;
		background: $primary-color;
		animation: preloader 1.3s linear infinite;
		&:last-child{
			animation-delay: -0.8s;
		}
	}
}
@keyframes preloader {
	0%{
		transform: scale(0, 0);
		opacity: .9;
	}

	100%{
		transform: scale(1, 1);
		opacity: 0;
	}
}
.preloader-2{
	span{
		background: $primary-color-4;
	}
}
.preloader-3{
	span{
		background: $primary-color-5;
	}
}
.preloader-4{
	span{
		background: $primary-color-6;
	}
}
.preloader-5{
	span{
		background: $primary-color-7;
	}
}
/* ---Fixed Wrapper--- */
.fixed-wrapper{
	.fixed-header{
		@include breakpoint(min-xl_device){
			background: $white-color;
			position: fixed;
			width: 370px;
			z-index: 1;
			height: 100%;
		}
	}
	.main-site{
		@include breakpoint(min-xl_device){
			width: calc(100% - 370px);
			margin-left: auto;
		}
	}
}
/* ---Base Transition--- */
%basetransition {
	@include transition(all .3s ease-in);
}
/* ---Section Title--- */
.section-title{
	> .heading-one{
		margin-bottom: 0;
		line-height: 24px;
		padding-bottom: 20px;
		font-size: 36px;
		letter-spacing: 4px;
		@include breakpoint(max-xs_device){
			font-size: 28px;
			letter-spacing: 2px;
			line-height: 22px;  
		}
	}
}
.section-title-2{
	> .heading-5{
		color: $heading-color;
		font-family: $general-font-family-2;
		line-height: 36px;
		font-size: 36px;
		letter-spacing: 4px;
		font-weight: normal;
		@include breakpoint(max-xxs_device){
			font-size: 28px;
			letter-spacing: 2px;
			line-height: 24px;
		}
	}
}
.section-title-3 {
	position: relative;
	> .heading-5{
		color: $heading-color;
		font-family: $general-font-family-3;
		font-size: 46px;
		font-weight: 400;
		line-height: 33px;
		padding-bottom: 30px;
		margin-bottom: 50px;
		@include breakpoint(max-xxs_device){
			font-size: 28px;
			letter-spacing: 1px;
			line-height: 20px;
		}
		&:after{
			position: absolute;
			content: "";
			left: 0;
			right: 0;
			bottom: 0;
			margin: auto;
			height: 1px;
			background: $primary-color-5;
			width: 150px;
		}
	}
}
.section-title-4 {
	position: relative;
	> .heading-5{
		color: $heading-color-2;
		font-family: $general-font-family-5;
		font-size: 66px;
		font-weight: 400;
		line-height: 46px;
		padding-bottom: 30px;
		&:after{
			position: absolute;
			content: "";
			left: 0;
			right: 0;
			bottom: 0;
			margin: auto;
			height: 4px;
			background: $white-color;
			width: 80px;
		}
		@include breakpoint(max-sm_device){
			font-size: 52px;
		}
		@include breakpoint(max-xs_device){
			font-size: 46px;
		}
		@include breakpoint(max-xxs_device){
			padding-bottom: 15px;
		}
	}
	> .heading-6{
		color: $heading-color;
		font-family: $general-font-family-5;
		font-size: 66px;
		font-weight: 400;
		line-height: 46px;
		padding-bottom: 30px;
		opacity: 1;
		&:after{
			position: absolute;
			content: "";
			left: 0;
			right: 0;
			bottom: 0;
			margin: auto;
			height: 4px;
			background: $heading-color;
			width: 80px;
		}
		@include breakpoint(max-xs_device){
			font-size: 46px;
		}
		@include breakpoint(max-xxs_device){
			padding-bottom: 15px;
		}
	}
}
.section-title-5{
	> .heading-one{
		margin-bottom: 0;
		padding-bottom: 20px;
		font-size: 46px;
		line-height: 28px;
		font-family: $general-font-family-6;
		font-weight: 400;
		font-style: italic;
		text-transform: capitalize;
		@include breakpoint(max-sm_device){
			font-size: 36px;
			line-height: 22px;
		}
		@include breakpoint(max-xxs_device){
			font-size: 28px;
			letter-spacing: 2px;
			line-height: 20px;  
		}
	}
}
.section-title-13{
	display: flex;
    justify-content: center;
	> .heading-13{
		color: #3e3e3e;
		font-family: $general-font-family-3;
		font-size: 36px;
		line-height: 25px;
		font-weight: normal;
		text-transform: uppercase;
		letter-spacing: 2px;
		text-align: center;
		padding: 80px 20px;
		position: relative;
		@include breakpoint(max-md_device){
			font-size: 30px;
			line-height: 22px;
		}
		@include breakpoint(max-sm_device){
			font-size: 26px;
			line-height: 19px;
		}
		@include breakpoint(max-xxs_device){
			font-size: 16px;
			line-height: 12px;
			padding: 80px 10spx;
			letter-spacing: 1px;
		}
		&:before{
			content: '';
			position: absolute;
			top: 50%;
			border-top: 2px solid $border-color-3;
			width: 100px;
			transition: inherit;
			left: 100%;
			@include breakpoint(max-xs_device){
				width: 50px;
			}
			@include breakpoint(max-xxs_device){
				width: 20px;
			}
		}
		&:after{
			content: '';
			position: absolute;
			top: 50%;
			border-top: 2px solid $border-color-3;
			width: 100px;
			transition: inherit;
			right: 100%;
			@include breakpoint(max-xs_device){
				width: 50px;
			}
			@include breakpoint(max-xxs_device){
				width: 20px;
			}
		}
	}
}
.section-title-14{
	display: flex;
	justify-content: center;
	width: 100%;
	margin-top: 80px;
	flex-direction: column;
	align-items: center;
	.heading-14{
		color: #3e3e3e;
		font-family: $general-font-family-3;
		font-size: 36px;
		font-weight: normal;
		letter-spacing: 2px;
		line-height: 26px;
		text-transform: uppercase;
		margin-bottom:35px;
		@include breakpoint(max-sm_device){
			font-size: 30px;
			line-height: 20px;
		}
		@include breakpoint(max-xs_device){
			font-size: 28px;
			line-height: 18px;
		}
		@include breakpoint(max-xxs_device){
			font-size: 20px;
			line-height: 14px;
			letter-spacing: 1px;
		}
	}
}
.border-heading{
	> .heading-5{
		position: relative;
		display: inline-block;
		&:after{
			position: absolute;
			content: "";
			left: 0;
			right: 0;
			bottom: -8px;
			margin: auto;
			border-bottom: 2px solid #3e3e3e;
			width: 100%;
		}
	}
}
.heading-two{
	margin-bottom: 0;
	line-height: 24px;
	font-size: 32px;
	letter-spacing: 4px;
}
.heading-3{
	font-size: 16px;
	font-weight: 400;
	letter-spacing: 4px;
}
.heading-4{
	font-size: 46px;
	letter-spacing: 6px;
	color: $banner-color-1;
}
.heading-6{
	font-family: $general-font-family-2;
	font-size: 16px;
	color: $border-color;
	opacity: .5;
	@extend %basetransition;
}
.small-title-1{
	font-size: 24px;
	font-weight: 400;
	line-height: 1.2em;
	letter-spacing: 26px;
	@include breakpoint(max-sm_device){
		letter-spacing: 16px;
	}
	@include breakpoint(max-xs_device){
		letter-spacing: 8px;
		font-size: 20px;
	}
}
.small-title-2{
	font-size: 24px;
	font-weight: 400;
	line-height: 1.2em;
	letter-spacing: 3px;
	font-family: $heading-font-family-name;
	@include breakpoint(max-xs_device){
		font-size: 20px;
	}
}
.large-title-1{
	font-size: 70px;
	line-height: 1.2em;
	letter-spacing: 7px;
	@include breakpoint(max-sm_device){
		font-size: 50px;
		letter-spacing: 5px;
	}
	@include breakpoint(max-xs_device){
		font-size: 36px;
		letter-spacing: 5px;
	}
}
.large-title-2{
	font-size: 100px;
	font-weight: 400;
	line-height: .9em;
}
.large-title-3{
	font-size: 66px;
	line-height: 1.25em;
	font-family: $general-font-family-2;
	font-weight: normal;
	@include breakpoint(max-sm_device){
		font-size: 50px;
	}
	@include breakpoint(max-xs_device){
		font-size: 36px;
	}
}
.desc-one{
	font-size: 18px;
	line-height: 2;
}
/* ---Product Sticker---*/
.sticker {
	position: absolute;
	top: 20px;
	right: 0;
	width: 50px;
	height: 25px;
	line-height: 24px;
	font-size: 10px;
	text-transform: uppercase;
	text-align: center;
	background: $text-color;
	color: $white_color;
	text-transform: uppercase;
	cursor: pointer;
}
/* ---Tooltip--- */
.tooltip{
	z-index: 10;
}
.tooltip-inner {
	padding: 0 10px;
	height: 25px;
	line-height: 22px;
	text-align: center;
	border-radius: 2px;
	font-size: 10px;
	background-color: $black-color;
	text-indent: 0;
}
.tooltip.bs-tooltip-auto[x-placement^=top] .arrow::before,
.tooltip.bs-tooltip-top .arrow::before {
	content: "";
	border-width: 5px 5px 0;
}
.tooltip.bs-tooltip-auto[x-placement^=top] .arrow::before,
.tooltip.bs-tooltip-top .arrow::before {
	border-top-color: $black-color;
}
.bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.bs-tooltip-bottom .arrow::before {
	border-bottom-color: $black-color;
}
.tooltip.bs-tooltip-auto[x-placement^=right] .arrow::before,
.tooltip.bs-tooltip-right .arrow::before {
	border-right-color: $black-color;
}
.bs-tooltip-auto[x-placement^=left] .arrow::before,
.bs-tooltip-left .arrow::before {
	border-left-color: $black-color;
}
/* ---Spacing Between Two Row---*/
.product-slider {
	.slick-slide {
		div {
			&:not(:last-child) {
				.product-item {
					padding-bottom: 55px;
				}
			}
		}
	}
}
/* ---Slick Disabled Cursor Point--- */
.slick-disabled {
	cursor: not-allowed !important; 
}
/* ---Tab Content & Pane Fix--- */
.tab-content {
	width: 100%;
	.tab-pane {
		display: block;
		height: 0;
		max-width: 100%;
		visibility: hidden;
		overflow: hidden;
		opacity: 0;
		&.active {
			height: auto;
			visibility: visible;
			opacity: 1;
			overflow: visible;
			@extend %basetransition;
		}
	}
}
/*-- Scroll To Top --*/
.scroll-to-top{
    background-color: $text-color;
    color: $white-color;
    position: fixed;
    right: 50px;
	bottom: 50px;
	width: 40px;
	height: 40px;
	line-height: 40px;
    z-index: 999;
    font-size: 20px;
    overflow: hidden;
    display: block;
	text-align: center;
	opacity: 0;
	visibility: hidden;
	animation:  SlideInDown 1s normal;
	@include transition(all 300ms ease);
	&.show{
		opacity: 1;
		visibility: visible;
		animation: SlideInUp 1s normal;
	}
	i{
		@extend %basetransition;
		color: $white-color;
		font-size: 16px;
		display: inline-block;
	}
	&:hover{
		background-color: $primary-color;
		> i{
			animation: SlideInUp 1s infinite;
		}
	}
	&.bg-color-2{
		background-color: $default-color;
		&:hover{
			background-color: $primary-color-4;
		}
	}
	&.bg-color-3{
		background-color: $default-color;
		&:hover{
			background-color: $primary-color-5;
		}
	}
	&.bg-color-4{
		background-color: $default-color;
		&:hover{
			background-color: $primary-color-6;
		}
	}
	&.bg-color-5{
		background-color: $default-color;
		&:hover{
			background-color: $primary-color-7;
		}
	}
}
// Rating Box
.rating-box {
	> ul {
		line-height: 12px;
		> li {
			display: inline-block;
			line-height: 12px;
			> i {
				color: #F5A623;
				font-size: 14px;
			}
		}
	}
	&.gamboge-color{
		> ul{
			> li{
				> i{
					color: $gamboge-color;
				}
			}
		}
	}
}
/* ---Pagination--- */
.paginatoin-area {
	padding: 30px 0 90px;
	&.position-center{
		display: flex;
		justify-content: center;
	}
	.pagination-box {
		li {
			display: inline-block;
			> a {
				border: 2px solid $border-color;
				color: $border-color;
				width: 45px;
				height: 45px;
				line-height: 44px;
				text-align: center;
				display: block;
				font-family: $body-font-family-name;
				&.next{
					i{
						font-size: 16px;
					}
				}
			}
			&.active{
				> a{
					color: $theme-black-color;
					border-color: $theme-black-color;
				}
			}
			&:hover{
				> a{
					color: $theme-black-color;
					border-color: $theme-black-color;
				}
			}
		}
	}
}
/* ---Tag--- */
.tags-list {
	li {
		display: inline-block;
		margin-bottom: 5px;
		a {
			font-size: 14px;
			border: 1px solid $border-color;
			padding: 5px 15px;
			display: block;
			line-height: 20px;
		}
	}
}
/* ---Range Slider--- */
.price-filter {
	.ui-widget-content {
		background-color: $border-color;
		border: none;
		border-radius: 0;
		box-shadow: none;
		cursor: pointer;
		height: 5px;
		margin: 0 0 25px;
		width: 100%;
		border-top-left-radius: 25px;
		border-top-right-radius: 25px;
		border-bottom-left-radius: 25px;
		border-bottom-right-radius: 25px;
	}
	.ui-slider-range {
		background-color: $theme-black-color;
		border: none;
		box-shadow: none;
		height: 100%;
	}
	.ui-slider-handle {
		background: $white-color;
		box-shadow: 0 1px 4px 0 rgba(0,0,0,.4);
		display: block;
		outline: none;
		width: 20px;
		height: 20px;
		line-height: 15px;
		margin: 0;
		text-align: center;
		top: 50%;
		border-radius: 100%;
		box-shadow: 0px 0px 6.65px .35px rgba(0, 0, 0, 0.15);
		transform: translate(0, -50%);
		cursor: e-resize;
		@extend %basetransition;
		&:last-child {
			transform: translate(0%, -50%);
		}
		&:hover {
			background-color: $primary-color;
		}
	}
	.price-slider-amount {
		display: flex;
		justify-content: space-between;
		align-items: center;
		.filter-btn {
			border: 2px solid $theme-black-color;
			width: 110px;
			height: 40px;
			line-height: 38px;
			font-size: 12px;
			@extend %basetransition;
			@include breakpoint(lg-device){
				width: 40px;
				font-size: 14px;
			}
			&:hover{
				background-color: $primary-color;
                border-color: $primary-color;
                color: $white-color;
			}
		}
		.label-input {
			label {
				font-size: 14px;
				margin: 0;
				text-transform: capitalize;
				padding-left: 5px;
			}
			input {
				background: transparent none repeat scroll 0 0;
				border: medium none;
				box-shadow: none;
				font-size: 14px;
				height: inherit;
				padding-left: 5px;
				width: 99px;
			}
		}
	}
}
/* ---Countdown--- */
.countdown-wrap {
	.countdown{
		&.item-4{
			.countdown__item{
				display: inline-block;
				position: relative;
				padding: 10px 0;
				text-align: center;
				margin-left: 60px;
				@include breakpoint(max-md_device){
					margin-left: 30px;
				}
				@include breakpoint(max-xxs_device){
					margin-left: 15px;
				}
				&:first-child{
					margin-left: 0;
				}
				span {
					text-transform: capitalize;
					&.countdown__time {
						font-size: 49px;
					}
					&.countdown__text {
						display: block;
						font-size: 18px;
						line-height: 32.4px;
					}
				}
			}
		}
	}
	&.white-text_color{
		span {
			color: $white-color;
		}
	}
	&.with-border_radius{
		.countdown{
			&.item-4{
				.countdown__item{
					background-color: $white-color;
					padding: 15px 0 10px;
					width: 90px;
					height: 90px;
					border-radius: 100%;
					margin-left: 15px;
					@include breakpoint(max-xl_device){
						margin-left: 5px;
						width: 70px;
						height: 70px;
						padding: 10px 0
					}
					@include breakpoint(max-xs_device){
						width: 60px;
						height: 60px;
						padding: 5px 0
					}
					&:first-child{
						margin-left: 0;
					}
					span {
						font-family: 'Playfair Display', serif;
						font-style: italic;
						text-transform: none;
						font-size: 16px;
						@include breakpoint(max-xl_device){
							font-size: 14px;
						}
						&.countdown__time {
							font-weight: 600;
							font-size: 25px;
							@include breakpoint(max-xl_device){
								font-size: 18px;
							}
						}
						&.countdown__text {
							display: block;
							@include breakpoint(max-xl_device){
								line-height: 1.4;
							}
							@include breakpoint(max-xs_device){
								line-height: 1;
							}
						}
					}
				}
			}
		}
	}
}
/* ---Section Space--- */
.h-100{
	height: 100% !important;
}
// Padding Top
.pt-100{
	padding-top: 100px;
}
.pt-90{
	padding-top: 90px;
}
.pt-80{
	padding-top: 80px;
}
.pt-60{
	padding-top: 60px;
}
.pt-50{
	padding-top: 50px;
}
.pt-35{
	padding-top: 35px;
}
.pt-30{
	padding-top: 30px;
}
.pt-10{
	padding-top: 10px;
}
// Padding bottom
.pb-100{
	padding-bottom: 100px;
}
.pb-90{
	padding-bottom: 90px;
}
.pb-85{
	padding-bottom: 85px;
}
.pb-80{
	padding-bottom: 80px;
}
.pb-75{
	padding-bottom: 75px;
}
.pb-70{
	padding-bottom: 70px;
}
.pb-60{
	padding-bottom: 60px;
}
.pb-55{
	padding-bottom: 55px;
}
.pb-50{
	padding-bottom: 50px;
}
.pb-40{
	padding-bottom: 40px;
}
.pb-30{
	padding-bottom: 30px;
}
.pb-25{
	padding-bottom: 25px;
}
.pb-20{
	padding-bottom: 20px;
}
.pb-15{
	padding-bottom: 15px;
}
.pb-10{
	padding-bottom: 10px;
}
// margin Top
.mt-100{
	margin-top: 100px;
}
.mt-90{
	margin-top: 90px;
}
.mt-80{
	margin-top: 80px;
}
.mt-75{
	margin-top: 75px;
}
.mt-60{
	margin-top: 60px;
}
.mt-50{
	margin-top: 50px;
}
.mt-30{
	margin-top: 30px;
}
.mt-20{
	margin-top: 20px;
}
.mt-15{
	margin-top: 15px;
}
.mt-10{
	margin-top: 10px;
}
// margin Bottom
.mb-100{
	margin-bottom: 100px;
}
.mb-90{
	margin-bottom: 90px;
}
.mb-80{
	margin-bottom: 80px;
}
.mb-70{
	margin-bottom: 70px;
}
.mb-50{
	margin-bottom: 50px;
}
.mb-40{
	margin-bottom: 40px;
}
.mb-30{
	margin-bottom: 30px;
}
.mb-20{
	margin-bottom: 20px;
}
.mb-10{
	margin-bottom: 10px;
}
// Margin Right
.mr-10{
	margin-right: 10px;
}
/*--- Image Hover Style ---*/
.hover-style{
	display: block;
	overflow: hidden;
	img{
		transform: scale(1.01) rotate(.05deg);
        transition: all .6s ease-in-out;
	}
	.overlay-1{
		position: absolute;
		left: 0;
		top: 0;
		opacity: 0;
		content: "";
		background-color: #3e3e3e;
		width: 100%;
		height: 100%;
		@extend %basetransition;
	}
	&:hover{
		img{
			transform: scale(1.05) rotate(.05deg);
            transition: all .6s ease-in-out;
		}
		.overlay-1{
			opacity: .2;
			@extend %basetransition;
		}
	}
}
.hover-style-2{
	display: block;
	overflow: hidden;
	img{
		transform: translate3d(-40px,0,0);
		max-width: none;
		width: calc(100% + 50px);
		transition: all .6s ease-in-out;
	}
	&:hover{
		img{
			transform: translate3d(0,0,0);
            transition: all .6s ease-in-out;
		}
	}
}
.hover-style-3{
	display: block;
	overflow: hidden;
	.background-3{
		transform: scale(1.01) rotate(.05deg);
        transition: all .6s ease-in-out;
	}
	.overlay-1{
		position: absolute;
		left: 0;
		top: 0;
		opacity: 0;
		content: "";
		background-color: #3e3e3e;
		width: 100%;
		height: 100%;
		@extend %basetransition;
	}
	&:hover{
		.background-3{
			transform: scale(1.08) rotate(.05deg);
            transition: all .9s ease-in-out;
		}
		.overlay-1{
			opacity: .2;
			@extend %basetransition;
		}
	}
}
/* ---Image Zoom Effect--- */
.img-zoom_effect{
	position: relative;
	overflow: hidden;
	&:before{
		background-color: #000;
		content: "";
		top: 0;
		left: 0;
		position: absolute;
		width: 100%;
		height: 100%;
		opacity: 0;
		cursor: pointer;
		pointer-events: none;
		z-index: 1;
		@extend %basetransition;
	}
	img{
		@include transition(1.8s ease-in-out);
	}
	&:hover{
		&:before{
			opacity: 0.3;
		}
		img{
			transform: scale(1.08);
		}
	}
}
/* ---Global Overlay--- */
.global-overlay {
	background-color: rgba($text-color, .8);
	@extend %basetransition;
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	z-index: 99;
	visibility: hidden;
	&.overlay-open {
		visibility: visible;
		cursor: url(../images/menu/icon/light-close.png) 16 16, pointer;
	}
}
/* ---Nice Select Customization--- */
.myniceselect {
	&.nice-select {
		padding: 0;
		margin: 0;
		height: auto;
		line-height: auto;
		span {
			height: 40px;
			line-height: 40px;
			display: inline-block;
			padding: 0 20px;
		}
		.list {
			width: 100%;
		}
		&.wide{
			span{
				width: 100%;
			}
		}
	}
}
/*--- Slick Dots Style ---*/
.slick-dots {
	position: absolute;
	bottom: 30px;
	left: auto;
	text-align: center;
	padding: 0 30px;
	@include breakpoint(max-xl_device){
		padding: 0;
	}
	li {
		border: 1px solid $black-color;
		display: inline-block;
		cursor: pointer;
		font-size: 0;
		width: 12px;
		height: 12px;
		border-radius: 100%;
		@extend %basetransition;
		&:first-child{
			margin-right: 15px;
		}
		&:hover {
			background-color: $text-color;
			border-color: $text-color;
		}
		&.slick-active {
			background-color: $text-color !important;
			border: 1px solid $text-color;
		}
		button {
			border: none;
		}
	}
}
.custom-dots{
	.slick-dots{
		position: absolute;
		padding: 0;
		left: 50%;
		transform: translateX(-50%);
		bottom: -45px;
		> li{
			background-color: rgba(153,133,119,0.5);
			border: 0;
			width: 15px;
			height: 15px;
			&:hover{
				background-color: $text-color;
			}
			@include breakpoint(max-sm_device){
				width: 10px;
				height: 10px;
			}
			&:not(:last-child){
				margin-right: 20px;
				@include breakpoint(max-sm_device){
					margin-right: 10px;
				}
			}
		}
	}
}
.custom-dots-2 {
	.slick-dots{
		left: 50%;
		transform: translateX(-50%);
		> li{
			background-color: $white-color;
			border: 0;
			width: 15px;
			height: 15px;
			&:hover{
				background-color: $text-color;
			}
			@include breakpoint(max-sm_device){
				width: 10px;
				height: 10px;
			}
			&:not(:last-child){
				margin-right: 20px;
				@include breakpoint(max-sm_device){
					margin-right: 10px;
				}
			}
		}
	}
}
.custom-dots-3{
	.slick-dots{
		left: 50%;
		transform: translateX(-50%);
		> li {
			width: 3px;
			height: 3px;
			background: $white-color;
			cursor: pointer;
			border: 5px solid $black-color;
			border-radius: 50%;
			box-sizing: content-box;
			perspective: 400;
			transform: translatez(0.01px);
			transition: all 0.3s;
			&:hover{
				background: $black-color;
				border-color: $white-color;
			}
			&.slick-active{
				background: $black-color;
				border-color: $white-color;
			}
			&:not(:last-child){
				margin-right: 20px;
				@include breakpoint(max-sm_device){
					margin-right: 10px;
				}
			}
		}
	}
}
.custom-dots-4{
	.slick-dots{
		left: 50%;
		transform: translateX(-50%);
		bottom: -73px;
		z-index: 9;
		> li {
			background-color: $white-color;
			width: 29px;
			height: 29px;
			border-style: solid;
			border-width: 4px 4px 4px 4px;
			border-color: $snow-color;
			@include breakpoint(max-sm_device){
				width: 24px;
				height: 24px;
			}
			&.slick-active{
				background-color: $white-color !important;
				border-color: $primary-color-6;
			}
			&:not(:last-child){
				margin-right: 20px;
				@include breakpoint(max-sm_device){
					margin-right: 10px;
				}
			}
		}
	}
}
/* ---Mgana Slick Arrows--- */
.arrow-style-1{
	.slick-arrow {
		background-color: $white-color;
		position: absolute;
		bottom: 30px;
		left: calc(50% - 48px);
		transform: translateX(-50%);
		width: 70px;
		height: 70px;
		line-height: 70px;
		z-index: 1;
		&.tty-slick-text-next{
			transform: translateX(60px);
		}
		> i{
			display: block;
			line-height: 70px;
			@include breakpoint(max-md_device){
				line-height: 60px;
			}
		}
		&:hover{
			opacity: 0.6;
			> i{
				transform: scale(1.2);
			}
		}
		@include breakpoint(max-md_device){
			width: 60px;
			height: 60px;
			line-height: 60px;
			bottom: 20px;
			left: calc(50% - 45px);
		}
	}
}
.arrow-style-2{
	.slick-arrow {
		background-color: $white-color;
		position: absolute;
		bottom: 30px;
		top: calc(50% - 0px);
		transform: translateY(-50%);
		width: 60px;
		height: 60px;
		line-height: 60px;
		opacity: 0;
		visibility: hidden;
		z-index: 1;
		@extend %basetransition;
		&.tty-slick-text-next{
			right: 0%;
		}
		&.tty-slick-text-prev{
			left: 0%;
		}
		> i{
			display: block;
			line-height: 60px;
			@include breakpoint(max-md_device){
			}
		}
		&:hover{
			opacity: 0.6;
			> i{
				transform: scale(1.2);
			}
		}
		@include breakpoint(max-md_device){
		}
	}
	&:hover{
		.slick-arrow{
			opacity: 1;
			visibility: visible;
			@extend %basetransition;
			&.tty-slick-text-next{
				right: 5%;
			}
			&.tty-slick-text-prev{
				left: 5%;
			}
		}
	}
}
.arrow-style-3{
	.slick-arrow{
		background-color: #adadad;
		color: $white-color;
		&:hover{
			background-color: #000;
		}
	}
}
.arrow-style-4{
	.slick-arrow{
		position: absolute;
		width: 56px;
		height: 56px;
		background: transparent;
		align-items: center;
		line-height: 14px;
		font-size: 14px;
		border: 2px solid;
		border-color: rgba(62, 62, 62, 0.5);
		z-index: 9;
		opacity: 1;
		@include breakpoint(max-xs_device){
			height: 46px;
			width: 46px;
		}
		&.tty-slick-text-next{
			right: 3.4%;
			top: 86.5%;
			transform: translateY(-90%);
			@include breakpoint(max-xl_device){
				right: 2%;
			}
		}
		&.tty-slick-text-prev{
			right: 3.4%;
			top: 77%;
			transform: translateY(-80%);
			@include breakpoint(max-xl_device){
				right: 2%;
			}
		}
		&:hover{
			background: $eclipse-color;
			color: $white-color;
			border: 2px solid;
			border-color: rgba(62, 62, 62, 1);
			opacity: 1;
		}
	}
	&:hover{
		.slick-arrow{
			opacity: 1;
			visibility: visible;
		}
	}
}
.arrow-style-5{
	.slick-arrow {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		width: 40px;
		height: 40px;
		background: $white-smoke_color;
		align-items: center;
		line-height: 12px;
		font-size: 12px;
		z-index: 9;
		opacity: 0;
		&.tty-slick-text-next{
			right: 5px;
		}
		&.tty-slick-text-prev{
			left: 5px;
		}
		&:hover{
			background: $primary-color;
			color: $white-color;
		}
	}
	&:hover{
		.slick-arrow{
			opacity: 1;
			visibility: visible;
			@extend %basetransition;
			&.tty-slick-text-next{
				right: 0%;
				left: auto;
			}
			&.tty-slick-text-prev{
				left: 0%;
				right: auto;
			}
		}
	}
}
.arrow-style-6{
	.slick-arrow{
		background-color: #adadad;
		color: $white-color;
		&:hover{
			background-color: $primary-color-4;
		}
	}
}
.arrow-style-7{
	.slick-arrow{
		position: absolute;
		width: 36px;
		height: 36px;
		background: transparent;
		align-items: center;
		line-height: 14px;
		font-size: 14px;
		z-index: 9;
		opacity: 1;
		@include breakpoint(max-xs_device){
			height: 26px;
			width: 26px;
		}
		&.tty-slick-text-next{
			right: 10%;
			top: 100%;
			i{
				transform: rotate(90deg);
			}
		}
		&.tty-slick-text-prev{
			left: 10%;
			top: 100%;
			i{
				transform: rotate(90deg);
			}
		}
		&:hover{
			color: $theme-black-color;
			opacity: 1;
		}
	}
	&:hover{
		.slick-arrow{
			opacity: 1;
			visibility: visible;
		}
	}
}
.arrow-style-8{
	.slick-arrow {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		width: 40px;
		height: 40px;
		align-items: center;
		line-height: 12px;
		font-size: 12px;
		z-index: 9;
		opacity: 1;
		&.tty-slick-text-next{
			left: auto;
			right: -9%;
			@include breakpoint(max-md_device){
				right: -12%;
			}
			@include breakpoint(max-sm_device){
				right: -9%;
			}
			@include breakpoint(max-xxs_device){
				right: -15%;
			}
		}
		&.tty-slick-text-prev{
			right: auto;
			left: -9%;
			@include breakpoint(max-md_device){
				left: -12%;
			}
			@include breakpoint(max-sm_device){
				left: -9%;
			}
			@include breakpoint(max-xxs_device){
				left: -15%;
			}
		}
	}
}
.arrow-style-9{
	.slick-arrow {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		width: 40px;
		height: 40px;
		align-items: center;
		line-height: 12px;
		font-size: 12px;
		z-index: 9;
		opacity: 0;
		visibility: hidden;
		box-shadow: 0 0 0 1px rgba(0,0,0,0.1);
		background-color: #fff;
		border-radius: 50%;
		&.tty-slick-text-next{
			left: auto;
			right: 0%;
		}
		&.tty-slick-text-prev{
			right: auto;
			left: 0%;
		}
		&:hover{
			color: $white-color;
			background-color: $primary-color;
			opacity: 1;
		}
	}
	&:hover{
		.slick-arrow{
			opacity: 1;
			visibility: visible;
			&.tty-slick-text-next{
				left: auto;
				right: -1.2%;
			}
			&.tty-slick-text-prev{
				right: auto;
				left: -1.2%;
			}
		}
	}
}
.arrow-style-10{
	.slick-arrow {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		width: 40px;
		height: 40px;
		background: $white-smoke_color;
		align-items: center;
		line-height: 12px;
		font-size: 12px;
		z-index: 9;
		opacity: 1;
		&.tty-slick-text-next{
			right: 15px;
		}
		&.tty-slick-text-prev{
			left: 15px;
		}
		&:hover{
			background: $theme-black-color;
			color: $white-color;
		}
	}
}
/*--- Mgana's Brand Logo --- */
.brand-logo-area{
	padding: 10px 0px;
}
.brand-slider{
	.slick-list{
		margin-right:-18px;
		margin-left: -20px;
		.slick-track{
			display: flex;
			align-items: center;
		}
	}
}
.brand-slider-2{
	padding: 20px 150px 10px;
	border-top: 1px solid $silver-color;
	@include breakpoint(max-lg_device){
		padding: 20px 100px 10px;
	}
	@include breakpoint (max-md_device){
		padding: 20px 0px 10px;
	}
	.slick-track{
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
.single-brand-item{
	margin: 20px;
	padding: 10px;
	@extend %basetransition;
	.brand-item{
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	img{
		background: $white-color;
		opacity: .5;
		@extend %basetransition;
	}
	&:hover{
		@extend %basetransition;
		img{
			opacity: 1;
			@extend %basetransition;
		}
	}
}
.single-brand-item-2{
	margin: 20px;
	padding: 10px;
	@extend %basetransition;
	.brand-item{
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	img{
		background: $white-color;
		opacity: 1;
		@extend %basetransition;
	}
}
.brand-logo-2{
	.single-brand-item{
		img{
			background: $white-smoke_color;
		}
	} 
}
/* ---Mgana Newsletters Popup --- */
.popup_wrapper {
	background: rgba(255, 255, 255, 0.7) none repeat scroll 0 0;
	height: 100%;
	opacity: 0;
	position: fixed;
	@extend %basetransition;
	visibility: hidden;
	width: 100%;
	z-index: 9999999;
	@include breakpoint (max-md_device) {
		display: none;
	}
	.test {
		background: $white-color;
		background-position: right;
		background-size: contain;
		background-repeat: no-repeat;
		bottom: 0;
		height: 425px;
		left: 0;
		margin: auto;
		max-width: 870px;
		padding: 50px 50px 50px;
		position: absolute;
		right: 0;
		top: 0;
		@include breakpoint (max-large) {
			max-width: 810px;
		}
		.popup_off {
			background: $text-color;
			color: $white-color;
			top: 0;
			right: 0;
			width: 40px;
			height: 40px;
			line-height: 43px;
			display: block;
			cursor: pointer;
			position: absolute;
			text-align: center;
			text-transform: uppercase;
			@extend %basetransition;
			font-size: 20px;
			&:hover{
				background-color: $primary-color;
			}
		}
	}
	.subscribe-area {
		display: flex;
		align-items: center;
		height: 100%;
		.subscribe-img{
			background-color: #f0daca;
			width: 40%;
			.subscribe-inner_img{
				margin: -20px -20px 20px 20px;
			}
		}
		.subscribe-content{
			padding-left: 80px;
			width: 60%;
			text-align: center;
			> h2 {
				color: $heading-color;
				font-weight: 600;
				font-size: 30px;
				text-transform: capitalize;
				margin-bottom: 0;
				padding-bottom: 20px;
			}
			> p {
				margin: 0;
				max-width: 580px;
				padding-bottom: 25px;
				color: $heading-color;
				font-size: 16px;
			}
			.subscribe-form-group {
				display: flex;
				justify-content: center;
				form {
					&.subscribe-form{
						position: relative;
						input {
							background: $white-color;
							border: 1px solid $border-color !important;
							color: $heading-color;
							border: 0 none;
							height: 50px;
							padding: 0 150px 0 20px;
							width: 100%;
							&::placeholder{
								color: $heading-color;
							}
						}
						button {
							background: $primary-color;
							color: $white-color;
							top: 0;
							right: 0;
							position: absolute;
							width: 130px;
							height: 50px;
							line-height: 50px;
							border: 0;
							display: block;
							text-transform: uppercase;
							@extend %basetransition;
							&:hover{
								background-color: $primary-color;
							}
						}
					}
					}
			}
			.subscribe-bottom {
				display: flex;
				justify-content: center;
				align-items: center;
				padding-top: 25px;
				input {
					margin-right: 5px;
				}
				label {
					margin-bottom: 0;
					color: $heading-color;
				}
			}
		}
	}
}
/* ---Mousemove Wrap--- */
.mousemove-wrap {
	width: calc(100% - 120px);
	height: calc(100% - 120px);
	bottom: 60px;
	left: 60px;
	position: absolute;
	.page-back {
		position: relative;
		top: calc(100% - 145px);
		left: 0;
		width: 100%;
		@include breakpoint(max-lg_device){
			top: calc(100% - 95px);
		}
		@include breakpoint(max-md_device){
			top: calc(100% - 55px);
		}
		@include breakpoint(max-sm_device){
			top: calc(100% - 10px);
		}
		@include breakpoint(max-xxs_device){
			top: calc(100% + 25px);
		}
	}
}
/* ---Bootstrap No Gutters--- */
.no-gutters{
	margin-right: 0px;
	margin-left: 0px;
}
/* ---In Stock--- */
.in-stock{
	font-size: 12px;
	margin-left: 10px;
	font-weight: 600;
	@include breakpoint(max-xxs_device){
		margin-left: 0px;
	}
	i{
		&:before{
			border: 1px solid #86ba45;
			width: 15px;
			height: 15px;
			line-height: 13px;
			border-radius: 100%;
			font-size: 10px;
			display: block;
			text-align: center;
			margin-right: 5px;
		}
	}
}
/* --- Quantity --- */
.quantity {
	margin-right: 10px;
	.cart-plus-minus {
		position: relative;
		width: 88px;
		text-align: left;
		> .cart-plus-minus-box {
			border: 1px solid $border-color;
			height: 60px;
			text-align: center;
			width: 85px;
			background: $white-color;
		}
		> .qtybutton {
			cursor: pointer;
			position: absolute;
			text-align: center;
			@extend %basetransition;
			font-size: 18px;
			&.dec {
				top: 50%;
				left: 5%;
				transform: translateY(-50%);
				display: flex;
				justify-content: center;
			}
			&.inc {
				border-bottom: none;
				top: 50%;
				right: 5%;
				transform: translateY(-50%);
				display: flex;
				justify-content: center;
			}
		}
	}
}