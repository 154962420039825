/*----------------------------------------*/
/*    06 - Layout - Header
/*----------------------------------------*/
.main-header{
    @include breakpoint(max-lg_device){
        padding: 40px 0px;
    }
}
/* ---Header Logo--- */
.header-logo{
    display: flex;
    align-items: center;
    @include breakpoint (max-xs_device){
        justify-content: center;
    }
    img{
        width: 175px;
    }
}
/* ---Main Menu--- */
.main-nav{
    > ul{
        > li{
            display: inline-block;
            padding: 45px 0px;
            position: relative;
            &:hover {
                .dropdown-hover {
                    visibility: visible;
                    opacity: 1;
                    top: 100%;
                }
                .menu-text{
                    &:after{
                        width: 100%;
                        opacity: 1;
                    }
                }
            }
            > a{
                display: block;
                padding: 8px 12px;
                font-size: 16px;
                text-transform: uppercase;
                &.active{
                    color: #d4876c !important;
                    .menu-text{
                        &:after{
                            width: 100%;
                            opacity: 1;
                        }
                    }
                }
                i{
                    margin-left: 5px;
                    font-size: 12px;
                    vertical-align: middle;
                }
                .menu-text{
                    position: relative;
                    &:after{
                        position: absolute;
                        left: 0;
                        right: 0;
                        margin: auto;
                        width: 20%;
                        border-bottom: 2px solid $primary-color;
                        content: "";
                        z-index: 1;
                        opacity: 0;
                        @extend %basetransition;
                        bottom: -16px;
                    }
                }
            }
            ul{
                li{
                    position: relative;
                    &:hover{
                        .dropdown-hover-2{
                            opacity: 1;
                            left: 100%;
                            visibility: visible;
                        }
                    }
                }
            }
        }
    }
}
/*--- Dropdwon ---*/
.mgana-dropdown{
    background: $white-color;
    box-shadow: 0 1px 24px 0 rgba(0, 0, 0, .09);
    padding: 20px 0 24px;
    width: 230px;
    > li {
        > a {
            padding: 10px 20px;
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            color: $text-color;
            font-family: $body-font-family-name;
            position: relative;
            &:hover{
                color: $primary-color;
                text-decoration: underline;
            }
            &.active{
                color: $primary-color;
                text-decoration: underline;
            }
            > i {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 2%;
                font-size: 12px;
                padding-right: 10px;
            }
        }
    }
}
.dropdown-hover{
    position: absolute;
    top: 110%;
    opacity: 0;
    visibility: hidden;
    transition: all .3s linear 0.2s;
    z-index: 999;
}
.dropdown-hover-2{
    position: absolute;
    left: 110%;
    top: 0px;
    opacity: 0;
    visibility: hidden;
    transition: all .3s linear 0.2s;
    z-index: 999;
}
/*--- Mega-Menu ---*/
.mega-menu{
    background: $white-color;
    box-shadow: 0 1px 24px 0 rgba(0, 0, 0, .09);
    padding: 15px;
    // transform: translate(-50%, 0);
    // left: 50%;
    &.three-column{
        width: 870px;
    }
    .menu-colum{
        width: 33.333%;
        padding: 15px;
        float: left;
        ul{
            li{
                .mega-menu-text{
                    position: relative;
                    font-size: 20px;
                    font-family: $general-font-family-name;
                    display: inline-block;
                    padding: 10px 20px 15px 0px;
                    margin: 0px 0px 10px 20px;
                    &:after{
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        margin: auto;
                        width: 30%;
                        border-bottom: 2px solid $primary-color;
                        content: "";
                        z-index: 1;
                    }
                }
                a{
                    padding: 10px 20px;
                    display: block;
                    font-family: $body-font-family-name;
                    font-size: 14px;
                    line-height: 20px;
                    &:hover{
                        text-decoration: underline;
                    }
                    &.active{
                        color: $primary-color;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}
.three-column{
    transform: translate(-50%, 0);
    left: 50%;
    @include breakpoint(max-xl_device){
        transform: translate(-30%, 0);
        left: 30%;
    }
}
.four-column{
    .four-column-wrapper {
        float: left;
        width: 73%;
        padding: 15px;
        @include breakpoint(max-xl_device){
            width: 71%;
        }
    }
    .mega-menu-image{
        width: 27%;
        @include breakpoint(max-xl_device){
            width: 29%;
        }
    }
    width: 1200px;
    transform: translate(-47.2%, 0);
    left: 100%;
    @include breakpoint(max-xl_device){
        transform: translate(-33%, 0);
        left: 28%;
        width: 1068px;
    }
}
.shop-demo{
    display: flex;
    justify-content: center;
    position: relative;
    overflow: hidden;
    a{
        img{
            transform: scale(1.01) rotate(.05deg);
            transition: all .7s ease-in-out;
        }
    }
    &:hover{
        a{
            img{
                transform: scale(1.05) rotate(.05deg);
                transition: all .7s ease-in-out;
            }
        }
        .mgana-btn{
            background-color: $text-color;
			color: $white-color !important;
        }
    }
    .demo-content{
        position: absolute;
        bottom: 50px;
        text-align: center;
        p{
            color: $white-color;
            font-size: 16px;
        }
        .title{
            color: $white-color;
            font-size: 60px;
            text-transform: uppercase;
        }
        .mgana-btn{
            display: inline-block;
            &:hover{
                color: $white-color;
            }
        }
    }
}
/* ---Header Right Area--- */
.header-right-area{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @include breakpoint (max-xs_device){
        justify-content: center;
        padding-top: 30px;
    }
    > ul{
        > li{
            float: left;
            &:nth-child(-n+2){
                margin-right: 30px;
            }
            &.minicart-wrap{
                > a{
                    > .minicart-count_area{
                        position: relative;
                        span{
                            &.cart-item_count{
                                background-color: $cart-count-color;
                                color: $white-color;
                                position: absolute;
                                top: -15px;
                                right: -10px;
                                width: 18px;
                                height: 18px;
                                line-height: 17px;
                                font-size: 10px;
                                text-align: center;
                                border-radius: 100%;
                            }
                        }
                    }
                }
            }
            > a{
                i{
                    font-size: 20px;
                }
            }
        }
    }
}
/* ---Sticky Header--- */
.header-sticky{
    background-color: $white-color;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    @include transition(all 300ms ease-in 0s);
    z-index: 99;
    display: none;
    @include breakpoint(max-lg_device){
        padding: 30px 0px;
    }
    .main-nav > ul > li{
        padding: 30px 0px;
    }
    &.sticky {
        animation: 800ms ease-in-out 0s normal none 1 running slideInDown;
        display: block;
        @include breakpoint(max-xs_device){
            position: static;
        }
    }
    .sticky-header_nav{
        padding: 0;
        @include breakpoint(max-md_device){
            padding: 20px 0;
        }
        .header-logo_area{
            > a {
                > img{
                    max-width: 120px;
                }
            }
        }
        .main-menu_area{
            > nav{
                &.main-nav{
                    > ul{
                        > li{
                            @include breakpoint(max-lg_device){
                                margin-right: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
}
/* ---Header 2--- */
.header-2{
    background-color: transparent;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 99;
}
/* ---Header 3--- */
.header-3{
    .header-middle-area{
        padding: 60px 0 0px;
        @include breakpoint(max-md_device){
            padding: 60px 0 60px;
        }
        .social-link{
            padding: 0px;
        }
    }
    .main-header-3{
        @include breakpoint(max-lg_device){
            padding: 0px;
        }
    }
    .three-column{
        @include breakpoint(max-lg_device){
            left: 100%;
        }
    }
    .four-column{
        @include breakpoint(max-lg_device){
            left: 50%;
            width: 950px;
        }
        .four-column-wrapper{
            @include breakpoint(max-lg_device){
                width: 69%;
            }
        }
        .mega-menu-image{
            @include breakpoint(max-lg_device){
                width: 31%;
            }
        }
    }
    .mega-menu{
        .menu-colum{
            &.menu-column-3{
                @include breakpoint(max-lg_device){
                    padding: 0;
                }
            }
        }
    }
}
/* ---Header 4--- */
.header-top{
    .header-offer{
        text-align: center;
        @include breakpoint(max-lg_device){
            text-align: left;
        }
        @include breakpoint(max-md_device){
            text-align: center;
            padding-top: 10px;
        }
        a{
            font-size: 12px;
            font-family: $body-font-family-name;
            span{
                text-transform: uppercase;
            }
        }
    }
    .box-right{
        text-align: right;
        @include breakpoint(max-md_device){
            text-align: center;
        }
        ul{
            li{
                display: inline-block;
                position: relative;
                padding: 13px 0px;
                a{
                    padding: 10px;
                    font-size: 12px;
                    font-family: $body-font-family-name;
                    @include breakpoint(max-xl_device){
                        padding: 10px 6px;
                    }
                    @media #{$desktop-device-2}{
                        padding: 10px 3px;
                    }
                    i {
                        padding-left: 3px;
                        font-size: 10px;
                        @media #{$desktop-device-2}{
                            font-size: 8px;
                            padding-left: 1px;
                        }
                    }
                }
                &:last-child{
                    a{
                        padding-right: 0px;
                    }
                }
            }
        }
        .box-dropdown{
            background: $white-color;
            -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.176);
            box-shadow: 0 6px 12px rgba(0,0,0,.176);
            right: 0;
            position: absolute;
            top: 100%;
            width: 140px;
            z-index: 99;
            padding: 10px 0;
            display: none;
            text-align: left;
            @include breakpoint(max-xxs_device){
                width: 100px;
            }
            li{
                display: block;
                padding: 0px;
                a{
                    padding: 3px 10px;
                    font-size: 12px;
                    width: 100%;
                }
            }
        }
    }
    .social-link{
        padding-top: 0px;
        > ul{
            > li{
                > a {
                    font-size: 18px;
                }
            }
        }
    }
}
/* ---Header 5--- */
.header-5{
    background-color: transparent;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 99;
    .main-header-5{
        .main-nav{
            > ul{
                > li{
                    > a{
                        color: $white-color;
                    }
                    &:hover{
                        > a{
                            color: $primary-color;
                        }
                    }
                }
            }
        }
        .header-right-area{
            > ul{
                > li{
                    > a{
                        color: $white-color;
                    }
                    &:hover{
                        > a{
                            color: $primary-color;
                        }
                    }
                }
            }
        }  
    }
}
/* ---Header 6--- */
.header-6{
    .main-nav {
        > ul{
            > li{
                > a {
                    font-family: $general-font-family-2;
                    &.active{
                        color: $primary-color-4 !important;
                    }
                    .menu-text{
                        &:after{
                            border-color: $primary-color-4;
                        }
                    }
                }
            }
        }
    }
    .mega-menu{
        .menu-colum{
            ul{
                li{
                    .mega-menu-text{
                        font-family: $general-font-family-2;
                    }
                }
            }
        }
    }
    .shop-demo{
        .demo-content{
            h2{
                font-family: $general-font-family-2;
                font-size: 50px;
                color: $white-color;
                margin-bottom: 20px;
            }
        }
    }
    .demo-content{
        .mgana-btn-3{
            margin: 0 auto;
        }
    }
    .mobile-menu_wrapper{
        .btn-close-2{
            > i:hover{
                color: $primary-color-4;
            }
        }
    }
    .offcanvas-navigation{
        .mobile-menu{
            li.menu-open{
                > a{
                    color: $primary-color-4;
                }
            }
        }
    }
}
/* ---Header 7--- */
.header-7{
    background-color: transparent;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 99;
    .main-header-7{
        .main-nav{
            > ul{
                > li{
                    > a{
                        color: $white-color;
                        font-family: $body-font-family-name;
                        &.active {
                            color: $primary-color-5 !important;
                        }
                    }
                    &:hover{
                        > a{
                            color: $primary-color-5;
                        }
                    }
                }
            }
        }
        .header-right-area{
            > ul{
                > li{
                    > a{
                        color: $white-color;
                        font-family: $body-font-family-name;
                    }
                    &:hover{
                        > a{
                            color: $primary-color-5;
                        }
                    }
                }
            }
        }
        .mega-menu{
            .menu-colum{
                ul{
                    li{
                        .mega-menu-text{
                            font-family: $general-font-family-3;
                            font-weight: 600;
                        }
                    }
                }
            }
            .shop-demo{
                .demo-content{
                    h1{
                        font-family: $general-font-family-3;
                    }
                }
            }
        }
    }
    .header-sticky{
        .main-nav{
            > ul{
                > li{
                    > a{
                        font-family: $body-font-family-name;
                        &.active{
                            color: $primary-color-5 !important;
                        }
                    }
                    &:hover{
                        > a{
                            color: $primary-color-5;
                        }
                    }
                }
            }
        }
        .header-right-area{
            > ul{
                > li{
                    > a{
                        font-family: $body-font-family-name;
                    }
                    &:hover{
                        > a{
                            color: $primary-color-5;
                        }
                    }
                }
            }
        }
        .mega-menu{
            .menu-colum{
                ul{
                    li{
                        .mega-menu-text{
                            font-family: $general-font-family-3;
                        }
                    }
                }
            }
        }
    }
}
/* ---Header 8--- */
.header-8{
    .header-logo{
        img{
            position: relative;
            width: 250px;
            top: -8px;
        }
    }
    .main-nav{
        > ul{
            > li{
                > a{
                    color: $text-color;
                    font-family: $body-font-family-name;
                    text-transform: capitalize;
                    font-weight: 600;
                    &.active {
                        color: $primary-color-6 !important;
                    }
                }
                &:hover{
                    > a{
                        color: $primary-color-6;
                    }
                }
            }
        }
    }
    .header-right-area{
        > ul{
            > li{
                &:nth-child(-n+2){
                    margin-right: 10px;
                }
                > a{
                    color: $text-color;
                    font-family: $body-font-family-name;
                }
                >a.search-btn {
                    > i{
                        padding: 12px;
                        background-color: #ff7a4b;
                        color: $white-color;
                        border-radius: 100%;
                        font-size: 18px;
                        &:hover{
                            background-color: #ff4d0c;
                        }
                    }
                }
                .minicart-btn{
                    i{
                        padding: 12px;
                        background-color: #64638D;
                        color: $white-color;
                        border-radius: 100%;
                        font-size: 18px;
                        &:hover{
                            background-color: #19178C;
                        }
                    }
                    .minicart-count_area{
                        span.cart-item_count{
                            top: -8px;
                            right: 0px;
                        }
                    }
                }
                .menu-btn{
                    i{
                        padding: 9px 0;
                        font-size: 24px;
                    }
                }
                .mobile-menu_btn{
                    i{
                        padding: 9px 0;
                        font-size: 24px;
                    }
                }
            }
        }
    }
    .mega-menu{
        .menu-colum{
            ul{
                li{
                    .mega-menu-text{
                        font-family: $general-font-family-5;
                        font-weight: 500;
                    }
                }
            }
        }
        .shop-demo{
            .demo-content{
                h1{
                    font-family: $general-font-family-3;
                }
            }
        }
    }
    .header-sticky{
        .main-nav{
            > ul{
                > li{
                    > a{
                        font-family: $body-font-family-name;
                        color: $text-color;
                        &.active{
                            color: $primary-color-6 !important;
                        }
                    }
                    &:hover{
                        > a{
                            color: $primary-color-6;
                        }
                    }
                }
            }
        }
        .header-right-area{
            > ul{
                > li{
                    > a{
                        font-family: $body-font-family-name;
                    }
                    &:hover{
                        > a{
                            color: $primary-color-6;
                        }
                    }
                }
            }
        }
        .mega-menu{
            .menu-colum{
                ul{
                    li{
                        .mega-menu-text{
                            font-family: $general-font-family-5;
                        }
                    }
                }
            }
        }
    }
}
/* ---Header 9--- */
.header-9{
    .header-top{
        @include breakpoint(max-xs_device){
            display: none;
        }
        border-bottom: 1px solid;
        border-color: rgba(216,216,216,.4);
        .box-right{
            @include breakpoint(max-md_device){
                text-align: right;
            }
        }
    }
    .header-logo{
        img{
            width: 240px;
        }
    }
    .main-nav{
        > ul{
            > li{
                > a {
                    font-family: $body-font-family-name;
                    letter-spacing: 1px;
                    margin-right: 10px;
                    padding: 2px 20px;
                    @include breakpoint(max-xl_device){
                        padding: 2px 12px;
                    }
                    &:last-child{
                        margin-right: 0px;
                    }
                    &.active{
                        background: $primary-color-7;
                        color: $white-color !important;
                    }
                    &:hover{
                        background: $primary-color-7;
                        color: $white-color !important;               
                    }
                    .menu-text{
                        &:after{
                            display: none;
                        }
                    }
                }
            }
        }
    }
    .three-column{
        @include breakpoint(max-xxl_device){
            transform: translate(-26.5%, 0);
            left: 26.5%;
        }
        @include breakpoint(max-xl_device){
            transform: translate(-26.5%, 0);
            left: 44%;
        }
    }
    .four-column{
        @include breakpoint(max-xxl_device){
            transform: translate(-31%, 0);
            left: 31%;
        }
        @include breakpoint(max-xl_device){
            transform: translate(-31%, 0);
            left: 35%;
        }
    }
    .header-right-area {
        > ul {
            > li {
                float: left;
                position: relative;
                padding: 45px 27px;
                line-height: 27px;
                align-items: center;
                justify-content: center;
                display: block;
                border-left: 1px solid rgba(216,216,216,.4);
                border-right: 1px solid rgba(216,216,216,.4);
                margin-right: 0px;
                &:nth-child(-n+2){
                    border-right: 0px;
                }
                @include breakpoint(max-xxl_device){
                    padding: 45px 23px;
                }
                @include breakpoint(max-xl_device){
                    padding: 45px 12px;
                }
                @include breakpoint(max-xs_device){
                    padding: 0px 15px;
                    border: 0px;
                    line-height: inherit;
                }
            }
        }
    }
    .header-sticky{
        .main-nav {
            > ul {
                > li {
                    padding: 45px 0px;
                }
            }
        }
    }
    .main-header{
        @include breakpoint(max-lg_device){
            padding: 0px;
            .header-right-area {
                > ul {
                    > li.menu-wrap {
                        display: none;
                    }
                }
            }
        }
        @include breakpoint(max-xs_device){
            padding: 40px 0px; 
        }
    }
}
/* ---Header 10--- */
.header-10{
    .offcanvas-menu_wrapper{
        &.open{
            .offcanvas-menu-inner{
                left: 0;
                right: auto;
            }
        }
        .offcanvas-menu-inner{
            left: -285px;
            .btn-close{
                left: auto;
                right: 25px;
            }
        }
    }
    .header-top{
        padding-top: 35px;
        @include breakpoint(max-sm_device){
            padding-top: 15px;
        }
    }
    .header-logo-2{
        display: flex;
        justify-content: center;
        img{
            width: 280px;
            @include breakpoint(max-sm_device){
                width: 240px;
            }
        }
    }
    .header-right-area{
        > ul {
            > li{
                > a{
                    i{
                        font-size: 24px;
                    }
                }
            }
            .minicart-wrap {
                margin-right: 0px;
                @include breakpoint(max-md_device){
                    margin-right: 30px;
                }
            }
        }
    }
    .social-link{
        @include breakpoint(max-sm_device){
            text-align:center;
            padding-bottom: 14px;
            margin: 0px -20px 20px -20px;
            border-bottom: 1px solid rgba(0,0,0,0.2);
        }
        > ul {
            > li{
                padding-right: 18px;
                > a{
                    font-size: 18px;
                }
            }
        }
    }
    .header-left-area{
        > ul {
            > li{
                float: left;
                margin-right: 35px;
                > a{
                    font-size: 24px;
                }
                @include breakpoint(max-md_device){
                    display: none;
                }
            }
        }
    } 
    .header-sticky{
        .header-right-area{
            > ul {
                .minicart-wrap{
                    margin-right: 30px;
                }
            }
        }
        .offcanvas-menu_wrapper{
            &.open{
                .offcanvas-menu-inner{
                    right: 0;
                    left: auto;
                }
            }
            .offcanvas-menu-inner{
                right: -285px;
                .btn-close{
                    right: auto;
                    left: 25px;
                }
            }
        }
    }
    .main-nav {
        > ul {
            > li {
                > a {
                    margin: 8px 10px;
                    font-family: $body-font-family-name;
                    @include breakpoint(max-xl_device){
                        margin: 8px;
                    }
                }
            }
        }
    }
    .main-header{
        @include breakpoint(max-lg_device){
            padding: 18px 0px;
        }
        @include breakpoint(max-sm_device){
            padding: 10px 0px;
        }
    }
    .three-column {
        @include breakpoint(max-xl_device){
            transform: translate(-30%, 0);
            left: 100%;
        }
    }
    .four-column {
        @include breakpoint(max-xl_device){
            transform: translate(-33%, 0);
            left: 60%;
        }
    }
}
/* ---Header 11--- */
.header-11{
    .fixed-main_header{
        @include breakpoint(min-xl_device){
            background-color: $white-color;
            height: 100%;
            .container-fluid{
                padding: 0 30px;
            }
            .header-logo{
                padding-top: 60px;
                justify-content: center;
                @include breakpoint(max-xl_device){
                    padding-top: 20px;
                }
                img{
                    width: 250px;
                }
            }
            .header-right-area{
                padding-top: 60px;
                justify-content: center;
                @include breakpoint(max-xl_device){
                    padding-top: 30px;
                }
            }
            .main-menu_area{
                text-align: center;
                padding-top: 20px;
                > nav{
                    &.main-nav{
                        position: relative;
                        > ul{
                            width: 100%;
                            flex-direction: column;
                            > li{
                                display: block;
                                padding: 0 0 20px;
                                position: static;
                                @include breakpoint(max-xl_device){
                                    padding: 0 0 5px;
                                }
                                &:last-child{
                                    padding-bottom: 0;
                                }
                                &:hover{
                                    > a{
                                        color: $primary-color !important;
                                    }
                                }
                                > a{
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    .menu-text{
                                        &:after{
                                            bottom: -5px;
                                        }
                                    }
                                }
                                .three-column{
                                    left: calc(100% - -30px);
                                    transform: translate(0%, 0);
                                    top: 0%;
                                    width: 500px;
                                }
                                .four-column{
                                    top: -35% !important;
                                    left: calc(100% - -30px);
                                    transform: translate(0%, 0);
                                    width: 750px;
                                    .four-column-wrapper{
                                        width: 100%;
                                    }
                                }
                                .mgana-dropdown{
                                    left: calc(100% - -30px);
                                    width: 200px;
                                    &.dropdown-right-1 {
                                        top: calc(100% - 242px);
                                    }
                                    &.dropdown-right-2 {
                                        top: calc(100% - 184px);
                                    }
                                    &.dropdown-right-3 {
                                        top: calc(100% - 370px);
                                    }
                                    &.dropdown-right-4{
                                        left: calc(100% - 0px);
                                        top: calc(100% - 64px);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }    
}