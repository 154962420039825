/*----------------------------------------*/
/*    43 - Components - Sidebar
/*----------------------------------------*/
/* ---Sidebar Area--- */
.sidebar-area,
.filter-body{
    .search-box{
        position: relative;
        .mc-form{
            position: relative;
            width: 100%;
            .text-box{
                width: 100%;
                padding: 15px 40px 15px 20px;
                background: transparent;
                font-size: 14px;
                line-height: 28px;
                color: inherit;
                display: inline-block;
                min-height: 30px;
                border: 1px solid $border-color;
                vertical-align: middle;
            }
            .search-btn{
                position: absolute;
                right: 0px;
                top: 50%;
                transform: translateY(-50%);
                vertical-align: middle;
                font-size: 18px;
                line-height: 30px;
                padding: 20px 15px;
                align-items: center;
                display: flex;
                &:hover{
                    color: $primary-color;
                }
            }
        }
    }
    .search-newsletter{
        .mc-form{
            .text-box{
                border-color: #000;
            }
        }
    }
    .widgets-area{
        > h2{
            &.heading{
                font-size: 16px;
                line-height: 16px;
                text-transform: uppercase;
                > span{
                    position: relative;
                    padding-bottom: 15px;
                    margin-bottom: 30px;
                    display: inline-block;
                    &:before{
                        background-color: $text-color;
                        width: 50%;
                        height: 1px;
                        content: "";
                        position: absolute;
                        bottom: 0;
                        left: 0;
                    }
                }
            }
        }
        .widgets-item{
            > ul{
                > li{
                    > a{
                        font-family: 'Playfair Display', serif;
                        font-style: italic;
                        padding: 10px 0;
                        border-bottom: 1px solid $border-color;
                        display: block;
                    }
                    &:first-child{
                        > a{
                            padding-top: 0;
                        }
                    }
                }
            }
        }
        .instagram-area{
            .instagram-item{
                &.instagram-col{
                    display: flex;
                    max-width: 33.333%;
                }
            }
        }
        .widgets-tags{
            > ul{
                > li{
                    display: inline-block;
                    > a{
                        font-size: 14px;
                    }
                }
            }
            &.with-border{
                margin-bottom: -5px;
                > ul{
                    > li{
                        margin-bottom: 10px;
                        &:not(:last-child){
                            margin-right: 5px;
                        }
                        > a{
                            border: 1px solid $border-color;
                            display: block;
                            padding: 10px;
                        }
                    }
                }
            }
        }
        .widgets-banner{
            position: relative;
            cursor: pointer;
            .widgets-banner_text{
                backface-visibility: hidden;
                position: absolute;
                left: 0;
                bottom: 0;
                padding: 0 0 30px 30px;
                display: flex;
                flex-direction: column;
                @include breakpoint(max-lg_device){
                    padding: 0 0 15px 15px;
                }
                span{
                    color: #fff;
                    font-size: 32px;
                    font-weight: 400;
                    text-align: left;
                    font-family: $heading-font-family-name;
                }
                .banner-text{
                    color: #fff;
                    font-size: 16px;
                    font-weight: 700;
                    text-align: center;
                }
            }
        }
        &.archives-area{
            @include breakpoint(max-md_device){
                padding-bottom: 30px;
            }
            .myniceselect{
                &.wide{
                    span{
                        padding: 0 60px 0 15px;
                    }
                }
            }
        }
        .list-product_area{
            .single-blog{
                display: flex;
                .blog-img{
                    width: 80px;
                }
                .blog-content{
                    width: calc(100% - 80px);
                    padding-left: 15px;
                    > h3{
                        &.title{
                            font-family: 'Playfair Display', serif;
                            font-size: 16px;
                            line-height: 22.4px;
                            color: $charcoal-color;
                            font-weight: 300;
                        }
                    }
                    .post-meta{
                        > ul{
                            > li{
                                color: $nobel-color;
                                font-size: 10px;
                                line-height: 18px;
                            }
                        }
                    }
                }
            }
            [class*="single-blog"]{
                &:not(:last-child){
                    border-bottom: 1px solid $border-color;
                    margin-bottom: 25px;
                    padding-bottom: 25px;
                }
            }
        }
        .widgets-checkbox{
            > li{
                &:not(:last-child){
                    padding-bottom: 15px;
                }
                input[type="checkbox"] + label {
                    font-family: $body-font-family-name;
                    position: relative;
                    padding-left: 25px;
                    line-height: 15px;
                    font-size: 13px;
                    font-weight: 400;
                    text-transform: uppercase;
                    color: $charcoal-color;
                    &:before {
                        width: 15px;
                        height: 15px;
                        display: block;
                        border: 1px solid $border-color;
                    }
                    &:after {
                        position: absolute;
                        left: 0;
                        top: 0;
                        display: block;
                        content: "\ea20";
                        font-family: "LaStudioIcons";
                        font-weight: 300;
                        font-size: 10px;
                        line-height: 15px;
                        opacity: 0;
                        width: 15px;
                        text-align: center;
                        background-color: $primary-color;
                        color: $white-color;
                    }
                }
                input[type="checkbox"]:checked + label {
                    &:before {
                        border: 1px solid $border-color;
                    }
                    &:after {
                        opacity: 1;
                    }
                }
                &:hover{
                    input[type="checkbox"] + label{
                        color: $primary-color;
                        &:before {
                            border: 1px solid $border-color;
                        }
                        &:after {
                            opacity: 1;
                        }
                    }
                }
            }
        }
        .widgets-checkbox-2{
            > li{
                display: block;
                margin: 0 10px 10px 0px;
                position: relative;
                .swatch-wrapper{
                    .swatch-anchor{
                        margin: 0 20px 0 0;
                        display: inline-block;
                        vertical-align: middle;
                        border: 7px solid #fff;
                        transition: all .3s;
                        border-radius: 50%;
                        width: 28px;
                        height: 28px;
                        &.anchor-black{
                            background-color: #000;
                            box-shadow: 0 0 1px 0 rgba(0,0,0,.8);
                        }
                        &.anchor-orange{
                            background-color: #FF7A4B;
                            box-shadow: 0 0 1px 0 rgba(255,122,75,.8);
                        }
                        &.anchor-brown{
                            background-color: #DDA973;
                            box-shadow: 0 0 1px 0 rgba(221,179,115,.8);
                        }
                        &.anchor-navy{
                            background-color: #325182;
                            box-shadow: 0 0 1px 0 rgba(50,81,130,.8);
                        }
                        &.anchor-pink{
                            background-color: #ED3277;
                            box-shadow: 0 0 1px 0 rgba(237,50,119,.8);
                        }
                        &.anchor-gray{
                            background-color: #D8D8D8;
                            box-shadow: 0 0 1px 0 rgba(237,50,119,.8);
                        }
                        &.anchor-dark-green{
                            background-color: #73A156;
                            box-shadow: 0 0 1px 0 rgba(115,161,86,.8);
                        }
                    }
                }
                &:hover{
                    .swatch-wrapper{
                        .swatch-anchor{
                            &:after{
                                content: '\ea20';
                                font-family: LaStudioIcons;
                                position: absolute;
                                top: 6px;
                                font-size: 16px;
                                line-height: 1;
                                left: 7px;
                                color: #fff;
                            }
                            &.anchor-black{
                                background-color: #000;
                                border-color: #000;
                            }
                            &.anchor-orange{
                                background-color: #FF7A4B;
                                border-color: #FF7A4B;
                            }
                            &.anchor-brown{
                                background-color: #DDA973;
                                border-color: #DDA973;
                            }
                            &.anchor-navy{
                                background-color: #325182;
                                border-color: #325182;
                            }
                            &.anchor-pink{
                                background-color: #ED3277;
                                border-color: #ED3277;
                            }
                            &.anchor-gray{
                                background-color: #d8d8d8;
                                border-color: #d8d8d8;
                            }
                            &.anchor-dark-green{
                                background-color: #73A156;
                                border-color: #73A156;
                            }
                        }
                    }
                }
            }
        }
        .shop-sidebar-category{
            .sidebar-category-expand{
                & > li{
                    &.menu-item-has-children{
                        &.menu-open{
                          & > span{
                              &.menu-expand{
                                  transform: rotate(180deg);
                              }
                          }
                        }
                    }
                    position: relative;
                    &:last-child{
                        margin: 0px;
                        a{
                            border-bottom: 0px;
                        }
                    }
                    span{
                        &.menu-expand{
                          position: absolute;
                          right: 0px;
                        }
                    }
                    a{
                        font-size: 14px;
                        font-weight: 400;
                        text-transform: capitalize;
                        display: block;
                        padding-bottom: 10px;
                        margin-bottom: 10px;
                        border-bottom: 1px solid $border-color;
                        &:hover{
                            color: $primary-color;
                        }
                    }
                    ul{
                        &.sub-menu{
                            padding-left: 20px;
                        }
                    } 
                }
                .sub-menu{
                    display: none;
                }
            }
        }
        .widgets-blog-category{
            > li{
                > a{
                    font-size: 16px;
                    font-family: $heading-font-family-name;
                    color: $text-color;
                    padding: 5px 0;
                    display: block;
                    &:hover{
                        color: $primary-color;
                    }
                }
            }
        }
        .widgets-blog-post-area{
            [class*="single-sidebar-post"]{
                &:not(:last-child){
                    border-bottom: 1px solid $border-color;
                    margin-bottom: 25px;
                    padding-bottom: 25px;
                }
            }
            .single-sidebar-post{
                display: flex;
                .sidebar-post-img{
                    width: 80px;
                    img{
                        width: 100%;
                    }
                }
                .sidebar-post-content{
                    margin-left: 20px;
                    width: calc(100% - 100px);
                    display: flex;
                    flex-direction: column;
                    a{
                        font-family: $heading-font-family-name;
                        font-size: 15px;
                        font-weight: 400;
                        line-height: 1.4;
                    }
                    span{
                        font-size: 10px;
                        color: #979797;
                        font-family: $body-font-family-name;
                    }
                }
            }
        }
        .archives-area{
            position: relative;
            display: inline-block;
            width: 100%;
        }
        .widgets-tags-2{
            ul{
                li{
                    display: inline-block;
                    a{
                        font-family: $body-font-family-name;
                        font-size: 14px;
                        line-height: 2.1;
                        color: $text-color;
                        &:hover{
                            color: $primary-color;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
}