/*----------------------------------------*/
/*    40 - Components - Popular Categories
/*----------------------------------------*/
.popular-categories-area{
    background-color: $default-color;
    padding: 60px 0 60px 0;
    margin: 90px 0 90px 0;
    .product-category{
        li{
            display: inline-block;
            a{
                margin: 20px 20px 0 20px;
                display: block;
                overflow: hidden;
                @include breakpoint(max-xxl_device){
                    margin: 0 15px;
                }
                i{
                    display: block;
                    width: 132px;
                    height: 132px;
                    border-radius: 100%;
                    color: $white-color;
                    line-height: 132px;
                    margin-bottom: 10px;
                    @extend %basetransition;
                }
                &:hover{
                    i{
                        background-color: $primary-color-4;
                        @extend %basetransition;
                    }
                    .heading-6{
                        opacity: 1;
                        @extend %basetransition;
                    }
                }
            }
            &:last-child{
                a{
                    margin-right: 0px;
                }
            }
            &:first-child{
                a{
                    margin-left: 0px;
                }
            }
            @include breakpoint(max-xl_device){
                &:last-child{
                    a{
                        margin-right: 15px;
                    }
                }
                &:first-child{
                    a{
                        margin-left: 15px;
                    }
                }
            }
        }
    }
}
.popular-categories-area-2{
    margin-top: 160px;
    background-image: url(../images/bg/home/11-1.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    height: 660px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    @include breakpoint(max-xxl_device){
        height: 620px;
    }
    @include breakpoint(max-xl_device){
        height: 540px;
    }
    @include breakpoint(max-xs_device){
        margin-top: 155px;
    }
    @include breakpoint(max-xxs_device){
        margin-top: 150px;
    }
    .section-title-4{
        margin-bottom: 20px;
    }
    .popular-category-bg-2{
        background-image: url(../images/bg/home/11-2.png);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        height: 160px;
        bottom: -1px;
        z-index: 9;
        position: absolute;
        width: 100%;
        @include breakpoint(max-xl_device){
            height: 90px;
        }
    }
    .single-category{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .category-content{
            font-size: 36px;
            font-family: $general-font-family-5;
            font-weight: 400;
        }
    }
    .category-image{
        img{
            padding: 20px 0px; 
        }
    }
}