/*----------------------------------------*/
/*    12 - Pages - Contact Us
/*----------------------------------------*/
.contact-us_area{
    padding: 90px 0 100px;
    @include breakpoint(max-md_device){
        padding: 90px 0 95px;
    }
    .contact-form-content{
        > h2{
            &.heading{
                font-size: 28px;
                line-height: 28px;
                padding-bottom: 10px;
            }
        }
        > p{
            font-family: $body-font-family-name;
            color: $charcoal-color;
            line-height: 36px;
            font-size: 16px;
            padding-bottom: 25px;
        }
        .contact-form{
            .form-group{
                margin-bottom: 20px;
                > input{
                    &.input-field{
                        width: 100%;
                        height: 70px;
                        padding: 20px;
                        &::placeholder {
                            color: $text-color;
                            opacity: 1;
                        }
                    }
                }
                > textarea{
                    &.textarea-field{
                        width: 100%;
                        height: 150px;
                        padding: 20px;
                        &::placeholder {
                            color: $text-color;
                            opacity: 1;
                        }
                    }
                }
                &.group-input{
                    display: flex;
                    @include breakpoint(max-xs_device){
                        display: block;
                    }
                    > input{
                        &[class*="input-field"]{
                            &:first-child{
                                margin-right: 20px;
                                @include breakpoint(max-xs_device){
                                    margin-right: 0;
                                    margin-bottom: 20px;
                                }
                            }
                        }
                    }
                }
                &.inquiry-field{
                    .nice-select{
                        padding: 0 20px;
                        height: 70px;
                        line-height: 70px;
                        margin-bottom: 20px;
                        // color: #6c757d;
                        &:focus{
                            border: 1px solid $border-color;
                        }
                    }
                }
                .contact-form_btn{
                    background-color: $text-color;
                    color: $white-color;
                    height: 60px;
                    line-height: 60px;
                    width: 150px;
                    &:hover{
                        background-color: $primary-color;
                    }
                }
            }
        }
    }
    .contact-slide_content{
        @include breakpoint(max-md_device){
            padding-top: 30px;
        }
        .single-contact_block{
            display: flex;
            align-items: center;
            > i{
                color: $primary-color;
                padding-right: 30px;
                font-size: 30px;
                display: block;
            }
            &[class*="single-contact_block"]{
                &:not(:last-child){
                    padding-bottom: 30px;
                }
            }
        }
    }
}
