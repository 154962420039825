/*----------------------------------------*/
/*    21 - Pages - Login Register
/*----------------------------------------*/
.login-register-area{
    margin: 90px 0;
    .login-register-wrapper{
        padding: 0 50px;
        @include breakpoint(max-md_device){
            padding: 0 20px;
        }
        @include breakpoint(max-sm_device){
            padding: 0 60px;
        }
        @include breakpoint(max-xs_device){
            padding: 0 20px;
        }
        .my-account{
            .account-header{
                margin-bottom: 20px;
                a{
                    display: block;
                    font-size: 20px;
                    line-height: 20px;
                    margin-bottom: 10px;
                    font-weight: normal;
                    cursor: pointer;
                    i{
                        vertical-align: middle;
                        margin-right: 5px;
                    }
                }
            }
            .account-body{
                .form-row{
                    .form_group{
                        display: flex;
                        flex-direction: column;
                        margin-bottom: 20px;
                        .input-form {
                            padding: 0 20px;
                            height: 50px;
                            line-height: 48px;
                            border: 1px solid #ebebeb;
                        }
                    }
                    .account-button{
                        margin: 0 5px;
                        width: 100%;
                    }
                }
            }
        }
    }
}