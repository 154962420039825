/*----------------------------------------*/
/*    25 - Components - Slider
/*----------------------------------------*/
.home-slider{
	.slide-item{
		display: flex;
		align-items: center;
		.inner-slide{
			display: flex;
			align-items: center;
			justify-content: flex-end;
			.slide-content{
				display: flex;
				flex-direction: column;
				align-items: center;
				max-width: 38%;
				@include breakpoint(max-md_device){
					max-width: 50%;
				}
				@include breakpoint(max-sm_device){
					max-width: 85%;
					margin: 0 auto;
				}
				@include breakpoint(max-xxs_device){
					max-width: 100%;
				}
				&.white-text{
					h4,
					h1,
					p{
						color: $white-color;
					}
				}
			}
		}
		&.slide-left_center{
			position: relative;
			overflow: hidden;
			.inner-slide{
				display: flex;
				justify-content: space-between;
				height: 100%;
				width: 100%;
				@include breakpoint(max-sm_device){
					display: block;
				}
				.slide-content{
					flex: 0 0 50%;
    				max-width: 50%;
					@include breakpoint(max-sm_device){
						flex: 0 0 100%;
						max-width: 100%;
					}
					&.slider-content-2{
						flex: 0 0 100%;
						max-width: 100%;
					}
					.inner-content{
						position: relative;
						left: 9%;
						@include breakpoint(max-lg_device){
							left: 0;
						}
						@include breakpoint(max-sm_device){
							@include absCenter;
							text-align: center;
							margin: 0 auto;
							width: 100%;
						}
						> h4{
							line-height: 20px;
							letter-spacing: 12px;
							font-weight: 400;
							font-size: 12px;
							text-transform: uppercase;
						}
						> h1{
							line-height: 74px;
							letter-spacing: 6px;
							font-weight: 600;
							font-size: 59px;
							text-transform: uppercase;
							@include breakpoint(max-md_device){
								font-size: 48px;
							}
							@include breakpoint(max-xxs_device){
								font-size: 40px;
								line-height: 50px;
								letter-spacing: 4px;
							}
						}
						> p{
							line-height: 20px;
							letter-spacing: 2px;
							font-weight: 400;
							font-size: 16px;
							text-transform: uppercase;
							font-family: $general-font-family-name;
							@include breakpoint(max-xxs_device){
								font-size: 14px;
							}
						}
						span{
							border: 1px solid $white-color;
							width: 270px;
							display: block;
							margin: 15px auto 25px auto;
						}
						.slide-btn{
							display: flex;
							justify-content: center;
						}
					}
				}
				.slide-img{
					position: relative;
					flex: 0 0 50%;
    				max-width: 50%;
					align-items: center;
					display: flex;
					left: 10%;
					@include breakpoint(max-xl_device){
						left: 8%;
					}
					@include breakpoint(max-sm_device){
						display: none;
					}
					img{
						width: 100%;
						height: 100%;
						object-fit: cover;
						position: relative;
						@include breakpoint(max-xl_device){
							width: 80%;
							height: 80%;
						}
					}
				}
				.slide-img-2{
					position: relative;
					flex: 0 0 50%;
    				max-width: 50%;
					align-items: center;
					display: flex;
					left: 20%;
					@include breakpoint(max-md_device){
						left: 8%;
					}
					@include breakpoint(max-sm_device){
						display: block;
					}
					@include breakpoint(max-xxs_device){
						left: 50%;
    					transform: translateX(-50%);
					}
					img{
						width: 100%;
						height: 100%;
						object-fit: cover;
						position: relative;
						@include breakpoint(max-xl_device){
							width: 80%;
							height: 80%;
						}
					}
					.inner-content{
						display: flex;
						flex-direction: column;
						align-items: center;
						.black-title-color{
							font-family: $general-font-family-3;
							line-height: 32px;
							letter-spacing: 1px;
							font-weight: 400;
							font-size: 25px;
							border-color: rgb(255, 255, 255);
							color: rgb(62, 62, 62);
							margin: 10px 0;
							@include breakpoint(max-sm_device){
								font-size: 22px;
							}
							@include breakpoint(max-xs_device){
								font-size: 18px;
							}
							@include breakpoint(max-xxs_device){
								font-size: 11px;
							}
						}
						.slide-btn{
							margin-top: 10px;
						}
					}
				}
			}
		}
	}
}
/*-- Home One slider --*/
.bg-1 {
	background-image: url('../images/slider/bg/1-1.jpg');
	background-color: #ccc;
	min-height: 786px;
	@include breakpoint(max-xl_device){
		min-height: 735px;
	}
	@include breakpoint(max-md_device){
		min-height: 585px;
	}
	@include breakpoint(max-sm_device){
		min-height: 700px;
	}
	@include breakpoint(max-xs_device){
		min-height: 493px;
	}
}
/*-- Home two slider --*/
.inner-content-2{
	.slider-head-1{
		line-height: 90px;
		letter-spacing: 3px;
		font-weight: 400;
		font-size: 80px;
		text-transform: uppercase;
		@include breakpoint(max-md_device){
			line-height: 70px;
			font-size: 60px;
		}
		@include breakpoint(max-sm_device){
			line-height: 60px;
			font-size: 50px;
		}
		@include breakpoint(max-xs_device){
			line-height: 40px;
			font-size: 40px;
		}
		@include breakpoint(max-xxs_device){
			line-height: 30px;
			font-size: 30px;
		}
	}
	.slider-head-2{
		line-height: 26px;
		letter-spacing: 21px;
		font-weight: 400;
		font-size: 20px;
		text-transform: uppercase;
		@include breakpoint(max-xs_device){
			letter-spacing: 10px;
		}
	}
	.slider-head-3{
		line-height: 66px;
		letter-spacing: 6px;
		font-weight: 600;
		font-size: 55px;
		text-transform: uppercase;
		@include breakpoint(max-xs_device){
			line-height: 30px;
			letter-spacing: 3px;
			font-size: 44px;
		}
		@include breakpoint(max-xxs_device){
			letter-spacing: 2px;
			font-size: 30px;
			padding-bottom: 5px;
		}
	}
	.slider-head-4{
		line-height: 41px;
		letter-spacing: 2px;
		font-weight: 400;
		font-size: 35px;
		text-transform: uppercase;
		@include breakpoint(max-xxs_device){
			font-size: 30px;
			line-height: 30px;
		}
	}
	.slide-btn{
		position: relative;
		z-index: 99;
	}
	.image-border{
		@include breakpoint(max-sm_device){
			display: none;
		}
		&:before{
			border-top: 7px solid $white-color;
			border-bottom: 7px solid transparent;
			border-left: 7px solid $white-color;
			border-right: 7px solid $white-color;
			content: "";
			position: absolute;
			width: 110%;
			height: 130%;
			top: -73px;
			left: -30px;
			z-index: 1;
			opacity: .5;
		}
		span{
			&:before{
				border-bottom: 7px solid $white-color;
				border-left: 7px solid transparent;
				content: "";
				position: absolute;
				width: 200px;
				height: 130px;
				top: 28px;
				left: -30px;
				z-index: 2;
				opacity: .5;
			}
			&:after{
				border-bottom: 7px solid $white-color;
				border-right: 7px solid transparent;
				content: "";
				position: absolute;
				width: 200px;
				height: 130px;
				top: 28px;
				right: -28px;
				z-index: 2;
				opacity: .5;
			}
		}
	}
}
.slider-content-2{
	position: relative;
}
.bg-2 {
	min-height: 920px;
	@include breakpoint(max-xl_device){
		min-height: 780px;
	}
	@include breakpoint(max-md_device){
		min-height: 710px;
	}
	@include breakpoint(max-sm_device){
		min-height: 600px;
	}
	@include breakpoint(max-xs_device){
		min-height: 450px;
	}
	@include breakpoint(max-xxs_device){
		background-image: none;
		background-color: #ccc;
		min-height: 400px;
	}
}
/*-- Home four slider --*/
.inner-content-4{
	.slider-head-1{
		line-height: 176px;
		letter-spacing: 27px;
		font-weight: 400;
		font-size: 176px;
		text-transform: uppercase;
		@include breakpoint(max-xl_device){
			font-size: 150px;
			line-height: 150px;
			letter-spacing: 24px;
		}
		@include breakpoint(max-md_device){
			font-size: 80px;
			line-height: 80px;
			letter-spacing: 10px;
		}
		@include breakpoint(max-xs_device){
			font-size: 70px;
			line-height: 70px;
			letter-spacing: 8px;
		}
		@include breakpoint(max-xxs_device){
			font-size: 50px;
			line-height: 50px;
			letter-spacing: 5px;
		}
	}
	.slider-head-2{
		text-align: center;
		line-height: 29px;
		letter-spacing: 4px;
		font-weight: 400;
		font-size: 20px;
		width: 20%;
		font-family: $body-font-family-name;
		margin: 0 auto 20px auto;
		text-transform: uppercase;
		@include breakpoint(max-md_device){
			width: 40%;
		}
		@include breakpoint(max-sm_device){
			width: 30%;
		}
		@include breakpoint(max-xs_device){
			font-size: 18px;
			width: 34%;
		}
		@include breakpoint(max-xxs_device){
			font-size: 16px;
			width: 49%;
		}
	}
	.slider-head-3{
		line-height: 90px;
		letter-spacing: 3px;
		font-weight: 400;
		font-size: 80px;
		text-transform: uppercase;
		@include breakpoint(max-md_device){
			line-height: 70px;
			font-size: 60px;
		}
		@include breakpoint(max-sm_device){
			line-height: 60px;
			font-size: 50px;
		}
		@include breakpoint(max-xs_device){
			line-height: 40px;
			font-size: 40px;
		}
		@include breakpoint(max-xxs_device){
			line-height: 30px;
			font-size: 30px;
		}
	}
	.slider-head-4{
		line-height: 176px;
		letter-spacing: 0px;
		font-weight: 400;
		font-size: 176px;
		text-transform: uppercase;
		@include breakpoint(max-xl_device){
			font-size: 150px;
			line-height: 150px;
		}
		@include breakpoint(max-md_device){
			font-size: 120px;
			line-height: 120px;
		}
		@include breakpoint(max-xs_device){
			font-size: 90px;
			line-height: 90px;
		}
		@include breakpoint(max-xxs_device){
			font-size: 70px;
			line-height: 70px;
		}
	}
	.slider-head-5{
		text-align: center;
		line-height: 29px;
		letter-spacing: 4px;
		font-weight: 400;
		font-size: 20px;
		width: 50%;
		font-family: $body-font-family-name;
		margin: 0 auto 20px auto;;
		text-transform: uppercase;
		@include breakpoint(max-xxs_device){
			width: 80%;
		}
	}
}
.slider-content-4{
	position: relative;
}
/*-- Home Five slider --*/
.bg-5-1 {
	min-height: 920px;
	height: 920px;
	@include breakpoint(max-xl_device){
		min-height: 820px;
		height: 820px;
	}
	@include breakpoint(max-md_device){
		min-height: 700px;
		height: 700px;
	}
	@include breakpoint(max-sm_device){
		min-height: 600px;
		height: 600px;
	}
} 
/*-- Home Six slider --*/
.inner-content-6{
	.slider-head-1{
		font-family: $general-font-family-2;
		text-transform: uppercase;
		text-align: center;
		line-height: 82px;
		letter-spacing: 0px;
		font-weight: 400;
		font-size: 65px;
		color: $banner-color-1;
		@include breakpoint(max-md_device){
			line-height: 70px;
			font-size: 60px;
		}
		@include breakpoint(max-sm_device){
			line-height: 60px;
			font-size: 50px;
		}
		@include breakpoint(max-xs_device){
			line-height: 40px;
			font-size: 40px;
		}
		@include breakpoint(max-xxs_device){
			line-height: 30px;
			font-size: 30px;
		}
	}
}
.slider-content-6{
	position: relative;
}
/*-- Home Eight slider --*/
.home-style-2{
	position: relative;
}
.slider-content-8{
	right: 8%;
	left: auto;
    display: block;
	position: absolute;
	background-color: rgba(255, 255, 255, .8);
    padding: 40px 30px;
	@include breakpoint(max-sm_device){
		left: 50%;
		transform: translateX(-50%);
		right: auto;
		width: 80%;
	}
	.large-title{
		letter-spacing: normal;
		font-size: 40px;
		font-weight: 400;
		font-family: $general-font-family-2;
		color: $text-color;
		position: relative;
		@include breakpoint(max-xs_device){
			font-size: 24px;
		}
	}
	span{
		border: 1.5px solid $primary-color-4;
		display: block;
		width: 87px;
		margin-top: 10px;
		margin-bottom: 10px;
	}
}
.bg-8 {
	min-height: 787px;
	@include breakpoint(max-xs_device){
		min-height: 650px;
	}
}
/*-- Home Ten slider --*/
.slider-content-10{
	.slider-head-1{
		letter-spacing: normal;
		font-size: 75px;
		line-height: 75px;
		font-weight: 400;
		font-family: $general-font-family-3;
		color: $text-color;
		position: relative;
		text-transform: uppercase;
		@include breakpoint(max-lg_device){
			font-size: 50px;
			line-height: 50px;
		}
		@include breakpoint(max-xs_device){
			font-size: 30px;
			line-height: 30px;
		}
		@include breakpoint (max-xxs_device){
			font-size: 26px;
			line-height: 26px;
		}
	}
	.slider-head-2{
		letter-spacing: normal;
		font-style: italic;
		font-size: 75px;
		line-height: 75px;
		font-weight: 400;
		font-family: $general-font-family-3;
		color: $text-color;
		position: relative;
		@include breakpoint(max-lg_device){
			font-size: 50px;
			line-height: 50px;
		}
		@include breakpoint(max-xs_device){
			font-size: 30px;
			line-height: 30px;
		}
		@include breakpoint(max-xxs_device){
			font-size: 26px;
			line-height: 26px;
		}
	}
}
.bg-10 {
	min-height: 1070px;
	@include breakpoint(max-xl_device){
		min-height: 980px;
	}
	@include breakpoint(max-md_device){
		min-height: 810px;
	}
	@include breakpoint(max-sm_device){
		min-height: 700px;
	}
	@include breakpoint(max-xs_device){
		min-height: 500px;
	}
	@include breakpoint(max-xxs_device){
		background-image: none;
		background-color: #ccc;
	}
}
/*-- Home 11 slider --*/
.autoplay-video_slider {
	position: relative;
	width: 100%;
	opacity: 0;
	visibility: hidden;
	transition: all 1.2s ease;
	&.slick-initialized{
		opacity: 1;
		visibility: visible;
	}
	.slick-slide {
		position: relative;
		outline: 0;
		height: 39vw;
		min-height: 920px;
		display: block;
		overflow: hidden;
		@include breakpoint(max-xl_device){
			min-height: 780px;
		}
		@include breakpoint(max-md_device){
			min-height: 650px;
		}
		@include breakpoint(max-sm_device){
			min-height: 550px;
		}
		@include breakpoint(max-xs_device){
			min-height: 493px;
		}
		iframe {
			position: relative;
			pointer-events: none;
		}
		&.slick-active{
			z-index: 1;
			.video-slide_content {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				z-index: 1;
				.slide-title-1{
					font-size: 36px;
					line-height: 46px;
					font-weight: 400;
					font-family: $general-font-family-5;
					color: rgb(100, 99, 141);
					text-transform: uppercase;
					@include breakpoint(max-xl_device){
						font-size: 32px;
						line-height: 42px;
					}
					@include breakpoint(max-md_device){
						font-size: 26px;
						line-height: 32px;
					}
					@include breakpoint(max-sm_device){
						font-size: 22px;
						line-height: 26px;
					}
					@include breakpoint(max-xs_device){
						font-size: 16px;
						line-height: 18px;
					}
				}
				.slide-title-2{
					color: rgb(100, 99, 141);
					font-size: 178px;
					line-height: 198px;
					font-weight: 400;
					font-family: $general-font-family-5;
					@include breakpoint(max-xl_device){
						font-size: 150px;
						line-height: 150px;
					}
					@include breakpoint(max-lg_device){
						font-size: 140px;
						line-height: 140px;
					}
					@include breakpoint(max-md_device){
						font-size: 100px;
						line-height: 100px;
					}
					@include breakpoint(max-sm_device){
						font-size: 80px;
						line-height: 80px;
					}
					@include breakpoint(max-xs_device){
						font-size: 60px;
						line-height: 60px;
					}
					@include breakpoint(max-xxs_device){
						font-size: 40px;
						line-height: 60px;
					}
				}
				.slide-content{
					color: rgb(95, 198, 98);
					font-size: 36px;
					line-height: 46px;
					font-weight: 400;
					font-family: $general-font-family-5;
					@include breakpoint(max-xl_device){
						font-size: 32px;
						line-height: 42px;
					}
					@include breakpoint(max-sm_device){
						font-size: 22px;
						line-height: 22px;
					}
					@include breakpoint(max-xxs_device){
						font-size: 18px;
						line-height: 18px;
					}
				}
				.slide-btn{
					display: flex;
					justify-content: center;
				}
			}
			.inner-content{
				position: absolute;
				top: 50%;
				left: 20%;
				transform: translateY(-50%);
				z-index: 1;
				transition: all .3s ease;
				display: flex;
				flex-direction: column;
				align-items: center;
				@include breakpoint(max-xxl_device){
					left: 15%;
				}
				@include breakpoint(max-xl_device){
					left: 10%;
				}
				.slide-title-1{
					font-size: 36px;
					line-height: 46px;
					font-weight: 400;
					font-family: $general-font-family-5;
					color: rgb(62, 62, 62);
					text-transform: uppercase;
					@include breakpoint(max-xl_device){
						font-size: 32px;
						line-height: 42px;
					}
					@include breakpoint(max-md_device){
						font-size: 26px;
						line-height: 32px;
					}
					@include breakpoint(max-sm_device){
						font-size: 22px;
						line-height: 26px;
					}
					@include breakpoint(max-xs_device){
						font-size: 16px;
						line-height: 18px;
					}
					&.title-width-1{
						width: 540px;
						@include breakpoint(max-xl_device){
							width: 430px;
						}
						@include breakpoint(max-md_device){
							width: 390px;
						}
						@include breakpoint(max-sm_device){
							width: 300px;
						}
						@include breakpoint(max-xs_device){
							width: 180px;
						}
					}
					&.title-width-2{
						width: 575px;
						@include breakpoint(max-md_device){
							width: 446px;
						}
						@include breakpoint(max-sm_device){
							width: 360px;
						}
						@include breakpoint(max-xs_device){
							width: 250px;
						}
					}
				}
				.slide-title-2{
					color: rgb(145, 29, 255);
					font-size: 178px;
					line-height: 198px;
					font-weight: 400;
					font-family: $general-font-family-5;
					@include breakpoint(max-xl_device){
						font-size: 150px;
						line-height: 150px;
					}
					@include breakpoint(max-lg_device){
						font-size: 140px;
						line-height: 140px;
					}
					@include breakpoint(max-md_device){
						font-size: 100px;
						line-height: 100px;
					}
					@include breakpoint(max-sm_device){
						font-size: 80px;
						line-height: 80px;
					}
					@include breakpoint(max-xs_device){
						font-size: 60px;
						line-height: 60px;
					}
					@include breakpoint(max-xxs_device){
						font-size: 40px;
						line-height: 60px;
					}
				}
				.slide-content{
					color: rgb(145, 29, 255);
					font-size: 36px;
					line-height: 46px;
					font-weight: 400;
					font-family: $general-font-family-5;
					@include breakpoint(max-xl_device){
						font-size: 32px;
						line-height: 42px;
					}
					@include breakpoint(max-sm_device){
						font-size: 22px;
						line-height: 22px;
					}
					@include breakpoint(max-xxs_device){
						font-size: 18px;
						line-height: 18px;
					}
				}
				.slide-btn{
					display: flex;
					justify-content: center;
				}
			}
		}
	}
}
/*-- Home 12 slider --*/
.slider-content-12{
	.slider-head-1{
		font-family: $general-font-family-6;
		font-style: italic;
		line-height: 60px;
		letter-spacing: 0px;
		font-weight: 400;
		font-size: 58px; 
		width: 100%;
		@include breakpoint(max-lg_device){
			font-size: 50px;
			line-height: 50px;
		}
		@include breakpoint(max-xs_device){
			font-size: 30px;
			line-height: 30px;
		}
		@include breakpoint(max-xxs_device){
			font-size: 26px;
			line-height: 26px;
		}
	}
}
/*-- Home 13 slider --*/
.slider-content-13{
	.slider-head-1{
		font-family: $general-font-family-6;
		font-style: italic;
		line-height: 60px;
		letter-spacing: 0px;
		font-weight: 400;
		font-size: 58px; 
		width: 100%;
		@include breakpoint(max-lg_device){
			font-size: 50px;
			line-height: 50px;
		}
		@include breakpoint(max-xs_device){
			font-size: 30px;
			line-height: 30px;
		}
		@include breakpoint(max-xxs_device){
			font-size: 26px;
			line-height: 26px;
		}
	}
}
/*-- Home 14 slider --*/
.slider-content-14{
	width: 240px;
	left: 14%;
	position: relative;
	.slider-head-2{
		font-family: $general-font-family-3;
		visibility: visible;
		text-align: center;
		line-height: 21px;
		letter-spacing: 1px;
		font-weight: 400;
		font-size: 16px;
	}
	.slider-head-1{
		font-family: $general-font-family-3;
		visibility: visible;
		text-align: center;
		line-height: 58px;
		letter-spacing: 3px;
		font-weight: 400;
		font-size: 46px;
		border-color: rgb(255, 255, 255);
		border-style: solid;
		border-width: 2px 0px;
		margin: 14px 0px 10px 0px;
		&.black-border-color{
			border-color: rgb(62, 62, 62);
		}
	}
	.slider-head-3{
		font-family: $general-font-family-3;
		visibility: visible;
		text-align: center;
		line-height: 21px;
		letter-spacing: 1px;
		font-weight: 400;
		font-size: 16px;
	}
	.slide-btn{
		display: flex;
		justify-content: center;
		margin-top: 15px;
	}
}
.bg-14{
	min-height: 580px;
	@include breakpoint(max-xl_device){
	}
	@include breakpoint(max-md_device){
	}
	@include breakpoint(max-sm_device){
	}
	@include breakpoint(max-xs_device){
	}
}
.bg-14-2{
	height: 415px;
	@include breakpoint(max-xl_device){
	}
	@include breakpoint(max-md_device){
	}
	@include breakpoint(max-sm_device){
	}
	@include breakpoint(max-xs_device){
	}
}
/*-- Slider content left & right --*/
.left-20 {
    left: 20%;
    display: block;
	position: absolute;
	@include breakpoint(max-xs_device){
		left: 10%;
	}
}
.left-10 {
    left: 10%;
    display: block;
	position: absolute;
	@include breakpoint(max-xs_device){
		left: 10%;
	}
	@include breakpoint(max-xxs_device){
		left: 50%;
		transform: translateX(-50%);
		width: 100%;
	}
}
.right-10 {
	right: 10%;
	left: auto;
    display: block;
	position: absolute;
	@include breakpoint(max-xs_device){
		right: 10%;
	}
	@include breakpoint(max-xxs_device){
		left: 50%;
		transform: translateX(-50%);
		width: 100%;
	}
}
.right-16 {
	right: 16%;
	left: auto;
    display: block;
	position: absolute;
	.slide-btn{
		@include breakpoint(max-xs_device){
			display: flex;
			justify-content: center;
		}
	}
	@include breakpoint(max-xs_device){
		left: 50%;
		transform: translateX(-50%);
		width: 100%;
		text-align: center;
	}
}
/*-- Home Background image --*/
.bg-position{
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
}
.image-2-1{
	background-image: url('../images/slider/bg/2-1.jpg');
}
.image-2-2{
	background-image: url('../images/slider/bg/2-2.jpg');
}
.image-2-3{
	background-image: url('../images/slider/bg/2-3.jpg');
}
.image-3-1{
	background-image: url('../images/slider/bg/3-1.jpg');
}
.image-3-2{
	background-image: url('../images/slider/bg/3-2.jpg');
}
.image-3-3{
	background-image: url('../images/slider/bg/3-3.jpg');
}
.image-4-1{
	background-image: url('../images/slider/bg/4-1.jpg');
}
.image-4-2{
	background-image: url('../images/slider/bg/4-2.jpg');
}
.image-4-3{
	background-image: url('../images/slider/bg/4-3.jpg');
}
.image-5-1{
	background-image: url('../images/bg/home/5-1.jpg');
}
.image-5-2{
	background-image: url('../images/bg/home/5-2.jpg');
}
.image-5-3{
	background-image: url('../images/bg/home/5-3.jpg');
}
.image-5-4{
	background-image: url('../images/bg/home/5-4.jpg');
}
.image-5-5{
	background-image: url('../images/bg/home/5-5.jpg');
}
.image-6-1{
	background-image: url('../images/slider/bg/6-1.jpg');
}
.image-6-2{
	background-image: url('../images/slider/bg/6-2.jpg');
}
.image-6-3{
	background-image: url('../images/slider/bg/6-3.jpg');
}
.image-8-1{
	background-image: url('../images/slider/bg/8-1.jpg');
}
.image-8-2{
	background-image: url('../images/slider/bg/8-2.jpg');
}
.image-8-3{
	background-image: url('../images/slider/bg/8-3.jpg');
}
.image-10-1{
	background-image: url('../images/slider/bg/10-1.jpg');
	background-color: rgba(90, 78, 23, .8);
}
.image-10-2{
	background-image: url('../images/slider/bg/10-2.jpg');
	background-color: rgba(90, 78, 23, .8);
}
.image-11-1{
	background-image: url('../images/slider/bg/11-1.jpg');
	background-color: rgba(254, 214, 31, .9);
}
.image-11-2{
	background-image: url('../images/slider/bg/11-2.jpg');
	background-color: rgba(139, 189, 187, 0.9);
}
.image-12-1{
	background-image: url('../images/slider/bg/12-1.jpg');
	background-color: rgba(215, 177, 190, 0.9);
}
.image-12-2{
	background-image: url('../images/slider/bg/12-2.jpg');
	background-color: rgba(215, 177, 190, 0.9);
}
.image-12-3{
	background-image: url('../images/slider/bg/12-3.jpg');
	background-color: rgba(215, 177, 190, 0.9);
}
.image-13-1{
	background-image: url('../images/slider/bg/13-1.jpg');
	background-color: rgba(215, 177, 190, 0.9);
}
.image-13-2{
	background-image: url('../images/slider/bg/13-2.jpg');
	background-color: rgba(215, 177, 190, 0.9);
}
.image-14-1{
	background-image: url('../images/slider/bg/14-1.jpg');
	background-color: rgba(215, 177, 190, 0.9);
}
.image-14-2{
	background-image: url('../images/slider/bg/14-2.jpg');
	background-color: rgba(215, 177, 190, 0.9);
}
.image-14-3{
	background-image: url('../images/bg/home/14-3.jpg');
}
.image-14-4{
	background-image: url('../images/bg/home/14-4.jpg');
}
.image-14-5{
	background-image: url('../images/bg/home/14-5.jpg');
}
.image-14-6{
	background-image: url('../images/bg/home/14-6.jpg');
}