/*----------------------------------------*/
/*    35 - Components - Featured Product
/*----------------------------------------*/
.featured-body-style{
    box-shadow: 0 0 32px 0 rgba(0,0,0,.12);
    margin: -70px 0 0 0;
    padding: 90px 70px 146px 70px;
    position: relative;
    background-color: $white-color;
    @include breakpoint(max-lg_device){
        padding: 90px 40px 146px 40px;
    }
    @include breakpoint(max-sm_device){
        padding: 90px 5px 146px 5px;
        margin: -40px 0 0 0;
    }
    @include breakpoint(max-xs_device){
        padding: 90px 50px 146px 50px;
        margin: -40px 0 0 0;
    }
    @include breakpoint(max-xxs_device){
        padding: 90px 0px 146px 0px;
        margin: -40px 0 0 0;
    }
    .section-title{
        .heading-one{
            @include breakpoint(max-sm_device){
                font-size: 24px;
                line-height: 16px;
            }
        }
    }
}
.featured-product-area{
    margin-top: 90px;
    .feature-content-wrapper{
        padding-right: 40px;
        @include breakpoint(max-xl_device){
            padding-right: 20px;
        }
        @include breakpoint(max-md_device){
            padding-right: 0px;
        }
        @include breakpoint(max-sm_device){
            padding-bottom: 40px;
        }
        .small-title{
            color: $primary-color-5;
            font-family: $general-font-family-3;
            font-style: italic;
            font-weight: 400;
            font-size: 24px;
            text-transform: capitalize;
            margin-bottom: 25px;
            @include breakpoint(max-xl_device){
                margin-bottom: 15px;
                line-height: 18px;
            }
            @include breakpoint(max-md_device){
                margin-bottom: 10px;
                line-height: 10px;
                font-size: 22px;
            }
        }
        .large-title{
            font-size: 42px;
            font-family: $general-font-family-3;
            text-transform: capitalize;
            font-weight: 400;
            color: $heading-color-2;
            margin-bottom: 35px;
            padding-bottom: 40px;
            position: relative;
            @include breakpoint(max-xl_device){
                margin-bottom: 25px;
                padding-bottom: 30px;
            }
            @include breakpoint(max-md_device){
                font-size: 25px;
                margin-bottom: 15px;
                padding-bottom: 20px;
            }
            &:after{
                content: "";
                position: absolute;
                bottom: 0px;
                left: 0px;
                border-top: 1px solid #827021;
                width: 150px;
            }
        }
        .desc-one{
            @include breakpoint(max-md_device){
                font-size: 16px;
                line-height: 1.7;
            }
        }
    }
}