/*----------------------------------------*/
/*    36 - Components - Brand Product
/*----------------------------------------*/
.brand-product-area{
    margin-top: -120px;
    @include breakpoint(max-sm_device){
        margin-top: -55px;
    }
    @include breakpoint (max-xs_device){
        margin-top: 88px; 
    }
    .brand-product-content{
        margin-bottom: -160px;
        padding-left: 50px;
        @include breakpoint(max-md_device){
            padding-left: 0px;
        }
        @include breakpoint(max-xs_device){
            margin-bottom: 50px;
            text-align: center;
            padding-left: 0px;
        }
        .large-title{
            font-family: $general-font-family-3;
            font-size: 45px;
            font-weight: normal;
            text-transform: uppercase;
            line-height: 60px;
            color: $heading-color-2;
            position: relative;
            padding-bottom: 36px;
            margin-bottom: 50px;
            @include breakpoint(max-md_device){
                font-size: 34px;
                line-height: 34px;
            }
            @include breakpoint(max-sm_device){
                line-height: 50px;
            }
            @include breakpoint(max-xs_device){
                font-size: 26px;
                line-height: 26px;
                margin-bottom: 30px;
                padding-bottom: 30px;
            }
            &:after{
                content: "";
                position: absolute;
                width: 150px;
                height: 1px;
                background: $primary-color-5;
                bottom: 0px;
                left: 0px;
                @include breakpoint(max-xs_device){
                    left: 50%;
                    transform: translateX(-50%)
                }
            }
        }
        .small-title{
            font-family: $general-font-family-3;
            font-size: 24px;
            line-height: 24px;
            color: $heading-color-2;
            font-weight: 400;
            @include breakpoint(max-sm_device){
                font-size: 20px;
                line-height: 20px;
            }
        }
        .icon10{
            margin-bottom: 20px;
            svg{
                @include breakpoint(max-xxs_device){
                    width: 260px;
                }
            }
        }
    }
    .brand-product-image{
        display: block;
        overflow: hidden;
        text-align: left;
        padding-left: 30px;
        @include breakpoint(max-md_device){
            padding-left: 0px;
        }
        @include breakpoint(max-xs_device){
            padding-left: 0;
            text-align: center;
        }
    }
}